import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//Routes
import { QlocPage_NotFoundComponent } from './pages/not-found/not-found.component';
import { QlocPage_HomeComponent } from './pages/home/home.component';

//Hardware
import { QlocPage_DellComponent } from './pages/hardware/dell/dell.component';
import { QlocPage_FujitsuComponent } from './pages/hardware/fujitsu/fujitsu.component'
import { QlocPage_IPTelefoneComponent } from './pages/hardware/ip-telefone/ip-telefone.component';
import { QlocPage_LenovoComponent } from './pages/hardware/lenovo/lenovo.component';

//Dienstleistungen
import { QlocPage_ITServiceComponent } from './pages/dienstleistungen/it-service/it-service.component';
import { QlocPage_BMWIComponent } from './pages/dienstleistungen/bmwi/bmwi.component';
import { QlocPage_AcronisComponent } from './pages/dienstleistungen/acronis/acronis.component';
import { QlocPage_O365Component } from './pages/dienstleistungen/o365/o365.component';
import { QlocPage_3CXComponent } from './pages/dienstleistungen/3cx/3cx.component';
import { QlocPage_MailstoreComponent } from './pages/dienstleistungen/mailstore/mailstore.component';
import { QlocPage_NoSpamProxyComponent } from './pages/dienstleistungen/nospamproxy/nospamproxy.component';
import { QlocPage_SophosComponent } from './pages/dienstleistungen/sophos/sophos.component';
import { QlocPage_NextcloudComponent } from './pages/dienstleistungen/nextcloud/nextcloud.component';
import { QlocPage_VersicherungComponent } from './pages/dienstleistungen/versicherung/versicherung.component';

//Unternehmen
import { QlocPage_KontaktComponent } from './pages/unternehmen/kontakt/kontakt.component';
import { QlocPage_TechnikComponent } from './pages/unternehmen/technik/technik.component';
import { QlocPage_ReferenzenComponent } from './pages/unternehmen/referenzen/referenzen.component';
import { QlocPage_PartnerComponent } from './pages/unternehmen/partner/partner.component';
import { QlocPage_KarriereComponent } from './pages/unternehmen/karriere/karriere.component';
import { QlocPage_PresseComponent } from './pages/unternehmen/presse/presse.component';
import { QlocPage_ImpressumComponent } from './pages/unternehmen/impressum/impressum.component';
import { QlocPage_AGBComponent } from './pages/unternehmen/agb/agb.component';
import { QlocPage_DatenschutzComponent } from './pages/unternehmen/datenschutz/datenschutz.component';
import { QlocPage_WiderrufComponent } from './pages/unternehmen/widerruf/widerruf.component';
import { QlocPage_LizenzenComponent } from './pages/dienstleistungen/lizenzen/lizenzen.component';

//Subheader
import { QlocSubheader_HomeComponent } from './subheader/home/home.component';
import { QlocSubheader_DellComponent ,QlocSubheader_FujitsuComponent, QlocSubheader_LenovoComponent, QlocSubheader_IPTelefoneComponent } from './subheader/hardware/hardware.component';
import { QlocSubheader_3CXComponent, QlocSubheader_AcronisComponent, QlocSubheader_BMWIComponent, QlocSubheader_ITServiceComponent, QlocSubheader_MailstoreComponent, QlocSubheader_NoSpamProxyComponent, QlocSubheader_O365Component, QlocSubheader_SophosComponent, QlocSubheader_NextcloudComponent, QlocSubheader_ITSecurityComponent, QlocSubheader_CitrixComponent, QlocSubheader_FortinetComponent, QlocSubheader_CrowdstrikeComponent, QlocSubheader_TenableComponent, QlocSubheader_ITSealComponent, QlocSubheader_AwarenessHeroComponent } from './subheader/dienstleistungen/dienstleistungen.component';
import { QlocSubheader_KarriereComponent } from './subheader/unternehmen/unternehmen.component';
import { QlocPage_ITSecurityComponent } from './pages/dienstleistungen/it-security/it-security.component';
import { QlocPage_CitrixComponent } from './pages/dienstleistungen/citrix/citrix.component';
import { QlocPage_FortinetComponent } from './pages/dienstleistungen/fortinet/fortinet.component';
import { QlocPage_CrowdstrikeComponent } from './pages/dienstleistungen/crowdstrike/crowdstrike.component';
import { QlocPage_TenableComponent } from './pages/dienstleistungen/tenable/tenable.component';
import { QlocPage_ITSealComponent } from './pages/dienstleistungen/it-seal/it-seal.component';
import { QlocPage_AwarenessHeroComponent } from './pages/dienstleistungen/awareness-hero/awareness-hero.component';
import { QlocPage_BarcoComponent } from './pages/dienstleistungen/barco/barco.component';


const routes: Routes = [
  { path: '', children: [
    { path:'', component: QlocPage_HomeComponent },
    { path: '', component: QlocSubheader_HomeComponent, outlet: 'subheader' }
  ],
  data: {
    title: 'Quality Location Systemhaus - Ihr Partner für Hosting und Netzwerke',
  }},

  { path: 'dell', children: [
    { path: '', component: QlocPage_DellComponent},
    { path: '', component: QlocSubheader_DellComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Dell Hardware - Quality Location GmbH',
  }},

  { path: 'fujitsu', children: [
    { path: '', component: QlocPage_FujitsuComponent},
    { path: '', component: QlocSubheader_FujitsuComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Fujitsu Hardware - Quality Location GmbH'
  }},

  { path: 'lenovo', children: [
    { path: '', component: QlocPage_LenovoComponent},
    { path: '', component: QlocSubheader_LenovoComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Lenovo Hardware - Quality Location GmbH'
  }},

  { path: 'ip-telefone', children: [
    { path: '', component: QlocPage_IPTelefoneComponent},
    { path: '', component: QlocSubheader_IPTelefoneComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'IP Telefone - Quality Location GmbH'
  }},

  { path: 'it-service', children: [
    { path: '', component: QlocPage_ITServiceComponent},
    { path: '', component: QlocSubheader_ITServiceComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'IT Service - Quality Location GmbH'
  }},

  { path: 'citrix', children: [
    { path: '', component: QlocPage_CitrixComponent},
    { path: '', component: QlocSubheader_CitrixComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Citrix - Quality Location GmbH'
  }},

  { path: 'it-security', children: [
    { path: '', component: QlocPage_ITSecurityComponent},
    { path: '', component: QlocSubheader_ITSecurityComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'IT Security - Quality Location GmbH'
  }},

  { path: 'bmwi', children: [
    { path: '', component: QlocPage_BMWIComponent},
    { path: '', component: QlocSubheader_BMWIComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'BMWI Förderung - Quality Location GmbH'
  } },

  { path: 'acronis', children: [
    { path: '', component: QlocPage_AcronisComponent},
    { path: '', component: QlocSubheader_AcronisComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Acronis - Quality Location GmbH'
  }},

  { path: 'o365', children: [
    { path: '', component: QlocPage_O365Component},
    { path: '', component: QlocSubheader_O365Component, outlet: 'subheader' },
  ],
  data: {
    title: 'Microsoft 365 - Quality Location GmbH'
  }},

  { path: '3cx', children: [
    { path: '', component: QlocPage_3CXComponent},
    { path: '', component: QlocSubheader_3CXComponent, outlet: 'subheader' },
  ],
  data: {
    title: '3CX Telefonanlagen - Quality Location GmbH'
  }},

  { path: 'barco', children: [
    { path: '', component: QlocPage_BarcoComponent},
    // { path: '', component: QlocSubheader_3CXComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Barco - Quality Location GmbH'
  }},

  { path: 'mailstore', children: [
    { path: '', component: QlocPage_MailstoreComponent},
    { path: '', component: QlocSubheader_MailstoreComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'E-Mail Archivierung - Quality Location GmbH'
  }},

  { path: 'nsp', children: [
    { path: '', component: QlocPage_NoSpamProxyComponent},
    { path: '', component: QlocSubheader_NoSpamProxyComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Spamfilter - Quality Location GmbH'
  }},

  { path: 'sophos', children: [
    { path: '', component: QlocPage_SophosComponent},
    { path: '', component: QlocSubheader_SophosComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Sophos - Quality Location GmbH'
  }},

  { path: 'fortinet', children: [
    { path: '', component: QlocPage_FortinetComponent},
    { path: '', component: QlocSubheader_FortinetComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Fortinet - Quality Location GmbH'
  }},

  { path: 'crowdstrike', children: [
    { path: '', component: QlocPage_CrowdstrikeComponent},
    { path: '', component: QlocSubheader_CrowdstrikeComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Crowdstrike - Quality Location GmbH'
  }},

  { path: 'tenable', children: [
    { path: '', component: QlocPage_TenableComponent},
    { path: '', component: QlocSubheader_TenableComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'tenable.io - Quality Location GmbH'
  }},

  { path: 'it-seal', children: [
    { path: '', component: QlocPage_ITSealComponent},
    { path: '', component: QlocSubheader_ITSealComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'IT-Seal - Quality Location GmbH'
  }},

  { path: 'awareness-hero', children: [
    { path: '', component: QlocPage_AwarenessHeroComponent},
    { path: '', component: QlocSubheader_AwarenessHeroComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Awareness Hero - Quality Location GmbH'
  }},

  { path: 'nextcloud', children: [
    { path: '', component: QlocPage_NextcloudComponent},
    { path: '', component: QlocSubheader_NextcloudComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Nextcloud - Quality Location GmbH'
  }},

  { path: 'versicherung', children: [
    { path: '', component: QlocPage_VersicherungComponent},
    //{ path: '', component: QlocSubheader_NextcloudComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Cyberversicherungen - Quality Location GmbH'
  }},

  { path: 'kontakt', children: [
    { path: '', component: QlocPage_KontaktComponent},
    //{ path: '', component: QlocSubheader_Component, outlet: 'subheader' },
  ],
  data: {
    title: 'Kontakt - Quality Location GmbH'
  }},

  { path: 'technik', children: [
    { path: '', component: QlocPage_TechnikComponent},
    //{ path: '', component: QlocSubheader_Component, outlet: 'subheader' },
  ],
  data: {
    title: 'Technik - Quality Location GmbH'
  }},

  { path: 'referenzen', children: [
    { path: '', component: QlocPage_ReferenzenComponent},
    //{ path: '', component: QlocSubheader_Component, outlet: 'subheader' },
  ],
  data: {
    title: 'Referenzen - Quality Location GmbH'
  }},

  { path: 'partner', children: [
    { path: '', component: QlocPage_PartnerComponent},
    //{ path: '', component: QlocSubheader_Component, outlet: 'subheader' },
  ],
  data: {
    title: 'Partner - Quality Location GmbH'
  }},

  { path: 'karriere', children: [
    { path: '', component: QlocPage_KarriereComponent},
   // { path: '', component: QlocSubheader_KarriereComponent, outlet: 'subheader' },
  ],
  data: {
    title: 'Karriere - Quality Location GmbH'
  }},

  { path: 'presse', children: [
    { path: '', component: QlocPage_PresseComponent},
    //{ path: '', component: QlocSubheader_Component, outlet: 'subheader' },
  ],
  data: {
    title: 'Presse - Quality Location GmbH'
  }},

  { path: 'impressum', children: [
    { path: '', component: QlocPage_ImpressumComponent},
    //{ path: '', component: QlocSubheader_Component, outlet: 'subheader' },
  ],
  data: {
    title: 'Impressum - Quality Location GmbH'
  }},

  { path: 'agb', children: [
    { path: '', component: QlocPage_AGBComponent},
    //{ path: '', component: QlocSubheader_Component, outlet: 'subheader' },
  ],
  data: {
    title: 'AGB - Quality Location GmbH'
  }},

  { path: 'datenschutz', children: [
    { path: '', component: QlocPage_DatenschutzComponent},
    //{ path: '', component: QlocSubheader_Component, outlet: 'subheader' },
  ],
  data: {
    title: 'Datenschutz - Quality Location GmbH'
  }},

  { path: 'widerruf', children: [
    { path: '', component: QlocPage_WiderrufComponent},
    //{ path: '', component: QlocSubheader_Component, outlet: 'subheader' },
  ],
  data: {
    title: 'Widerruf - Quality Location GmbH'
  }},

  { path: 'lizenzen', children: [
    { path: '', component: QlocPage_LizenzenComponent},
    //{ path: '', component: QlocSubheader_Component, outlet: 'subheader' },
  ],
  data: {
    title: 'Lizenzen - Quality Location GmbH'
  }},

  { path: '**', component: QlocPage_NotFoundComponent ,
  data: {
    title: '404 - Quality Location GmbH'
  }},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="row qloc-shp">
    <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
        <h4 class="qloc-shp-title" [innerHTML]="title"></h4>
        <div class="qloc-shp-text">
            <ng-content></ng-content>
        </div>
        <div class="row qloc-shp-kontakt">
            <div class="col-md-6 col-12">
                <p><strong>
                    Sie haben Interesse? Fragen? <br>
                    Rufen Sie an oder schreiben Sie uns:
                </strong></p>
            </div>
            <div class="col-md-6 col-12">
                <p><strong>
                    <fa-icon icon="phone"></fa-icon>Tel.: <a href="tel:030 2201298-30"> 030 2201298-30</a> <br>
                    <fa-icon icon="envelope"></fa-icon>E-Mail Adresse: <a href="mailto:sales@qloc.de"> sales@qloc.de</a>
                </strong></p>
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12 text-center qloc-shp-images">
        <img *ngFor="let img of images;" src="{{img}}" [style]="mediastyle" class="qloc-shp-image">
        <iframe *ngIf="iframe" [src]="iframeURL" frameborder="0 " style="width:80%; height: auto; aspect-ratio: 16 / 9;" allowfullscreen=" " allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" class="qloc-shp-iframe"></iframe>
    </div>
</div>
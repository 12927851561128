import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-bmwi',
  templateUrl: './bmwi.component.html',
  styleUrls: ['./bmwi.component.scss']
})
export class QlocPage_BMWIComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-lenovo',
  templateUrl: './lenovo.component.html',
  styleUrls: ['./lenovo.component.scss']
})
export class QlocPage_LenovoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<qloc-card class="mt-5">
    <qloc-col-media title="Zertifizierte Rechenzentren in Frankfurt" image="assets/unternehmen/rz/rz8.webp">
        <p>Wir sind europaweit mit unserem Netzwerk an zahlreichen Standorten präsent und können dort Projekte für Sie realisieren.</p>
        <p>Den Hauptteil unserer Infrastruktur betreiben wir an unserem Rechenzentrum <b>DE-FRA2</b> in Frankfurt am Main. Das im Osten der Stadt gelegene Datacenter liegt nur wenige Gehminuten größten europäischen Internetaustauschknoten – dem DECiX – entfernt
            und bietet damit ideale Voraussetzungen für eine optimale Konnektivität. Mit Platz für etwa 250 Racks oder 7.500 Server auf 1200qm Technikfläche steht uns eine ausreichende Wachstumsfläche für die kommenden Jahre zur Vefügung.</p>
        <p>Zum Schutz der Umwelt nutzen unsere Rechenzentren <strong>100 % Ökostrom</strong> aus regenerativen Energien, wie Sonne, Wind oder Wasser, über zertifizierte Ökostrom-Anbieter und kann damit eine Null-CO2-Bilanz aufweisen. Weitere ökologische
            Energiesparmaßnahmen liegen im Einsatz besonders energiesparender Server und hocheffizienter Kühlung.</p>
    </qloc-col-media>
    <div class="row mt-4">
        <div class="col-lg-4 col-12">
            <qloc-tick>24/7/365 – Network Operation Center</qloc-tick>
            <qloc-tick>Redundante Glasfaseranbindung</qloc-tick>
            <qloc-tick>480 Gbit/s Anbindung an verschiedene Carrier</qloc-tick>
            <qloc-tick>Native IPv4- und IPv6-Anbindung</qloc-tick>
            <qloc-tick>Redundantes 2.72 TBit/s Routing-Equipment</qloc-tick>
        </div>
        <div class="col-lg-4 col-12">
            <qloc-tick>Open Peering Philosophy</qloc-tick>
            <qloc-tick>Löschgasanlage Novec 1230</qloc-tick>
            <qloc-tick>Biometrisches Zutrittssystem</qloc-tick>
            <qloc-tick>Redundante Klimageräte N+1</qloc-tick>
        </div>
        <div class="col-lg-4 col-12">
            <qloc-tick>Luftzufuhr über den Doppelboden, Warmluftabsaugung im Deckenbereich</qloc-tick>
            <qloc-tick>Kalt- und Warmgang-Anordnung</qloc-tick>
            <qloc-tick>2 USV-Anlagen, 25 Min. Reservezeit</qloc-tick>
            <qloc-tick>2 Netzersatzanlagen für das gesamte Gebäude</qloc-tick>
        </div>
    </div>
</qloc-card>

<div class="row">
    <div class="col-lg-6 col-12 mb-5">
        <qloc-card-overview class="mt-5  h-100" title="RZ DE-FRA1" image="assets/unternehmen/rz/rz28.webp" footer="Zertifiziert nach T3+ Standard">
            <p>Unser Rechenzentrum in der Kruppstraße 105 in Frankfurt am Main bietet mit <b>400 Racks Kapazitäten für etwa 16.000 Serversysteme</b>. Redundante USV Systeme, ein Notstromaggregat sowie eine hocheffiziente Klimatisierung mit n+2 bieten anspruchsvollen
                Anwendungen ein sicheres Zuhause und lassen keine Wünsche übrig, wenn Sicherheit und Verfügbarkeit im Vordergrund stehen. Der Standort wird 24×7 durch professionellen On-Site Service betreut und bietet somit stets kurzfristige Reaktionszeiten,
                falls ein Problem auftreten sollte. </p>
        </qloc-card-overview>
    </div>
    <div class="col-lg-6 col-12 mb-5">
        <qloc-card-overview class="mt-5 h-100" title="RZ DE-FRA2" image="assets/unternehmen/rz/rz27.webp" footer="Zertifiziert nach T3+ Standard">
            <p>Der Standort auf der Hanauer Landstraße weist aktuell eine Kapazität von <b>250 Racks und damit Platz für etwa 7.500 Server</b> auf. Die Infrastruktur befindet sich dem neuesten Stand der Technik: Redundante USV-Anlagen in Kombination mit
                einem Notstromaggregat garantieren eine ständige Verfügbarkeit von Energie. Die ebenso fehlertolerant ausgelegte Klimatisierung sorgt für gleichbleibende Bedingungen im Betrieb. Durch einen 24×7 Service direkt vor Ort kann jede Störung
                sofort identifiziert und behoben werden.</p>
        </qloc-card-overview>
    </div>
</div>

<qloc-card-media class="mt-5" title="Weitere Standorte" image="assets/unternehmen/rz/map.svg" [left]="true" [size]="6">
    <p>Unternehmensanwendungen sind bereits vorbildlich durch <b>höchste Sicherheitsmaßnahmen</b> geschützt, auch wenn es im Betrieb selbst gar keine Daten mehr gibt. Die Mitarbeiter verbinden sich mittels Fernzugriff direkt auf die Server im Rechenzentrum
        und nehmen so alle Tätigkeiten autark vor. Die Risiken durch Elementarschäden, Einbrüche, Sabotageakte oder infolge von Defekten sind somit allesamt bereits gegen Null reduziert. Gleichzeitig ist ein schneller Zugriff sowohl aus dem Unternehmen,
        wie auch von jedem anderen beliebigen Aufenthaltsort auf den Arbeitsplatz gewährleistet.</p>
    <p>Um tatsächlich die höchste erzielbare Sicherheitsstufe zu realisieren, müssen sämtliche Datenbestände in einem <b>Redundanzstandort</b> – also einem zweiten Rechenzentrum – parallel vorgehalten werden. Neben dem Vorteil, dass beim Datenverkehr zwischen
        diesen beiden Standorten keine zusätzlichen Kosten anfallen, bieten unsere Optionen in Europa außerdem weitere Chancen für unsere Kunden. So können je nach Branche beispielsweise Kosten gespart, oder zusätzliche Geschäftsfelder abgedeckt werden.</p>
</qloc-card-media>

<qloc-card class="mt-5 mb-5">
    <h2>IP Transit</h2>
    <div class="row">
        <div class="col-md-6 mt-4">
            <b>Der Zusammenschluss mit möglichst vielen Carriern, Internet Service Providern und Internet Exchanges ist ein entscheidender Faktor, um die Kontinuität von Geschäftsabläufen sicherzustellen und die Reichweite der eigenen IT-Infrastruktur zu erhöhen.</b>
            <p>Wir vernetzen Ihre Systeme mit der Welt – indem wir Ihren Datenverkehr über unser hochwertiges Netzwerk weiterleiten. IP Transit über die technische Infrastruktur von Quality Location bietet Ihnen für kürzere Datenverkehrswege hervorragende
                Peerings zu einer Vielzahl an Providern. Wir überzeugen dabei nicht nur durch Qualität, sondern glänzen auch mit einem sehr niedrigen Preisniveau.</p>
            <p>Zur Messung des angefallenen Datenverkehr-Volumens werden von uns mehrere Verfahren angeboten. Sie können entweder den gesamten tatsächlich erzeugten Datenverkehr in Gigabyte als Abrechnungsgrundlage nutzen, wozu allerdings eine kontinuierliche
                Messung der Datenströme erforderlich ist, oder sie lassen in fest definierten Zeitabständen Durchschnittswerte ermitteln – beispielsweise alle fünf Minuten.</p>
            <p>Es steht außerdem die sogenannte “95%-Methode” zur Auswahl. Zunächst werden dabei alle Durchschnittswerte protokolliert. Die höchsten 5% der Werte werden nicht weiter berücksichtigt; daraufhin werden die Kosten auf Grundlage der verbleibenden
                Höchstwerte berechnet.</p>
        </div>
        <div class="col-md-6 mt-4">
            <img class="img-responsive" src="assets/unternehmen/rz/network-map.svg">
            <div class="row mt-3">
                <div class="col-md-6">
                    <qloc-tick>Level3</qloc-tick>
                    <qloc-tick>DTAG</qloc-tick>
                    <qloc-tick>Cogent</qloc-tick>
                    <qloc-tick>Atrato /Hibernia</qloc-tick>
                </div>
                <div class="col-md-6">
                    <qloc-tick>Kaia Networks</qloc-tick>
                    <qloc-tick>DE-CIX</qloc-tick>
                    <qloc-tick>AMS-IX</qloc-tick>
                </div>
            </div>
        </div>
    </div>
</qloc-card>
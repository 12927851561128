import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'qloc-theme-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class QlocTheme_SubHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}


@Component({
    selector: 'qloc-theme-subheader-overview',
    templateUrl: './subheader-overview.component.html',
    styleUrls: ['./subheader-overview.component.scss']
  })
  export class QlocTheme_SubheaderOverviewComponent implements OnInit {
  
    @Input() headline="";
    @Input() text="";
    @Input() overview=[""];
    @Input() image:any="";
    @Input() mediastyle: string = "";
    @Input() simage="";
    @Input() link="";
    @Input() html="";
    safehtml:SafeHtml = "";
  
    constructor(private sanitizer: DomSanitizer) { }

    images:Array<String> = [];
  
    ngOnInit(): void {
        if((typeof this.image) === "string") {
          this.images = [this.image];
          if(this.image == "")
            this.images = [];
        } else {
          this.images = this.image;
        }
        if(this.html) {
            this.safehtml = this.sanitizer.bypassSecurityTrustHtml(this.html);

        }
    }
  
  }

  @Component({
    selector: 'qloc-theme-subheader-product',
    templateUrl: './subheader-product.component.html',
    styleUrls: ['./subheader-product.component.scss']
  })
  export class QlocTheme_SubheaderProductComponent implements OnInit {
  
    @Input() title:string="";
    @Input() image:any="";
    @Input() mediastyle: string = "";
    @Input() iframe: string = '';
  
    constructor(private sanitizer: DomSanitizer) { }

    images:Array<String> = [];
    iframeURL:SafeResourceUrl = '';
  
    ngOnInit(): void {
        if((typeof this.image) === "string") {
          this.images = [this.image];
          if(this.image == "")
            this.images = [];
        } else {
          this.images = this.image;
        }
        if(this.iframe != '') 
          this.iframeURL =  this.sanitizer.bypassSecurityTrustResourceUrl(this.iframe);
    }
  
  }

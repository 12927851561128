import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-fortinet',
  templateUrl: './fortinet.component.html',
  styleUrls: ['./fortinet.component.scss']
})
export class QlocPage_FortinetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="container">
    <div class="carousel">
        <ngb-carousel>
            <ng-template ngbSlide *ngFor="let rowitem of partner">
                <div class="row">
                    <div class="col" *ngFor="let item of rowitem">
                        <a href="{{item.link}}" target="_blank"><img src="{{item.image}}" alt="{{item.title}}" class="img-responsive partnerimage"></a>
                    </div>
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</div>
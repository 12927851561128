import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-tenable',
  templateUrl: './tenable.component.html',
  styleUrls: ['./tenable.component.scss']
})
export class QlocPage_TenableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-o365',
  templateUrl: './o365.component.html',
  styleUrls: ['./o365.component.scss']
})
export class QlocPage_O365Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="mt-5">
    <h2>Sophos Lizenzen und Software</h2>
    <div class="mt-4 row">
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100" delay="0.6">
                <qloc-card-overview class="h-100" title="Ihre Vorteile bei Sophos Lizenzen" image="assets/products/msp/sophos-take-action-2.webp" footer="Möglich für Ihre Sophos Hardware vor Ort oder für Virtuelle Sophos Software">
                    <qloc-tick>Als Kauflizenz erhältlich</qloc-tick>
                    <qloc-tick>Als Mietizenz erhältlich</qloc-tick>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100" delay="0.3">
                <qloc-card-overview class="h-100" title="Sophos Software Lösungen Verwalten" image="assets/products/msp/sophos-take-action-1.webp" footer="Durch Verwaltung in Sophos Central oder vor Ort war das Administrieren nie einfacher.">
                    <qloc-tick>In Sophos Central</qloc-tick>
                    <qloc-tick>Auf Hardware vor Ort</qloc-tick>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100" delay="0">
                <qloc-card-overview class="h-100" title="Dedizierter Kundendienst" image="assets/products/msp/sophos-take-action-3.webp" footer="Schneller Kundenservice bei Problemen.">
                    <qloc-tick>Wir stehen Ihnen bei Problemen zur Verfügung</qloc-tick>
                    <qloc-tick>Sophos Support ebenfalls enthalten</qloc-tick>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
    </div>
</div>


<qloc-slide-in>
    <qloc-card class="mt-4">
        <div class="row">
            <div class="col mb-2 mt-2">
                <a class="btn btn-qloc h-100 v-align-center" href="sophos#InterceptXEndpoint">
                    <span>Intercept X Endpoint</span>
                </a>
            </div>
            <div class="col mb-2 mt-2">
                <a class="btn btn-qloc h-100 v-align-center" href="sophos#SecureUnifiedEndpointManagement">
                    <span>Secure Unified Endpoint Management</span>
                </a>
            </div>
            <div class="col mb-2 mt-2">
                <a class="btn btn-qloc h-100 v-align-center" href="sophos#InterceptXforServer">
                    <span>Intercept X for Server</span>
                </a>
            </div>
            <div class="col mb-2 mt-2">
                <a class="btn btn-qloc h-100 v-align-center" href="sophos#SophosCentralDeviceEncryption">
                    <span>Sophos Central Device Encryption</span>
                </a>
            </div>
            <div class="col mb-2 mt-2">
                <a class="btn btn-qloc h-100 v-align-center" href="sophos#SophosHardware">
                    <span>Sophos Hardware</span>
                </a>
            </div>
        </div>
    </qloc-card>
</qloc-slide-in>


<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Intercept X Endpoint" image="assets/products/msp/Endpoint_Protection_Advanced.webp" [downloads]="[['Endpoint Security Buyers Guide','assets/products/msp/sophos-endpoint-buyers-guide-de.pdf']]" id="InterceptXEndpoint">
        <p>Wehren Sie mehr Endpoint-Bedrohungen ab als je zuvor. Intercept X ermöglicht dies mit Deep Learning, einer Sonderform des Machine Learning, das bekannte und unbekannte Malware ohne Signaturen erkennen kann.</p>
        <p>Deep Learning macht Intercept X intelligenter, skalierbarer und effektiver im Kampf gegen völlig unbekannte Bedrohungen. Deep Learning macht Intercept X leistungsstärker als Endpoint-Security-Lösungen, die sich allein auf herkömmliches Machine
            Learning oder eine signaturbasierte Erkennung verlassen.</p>
        <p>Alle bereits verschlüsselten Dateien werden in einen sicheren Zustand zurückversetzt, damit Ihre Mitarbeiter ohne Unterbrechungen weiterarbeiten können und Ihre Geschäftskontinuität gewährleistet bleibt. Nach der Bereinigung erhalten Sie detaillierte
            Informationen, sodass Sie sehen können, wie die Bedrohung sich Zugriff verschafft hat, welche Bereiche betroffen waren und wann die Bedrohung blockiert wurde.</p>
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card class="mt-5">
        <div class="row">
            <div class="col-lg-6 col-sm-12 mt-4">
                <qloc-tick><strong>Web Control</strong>:</qloc-tick>
                <p>Mit Endpoint Web Filtering können Administratoren festlegen, welche Dateitypen ein Benutzer aus dem Internet herunterladen kann.</p>
            </div>
            <div class="col-lg-6 col-sm-12 mt-4">
                <qloc-tick><strong>Anti-Malware/Antivirus</strong>:</qloc-tick>
                <p>Erkennung bekannter Malware auf Signaturbasis. Malware-Engines sollten nicht nur ausführbare Dateien, sondern auch z. B. schädlichen Javascript-Code auf Websites überprüfen können.</p>
            </div>
            <div class="col-lg-6 col-sm-12 mt-4">
                <qloc-tick><strong>Application Lockdown</strong>:</qloc-tick>
                <p>Abwehr von schädlichen Verhaltensweisen von Anwendungen: Ein modifiziertes Office-Dokument installiert beispielsweise eine Anwendung und führt diese aus.</p>
            </div>
            <div class="col-lg-6 col-sm-12 mt-4">
                <qloc-tick><strong>Data Loss Prevention (DLP)</strong>:</qloc-tick>
                <p>Wird ein Angriff zunächst nicht bemerkt, kann dank DLP die letzte Phase mancher Angriffe erkannt und abgewehrt werden, wenn der Angreifer versucht, Daten abzuschöpfen. Hierbei werden eine Reihe sensibler Datentypen überwacht.</p>
            </div>
        </div>
    </qloc-card>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Sophos Mobile: Secure Unified Endpoint Management" image="assets/products/msp/sophos-mobile-8-banner.webp" [downloads]="[['Sophos Mobile: Secure Unified Endpoint Management','assets/products/msp/sophosmobilecontroldsna.pdf']]"
        id="SecureUnifiedEndpointManagement">
        <p class="mt-4">Sophos Mobile ist eine Unified Endpoint Management (UEM)- und Sicherheitslösung, mit der Unternehmen traditionelle und mobile Endpoints einfacher und zeitsparender verwalten und schützen können. Sophos Mobile ist als einzige UEM-Lösung nativ in
            eine führende Next-Gen-Endpoint-Security-Plattform eingebunden und unterstützt die Verwaltung von Windows-10-, macOS-, iOS- und Android-Geräten.</p>
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-col-media class="mt-5" image="assets/products/msp/sophos_home_rgb.webp" [size]="3" [left]="true" mediastyle="max-width: 150px;" textstyle="margin: auto 0;" textstyle="margin: auto 0;">
        <h3>Mehr Produktivität</h3>
        <p>Ermöglicht Benutzern, so zu arbeiten, wie sie möchten – auf dem Gerät ihrer Wahl und ohne Sicherheitsrisiken. Konzentrieren Sie sich auf Ihr Kerngeschäft und überlassen Sie Ihre Sorgen den Sicherheitsexperten von Sophos.</p>
    </qloc-col-media>
</qloc-slide-in>
<qloc-slide-in>
    <hr>
    <qloc-col-media image="assets/products/msp/enduser_protection_rgb.webp" [size]="3" [left]="true" mediastyle="max-width: 150px;" textstyle="margin: auto 0;">
        <h3>Sicherheit</h3>
        <p>Aktive Malware-, Ransomware-, Phishing- und Webfilter schützen Ihre Geräte vor Schadsoftware und Datenverlust. Compliance-Regeln und PUAs stellen sicher, dass keine Geschäftsdaten verloren gehen oder durch Malware gefährdet werden.</p>
    </qloc-col-media>
</qloc-slide-in>
<qloc-slide-in>
    <hr>
    <qloc-col-media image="assets/products/msp/sg_utm_rgb.webp" [size]="3" [left]="true" mediastyle="max-width: 150px;" textstyle="margin: auto 0;">
        <h3>Anwendungkontrolle</h3>
        <p>Lässt sich einfach konfigurieren, bedienen und verwalten. Mit dem flexiblen Self-Service-Portal können Ihre Benutzer viele Probleme selbst lösen – so fallen weniger Helpdesk-Anfragen an und Ihre IT wird entlastet.
        </p>
    </qloc-col-media>
</qloc-slide-in>
<qloc-slide-in>
    <hr>
    <qloc-col-media image="assets/products/msp/sophos_central_rgb.webp" [size]="3" [left]="true" mediastyle="max-width: 150px;" textstyle="margin: auto 0;">
        <h3>Einfache Bedienung</h3>
        <p>Dank einer moderner und intuitiver Weboberfläche bedienen und konfigurieren Sie das Sophos Mobile Device Management im Handumdrehen.
        </p>
    </qloc-col-media>
</qloc-slide-in>
<qloc-slide-in>
    <hr>
    <qloc-col-media image="assets/products/msp/best-value.webp" [size]="3" [left]="true" mediastyle="max-width: 150px;" textstyle="margin: auto 0;">
        <h3>Preis-Leistung</h3>
        <p>Dank benutzerbasierten Preisen können Sie Endpoints kostengünstig schützen und verwalten.
        </p>
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Intercept X for Server" iframe="https://player.vimeo.com/video/254657007?dnt=true" [downloads]="[['Datenblatt: Intercept X for Server','assets/products/msp/sophosinterceptxforserver.pdf']]" id="InterceptXforServer">
        <p>Interception X ist ein von Sophos entwickeltes und stetig weiter ausgebautes neuronales Netzwerk. Durch Machine Leaning ist dieses in der Lage, sowohl bekannte als auch bisher noch unbekannte Malware komplett ohne Signaturen zu erkennen.<br />            Deep Learning macht Intercept X intelligenter, skalierbarer und leistungsstärker als Sicherheitslösungen, die sich allein auf herkömmliches Machine Learning oder eine signaturbasierte Erkennung verlassen.<br /> CryptoGuard in Intercept X blockiert
            alle Ransomware-Bedrohungen, die weltweit bekannt sind. Durch Verhaltensanalysen werden selbst noch unbekannte Ransomware- und Boot-Record-Angriffe zuverlässig erkannt und blockiert.</p>
        <p>Sophos Interception X blockiert Exploits und Verfahren, die zur Verbreitung von Malware, zum Diebstahl von Zugangsdaten und zum Aushebeln von Erkennungsmechanismen genutzt werden. So haben Hacker und Zero-Day-Angriffe keine Chance mehr, in Ihr
            Netzwerk zu gelangen. Dies macht Intercept X zur leistungsstärksten Ransomware-Technologie überhaupt.</p>
    </qloc-col-media>
</qloc-slide-in>


<qloc-slide-in>
    <qloc-card class="mt-5">
        <div class="row">
            <div class="col-lg-6 col-sm-12 col-xs-12 mt-4">
                <qloc-tick>Hocheffektiv durch neuronale Deep-Learning-Netzwerke</qloc-tick>
            </div>
            <div class="col-lg-6 col-sm-12 col-xs-12 mt-4">
                <qloc-tick>Automatisches Blockieren aller Ransomware-Bedrohungen</qloc-tick>
            </div>
            <div class="col-lg-6 col-sm-12 col-xs-12 mt-4">
                <qloc-tick>Intelligente rückstandslose Beseitigung von Schadsoftware beseitigt auch von Malware modifizierte Registry-Schlüssel.</qloc-tick>
            </div>
            <div class="col-lg-6 col-sm-12 col-xs-12 mt-4">
                <qloc-tick>Forensikbasierte Analysen bieten wertvolle Informationen zur Ursache von Angriffen und bieten Anweisungen zur Beseitigung von Infektionen und dessen Prävention.</qloc-tick>
            </div>
        </div>
    </qloc-card>
</qloc-slide-in>


<qloc-gradient class="mt-5" title="Sophos Central Device Encryption" id="SophosCentralDeviceEncryption">
    <qloc-slide-in>
        <qloc-col-media class="mt-5" iframe="https://player.vimeo.com/video/184031210?dnt=true" [downloads]="[['Datenblatt: Sophos Central Device Encryption','assets/products/msp/sophosdeviceencryption.pdf'], ['Whitepaper: Next-Gen Encryption: Datenschutz mit Sophos', 'assets/products/msp/Sophos-Next-Generation-Encryption-Technology-wpna.pdf']]">
            <p>Jedes Jahr gehen Millionen von Laptops verloren oder werden gestohlen viele mit wichtigen und vertraulichen Daten. In all diesen Fällen ist eine Festplattenverschlüsselung als erste Verteidigungslinie für den Schutz Ihrer Daten entscheidend.
                In Sophos Central können Sie Ihre Festplattenverschlüsselung über ein zentrales, webbasiertes Management Center verwalten.</p>
            <p>Sophos Central Device Encryption greift zum Schutz von Geräten und Daten auf Windows BitLocker und macOS FileVault zurück.</p>
            <p>Öffnen und Bearbeiten Sie verschlüsselte Dateien und wie gewohnt. Dateien, die Sie Personen außerhalb des Unternehmens bereitstellen möchten, können Sie mit einem Klick mit einen Passwort versehen.</p>
            <p>Als Teil der zentralen Security-Konsole Sophos Central wird Central Device Encryption gemeinsam mit unserer gesamten Reihe marktführender Schutzlösungen verwaltet. Dank der webbasierten Konsole müssen Sie keinen Server bereitstellen und auch
                keine Backend-Schlüssel-Server konfigurieren. Central Device Encryption nutzt den gleichen Core Agent wie Intercept X, sodass bestehende Sophos-Kunden keinen zusätzlichen Agenten installieren müssen und innerhalb von Minuten mit der Verschlüsselung
                von Computern beginnen können.</p>
            <p>Auf diese Weise schützt Sophos Ihre Daten, ohne dabei Ihre normalen Arbeitsabläufe zu behindern.</p>
        </qloc-col-media>
        <qloc-card-overview class="mt-5" title="Compliance, Reporting und Verwaltung">
            <qloc-tick>Festplatten-Verschlüsselung: Schützen Sie Geräte und Daten die in Sophos Central verwaltet werden.</qloc-tick>
            <qloc-tick>Intuitive Bedienung: Einfach einzurichten, einfach zu verwalten.</qloc-tick>
            <qloc-tick>Compliance: Überprüfen Sie den Verschlüsselungs-Status des Geräts.</qloc-tick>
            <qloc-tick>Self-Service Portal: Benutzer können den Zugriff auf ihre Geräte selbst wiederherstellen.</qloc-tick>
            <qloc-tick>Sicherer Dateiaustausch: Dokumente können nur von Empfängern mit richtigem Passwort geöffnet werden.</qloc-tick>
            <qloc-tick>Transparenz: Verschaffen Sie Admins einen Überblick über Geräte in ihrer gesamten Umgebung.</qloc-tick>
        </qloc-card-overview>
    </qloc-slide-in>
</qloc-gradient>


<h2 class="mt-5 hardware_heading" id="SophosHardware">Sophos Hardware</h2>
<qloc-slide-in>
    <qloc-col-media class="mt-4" title="Sophos Firewall: Extra starke Performance" image="assets/products/msp/sophos-XGS6500.webp" [downloads]="[['Datenblatt: Sophos Firewall','assets/products/msp/sophosfirewallreviewersguidede.pdf'], ['Produktpalette: Sophos Firewall','assets/products/msp/sophos-xg-series.pdf']]">
        <p>Die XGS-Serie bietet Xstream-Performance in jeder Preisklasse. So wird der extra starke Schutz gewährleistet, den Sie für die vielfältigen, verteilten und verschlüsselten Netzwerke von heute benötigen.</p>
        <p>Die ultimative Firewall-Lösung. Die Sophos Firewall passt sich an Ihr Netzwerk an und nicht anders herum. Sie müssen also keine Änderungen Ihrer Netzwerk-Infrastruktur vornehmen – die Sophos Firewall bietet bereits alle Funktionen und die erforderliche
            Flexibilität zum Schutz Ihrer individuellen Netzwerk-Umgebung.</p>
        <p>Die Sophos Firewall bietet Schutz der Enterprise-Klasse, unübertroffene Transparenz über Risiken und die nötige Flexibilität für anspruchsvolle verteilte Netzwerke von heute.</p>
        <p>Diese lässt sich in Ihre vorhandenen Technologien integrieren und bietet so eine zuverlässige, sichere und leistungsstarke Netzwerk-Security-Lösung.</p>
    </qloc-col-media>
</qloc-slide-in>

<div class="row mt-1">
    <div class="col-lg-4 col-12 mt-4">
        <qloc-slide-in class="h-100" delay="0.6">
            <qloc-card-overview class="h-100" title="TLS-1.3-Entschlüsselung" icon="">
                <p>Die schnelle und effektive TLS Inspection der Sophos Firewall unterstützt die neuesten Standards mit umfangreichen Ausnahmen und benutzerfreundlichen Point-and-Click-Richtlinien-Tools.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-12 mt-4">
        <qloc-slide-in class="h-100" delay="0.3">
            <qloc-card-overview class="h-100" title="Deep Packet Inspection" icon="">
                <p>Stoppen Sie neueste Ransomware und Sicherheitslücken mit Hochleistungs-Streaming-Deep Packet-Inspection, einschließlich Next-Gen IPS, Web Protection und App Control sowie Deep Learning und durch SophosLabs Intelix unterstütztes Sandboxing.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-12 mt-4">
        <qloc-slide-in class="h-100">
            <qloc-card-overview class="h-100" title="Anwendungs-Beschleunigung" icon="">
                <p>Beschleunigen Sie Ihren SaaS-, SD-WAN- und Cloud-Datenverkehr wie VoIP, Video und andere vertrauenswürdige Anwendungen automatisch oder über Ihre eigenen Richtlinien, indem Sie diese zur Optimierung der Performance auf dem Xstream FastPath
                    übertragen.
                </p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-12 mt-4">
        <qloc-slide-in class="h-100" delay="0.6">
            <qloc-card-overview class="h-100" title="Xstream-starker Schutz" icon="">
                <p>Beschleunigen und offloaden Sie Ihren wichtigen SaaS-, SD-WAN- und Cloud-Datenverkehr auf Hardware-Ebene und erweitern Sie mit integrierten Xstream-Flow-Prozessoren gleichzeitig die Performance-Reserven für TLS Inspection und Deep Packet
                    Inspection.
                </p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-12 mt-4">
        <qloc-slide-in class="h-100" delay="0.3">
            <qloc-card-overview class="h-100" title="Hochgeschwindigkeits-Konnektivität" icon="">
                <p>Passen Sie die bereits vielseitigen Anschlussmöglichkeiten Ihrer Firewall mit einer umfangreichen Auswahl von Add-on-Modulen für Hochgeschwindigkeits-Kupfer-, Glasfaser-, Power-over-Ethernet(PoE)- und WLAN-Konnketivität weiter an.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-12 mt-4">
        <qloc-slide-in class="h-100">
            <qloc-card-overview class="h-100" title="Maßgeschneiderte Performance
        " icon="">
                <p>Ihre Firewall wurde von Grund auf für die Verarbeitung besonders anspruchsvoller Workloads entwickelt und verfügt über eine Dual-Prozessor-Architektur, großzügige Speicherdimensionierung und leistungsstarken Solid-State Storage.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
</div>

<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Sophos Wireless: Synchronized Wireless Network" image="assets/products/msp/Sophos-AP.webp" [downloads]="[['Datenblatt: Sophos Wireless','assets/products/msp/sophos-wireless-protection-licensing-guide.pdf'], ['Produktpalette: Sophos Wireless','assets/products/msp/sophos-wireless-dsde.pdf']]">
        <p class="mt-4">Sophos Wireless ist die einfache und effektive Methode zur Verwaltung und Kontrolle von WLANs. Sophos Wireless wird über Sophos Central verwaltet und lässt sich ganz einfach eigenständig oder als Komponente cloudverwalteter Sicherheitslösungen
            bereitstellen. Eine vollständige Beschreibung der Funktionen erhalten Sie in diesem Datenblatt.</p>
        <p>Die Sophos Wireless-Lösung ist besonders auf die Bedürfnisse kleiner und mittlerer sowie weitverzweigter Unternehmen ausgerichtet. Sie werden insbesondere die einfache Einrichtung und Verwaltung zu schätzen wissen.</p>
        <p>Bestandskunden von Sophos Central können Sophos Wireless einfach bereitstellen. Die Lösung lässt sich zur Nutzung ausgewählter Synchronized-Security-Funktionen außerdem in Sophos Endpoint und Sophos Mobile integrieren.</p>
        <p>Firewall-/UTM-Kunden, die Ressourcen auf ihrer Hardware freisetzen möchten, können zudem von der Skalierbarkeit unserer cloudbasierten Lösung profitieren.</p>
    </qloc-col-media>
</qloc-slide-in>


<div class="row mt-1">
    <div class="col-lg-4 col-12 mt-4">
        <qloc-slide-in class="h-100" delay="0.6">
            <qloc-card-overview class="h-100" title="Synchronized Connectivity" icon="">
                <p>Steuern Sie den Zugriff verwalteter Clients automatisch auf der Grundlage ihres Integritätsstatus.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-12 mt-4">
        <qloc-slide-in class="h-100" delay="0.3">
            <qloc-card-overview class="h-100" title="Erkennen von Bedrohungen" icon="">
                <p>Bedrohungen in Ihren und für Ihre WLANs werden sofort kategorisiert (z. B. als unautorisierte APs, Manipulationsversuche oder unbekannte Geräte).</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-12 mt-4">
        <qloc-slide-in class="h-100">
            <qloc-card-overview class="h-100" title="Modulares Management-System" icon="">
                <p>Sie können Wireless alleine oder zusammen mit allen anderen über Sophos Central verwalteten Sicherheitslösungen verwalten.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-12 mt-4">
        <qloc-slide-in class="h-100" delay="0.6">
            <qloc-card-overview class="h-100" title="Synchronized Security: Vernetzte Sicherheitsdaten" icon="">
                <p>Sophos Wireless kombiniert die Leistungsstärke der Sophos Central Plattform mit unserer einzigartigen Security Heartbeat™-Funktionalität. So ist es möglich, den Sicherheitsstatus verbundener Endpoint- und mobiler Clients zu überwachen,
                    bei Bedarf Maßnahmen zu ergreifen und damit das Risiko für Ihre vertrauenswürdigen WLANs deutlich zu verringern.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-12 mt-4">
        <qloc-slide-in class="h-100" delay="0.3">
            <qloc-card-overview class="h-100" title="Zentrale Verwaltung" icon="">
                <p>Sophos Wireless lässt sich ganz einfach als Teil Ihres Sophos Central-Portfolios cloudverwalteter Sicherheitslösungen einrichten und bereitstellen. Mit Sophos Central können Sie Wireless alleine oder zusammen mit Endpoint, Mobile, Email,
                    Encryption, Server Protection und dem Firewall-Management verwalten</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-12 mt-4">
        <qloc-slide-in class="h-100">
            <qloc-card-overview class="h-100" title="Einfache Bereitstellung" icon="">
                <p>Die Access Points lassen sich ganz einfach bereitstellen. Mit unserer Hilfe erstellen Sie in drei einfachen Schritten Ihre Netzwerke, registrieren Ihre Access Points und richten einen oder mehrere Standorte ein.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
</div>


<qloc-gradient class="mt-5" title="Sophos Switch">
    <qloc-slide-in>
        <qloc-col-media image="assets/products/msp/products-competitive-intelligence.svg">
            <p>Sophos plant die Einführung einer Reihe von Access-Layer-Netzwerk-Switches für die Verwaltung in Sophos Central.</p>
            <p>Die Serie „Sophos Switch“ wird zehn Modelle umfassen, mit Optionen für 8, 24 und 48 Ports sowohl 1 GE als auch 2,5 GE, einschließlich Modellen mit Power over Ethernet.</p>
            <p>Mit vollständiger Transparenz und Zero-Touch-Bereitstellung über Sophos Central sowie der geplanten Integration der Synchronized-Security-Funktionalität in einer zukünftigen Version sind die Switches die perfekte Ergänzung des bestehenden
                Netzwerk-Portfolios auf Access-Layer-Ebene.</p>
            <p>Erste Modelle werden voraussichtlich ab dem 4. Quartal 2021 erhältlich sein.</p>
        </qloc-col-media>
    </qloc-slide-in>
</qloc-gradient>


<qloc-interesse class="mt-5"></qloc-interesse>
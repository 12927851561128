<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">
    <qloc-card class="mt-5 mb-5">


        <qloc-col-media class="mb-4" title="Tenable.ep" image="assets/unternehmen/partners/tenable_ep.svg"
            [downloads]="[['Tenable Exposure Plattform Datenblatt','assets/products/tenable/tenable_ep_datasheet.pdf']]">
            <h5 class="mb-3">Die Cyber Exposure sämtlicher Assets auf jeder Plattform jederzeit im Blick.</h5>
            <p>
                Tenable.ep ist eine umfassende Lösung für risikobasiertes Schwachstellen-Management, mit der Sie die
                Cyber Exposure all Ihrer Assets zu ermitteln können – jederzeit, überall und auf jeder Plattform.
            </p>
            <p>
                Mit Tenable.ep können Sie jedes Asset und jede Schwachstelle in Ihrer gesamten Angriffsoberfläche sehen,
                vorhersagen, bei welchen Schwachstellen in nächster Zeit ein Angriff am wahrscheinlichsten ist, und
                Maßnahmen für die ermittelten Prioritäten ergreifen.
            </p>
            <p>
                Die Plattform versetzt Sie in die Lage, mit minimalem Aufwand eine maximale Reduzierung des Risikos zu
                bewirken.
            </p>
        </qloc-col-media>




        <div class="row">
            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6" title="VOLLSTÄNDIGE SICHTBARKEIT">
                    <qloc-card-overview class="h-100">
                        <p>
                            Beseitigen Sie blinde Flecken in Ihrer gesamten Angriffsoberfläche, zu der herkömmliche
                            IT-Assets, Cloud-Services, operative Technologien (OT), moderne Webanwendungen, Active
                            Directory (AD)-Domänen und Ihre externe Angriffsoberfläche gehören.
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>

            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6" title="FOKUSSIERTE ERGEBNISSE">
                    <qloc-card-overview class="h-100">
                        <p>
                            Durch die kontinuierliche Analyse von mehr als 20 Billionen Aspekten von Bedrohungs-,
                            Schwachstellen- und Asset-Daten mithilfe von maschinellen Lernalgorithmen ermöglicht
                            Tenable.ep Ihrem Unternehmen, sich zuerst auf das Wesentliche zu konzentrieren und den
                            Behebungsaufwand zu reduzieren.
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
        </div>
    </qloc-card>
</qloc-slide-in>

<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">
    <qloc-card class="mt-5 mb-5">

        <qloc-col-media title="Tenable.io" image="assets/unternehmen/partners/tenable_io.svg"
            [downloads]="[['Tenable.io Datenblatt','assets/products/tenable/tenable_io_datasheet.pdf']]">
            <h5 class="mb-3">Voller Einblick und wirklich relevante Prognosen.</h5>
            <p>
                Mit Tenable.io können Sie Ihr Risiko nachvollziehen und wissen genau, welche Schwachstellen zuerst zu
                beheben sind.
            </p>
            <p>
                Nutzen Sie cloudbasiertes Schwachstellen-Management für vollständigen Einblick in die Assets und
                Schwachstellen in Ihrem Unternehmen.
            </p>
            <p>
                Tenable.io wird in der Cloud verwaltet und basiert auf Nessus-Technologie. Neben der branchenweit
                umfangreichsten Schwachstellen-Abdeckung lässt sich mit Tenable.io bestimmen, welche Sicherheitsprobleme
                zuerst behoben werden müssen. Verlassen Sie sich auf Ihre End-to-End-Komplettlösung für das
                Schwachstellen-Management.
            </p>
        </qloc-col-media>

        <div class="row mt-4">
            <div class="col-lg-12 mb-4">
                <h5 class="mb-3">Probleme bei der Absicherung Ihrer AWS-Assets? Testen Sie Frictionless Assessment
                </h5>

                <p>
                    Schluss mit dem Einsatz von Netzwerk-Scannern und Agents für die Cloud. Frictionless Assessment
                    in Tenable.io verwendet cloud-native Tools, um kontinuierlich und nahezu in Echtzeit Einblick in
                    Ihre AWS-Expositionen zu bieten.
                </p>

                <p>
                    Es müssen weder Scanner oder Agents bereitgestellt, noch Zeitpläne konfiguriert und Software
                    oder Zugangsdaten verwaltet werden. Blinde Flecken gehören jetzt der Vergangenheit an.
                </p>

                <p>
                    Das Tool lässt sich sekundenschnell einrichten und liefert verwertbare Ergebnisse in nur wenigen
                    Minuten – völlig reibungslos.
                </p>
            </div>
            <div class="col-lg-12 mb-4">
                <h5 class="mb-3">Risiken im gesamten OT-Betrieb reduzieren, von überall aus</h5>

                <p>
                    Wenn Sie mit Industriebetrieben oder kritischen Infrastrukturen zu tun haben, wissen Sie, dass
                    Risiken nicht nur im IT-Bereich bestehen.
                </p>
                <p>
                    Tenable.ot lässt sich jetzt mit Tenable.io integrieren, sodass Sie vollständigen Einblick in Ihr
                    OT-Risiko erhalten und Ihre Umgebung über die Cloud verwalten können.
                </p>
            </div>
        </div>
    </qloc-card>
</qloc-slide-in>

<qloc-interesse></qloc-interesse>
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'qloc-page-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss']
})
export class QlocPage_KontaktComponent implements OnInit {

  requestData = {
    firstname: '',
    lastname: '',
    mail: '',
    tel: '',
    company: '',
    position: '',
    message: '',
    privacy: null,
    captcha: '',
  };
  isBrowser: boolean;

  success = '';
  error = '';
  warning = '';

  constructor(
    private apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId: any
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
    }

  ngOnInit(): void {
  }

  verifyCaptcha(token: string): void {
    this.requestData.captcha = token;
  }

  submit(): void {

    this.warning = '';
    this.error = '';
    this.success = '';

    if (this.requestData.firstname === '') {
      this.warning = 'Bitte Vorname eingeben!';
      return;
    }

    if (this.requestData.lastname === '') {
      this.warning = 'Bitte Nachname eingeben!';
      return;
    }

    if (this.requestData.tel === '') {
      this.warning = 'Bitte Telefonnummer eingeben!';
      return;
    }

    if (this.requestData.mail === '') {
      this.warning = 'Bitte E-Mail Adresse eingeben!';
      return;
    }

    if (this.requestData.message === '') {
      this.warning = 'Bitte Nachricht eingeben!';
      return;
    }

    if (this.requestData.privacy === null) {
      this.warning = 'Sie müssen die Datenschutzerklärung akzeptieren!';
      return;
    }

    if (this.requestData.captcha === '') {
      this.warning = 'Sie müssen das Captcha bestätigen!';
      return;
    }

    this.apiService.sendContactData(this.requestData).subscribe(data => {
      this.warning = '';
      this.error = '';
      eval('hcaptcha.reset()');
      this.success = 'Vielen Dank für Ihr Interesse. Unsere Kundenbetreuer werden sich in Kürze bei Ihnen melden!';
    }, (error => {
      console.log(error);
      eval('hcaptcha.reset()');

      if (error.error.message && error.error.message === 'Captcha validation failed!') {
        this.error = 'Fehler beim Bestätigen des Captcha! Bitte versuchen Sie es erneut.';
        return;
      }

      this.error = 'Es ist ein Fehler aufgetreten und Ihre Nachricht konnte leider nicht gesendet werden! Bitte versuchen Sie es später erneut, oder wenden Sie sich an info@qloc.de.';
    }));
  }

}

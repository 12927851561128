import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-3cx',
  templateUrl: './3cx.component.html',
  styleUrls: ['./3cx.component.scss']
})
export class QlocPage_3CXComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'
import { MatExpansionModule } from '@angular/material/expansion'; // Importiere das Material-Modul
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QlocComponentsModule } from '../components/components.module';
import { QlocPage_HomeComponent } from './home/home.component';
import { QlocPage_DellComponent } from './hardware/dell/dell.component';
import { QlocPage_NotFoundComponent } from './not-found/not-found.component';
import { QlocPage_FujitsuComponent } from './hardware/fujitsu/fujitsu.component';
import { QlocPage_IPTelefoneComponent } from './hardware/ip-telefone/ip-telefone.component';
import { QlocPage_ITServiceComponent } from './dienstleistungen/it-service/it-service.component';
import { QlocPage_BMWIComponent } from './dienstleistungen/bmwi/bmwi.component';
import { QlocPage_AcronisComponent } from './dienstleistungen/acronis/acronis.component';
import { QlocPage_O365Component } from './dienstleistungen/o365/o365.component';
import { QlocPage_3CXComponent } from './dienstleistungen/3cx/3cx.component';
import { QlocPage_MailstoreComponent } from './dienstleistungen/mailstore/mailstore.component';
import { QlocPage_NoSpamProxyComponent } from './dienstleistungen/nospamproxy/nospamproxy.component';
import { QlocPage_SophosComponent } from './dienstleistungen/sophos/sophos.component';
import { QlocPage_KontaktComponent } from './unternehmen/kontakt/kontakt.component';
import { QlocPage_TechnikComponent } from './unternehmen/technik/technik.component';
import { QlocPage_ReferenzenComponent } from './unternehmen/referenzen/referenzen.component';
import { QlocPage_PartnerComponent } from './unternehmen/partner/partner.component';
import { QlocPage_LizenzenComponent } from './dienstleistungen/lizenzen/lizenzen.component';
import { QlocPage_KarriereComponent } from './unternehmen/karriere/karriere.component';
import { QlocPage_PresseComponent } from './unternehmen/presse/presse.component';
import { QlocPage_ImpressumComponent } from './unternehmen/impressum/impressum.component';
import { QlocPage_AGBComponent } from './unternehmen/agb/agb.component';
import { QlocPage_DatenschutzComponent } from './unternehmen/datenschutz/datenschutz.component';
import { QlocPage_WiderrufComponent } from './unternehmen/widerruf/widerruf.component';
import { QlocPage_NextcloudComponent , QlocPage_NextcloudCalculatorComponent } from './dienstleistungen/nextcloud/nextcloud.component';
import { QlocPage_LenovoComponent } from './hardware/lenovo/lenovo.component';
import { NgHcaptchaModule } from 'ng-hcaptcha';
// import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from 'ngx-slider-v2';
import { QlocPage_ITSecurityComponent } from './dienstleistungen/it-security/it-security.component';
import { QlocPage_CitrixComponent } from './dienstleistungen/citrix/citrix.component';
import { QlocPage_FortinetComponent } from './dienstleistungen/fortinet/fortinet.component';
import { QlocPage_CrowdstrikeComponent } from './dienstleistungen/crowdstrike/crowdstrike.component';
import { QlocPage_TenableComponent } from './dienstleistungen/tenable/tenable.component';
import { QlocPage_ITSealComponent } from './dienstleistungen/it-seal/it-seal.component';
import { QlocPage_AwarenessHeroComponent } from './dienstleistungen/awareness-hero/awareness-hero.component';
import { QlocPage_BarcoComponent } from './dienstleistungen/barco/barco.component';
import { QlocPage_VersicherungComponent } from './dienstleistungen/versicherung/versicherung.component';

@NgModule({
  declarations: [
    QlocPage_HomeComponent,
    QlocPage_DellComponent,
    QlocPage_NotFoundComponent,
    QlocPage_FujitsuComponent,
    QlocPage_IPTelefoneComponent,
    QlocPage_ITServiceComponent,
    QlocPage_ITSecurityComponent,
    QlocPage_CitrixComponent,
    QlocPage_BMWIComponent,
    QlocPage_AcronisComponent,
    QlocPage_O365Component,
    QlocPage_3CXComponent,
    QlocPage_MailstoreComponent,
    QlocPage_NoSpamProxyComponent,
    QlocPage_SophosComponent,
    QlocPage_KontaktComponent,
    QlocPage_TechnikComponent,
    QlocPage_ReferenzenComponent,
    QlocPage_PartnerComponent,
    QlocPage_LizenzenComponent,
    QlocPage_KarriereComponent,
    QlocPage_PresseComponent,
    QlocPage_ImpressumComponent,
    QlocPage_AGBComponent,
    QlocPage_DatenschutzComponent,
    QlocPage_WiderrufComponent,
    QlocPage_NextcloudComponent,
    QlocPage_NextcloudCalculatorComponent,
    QlocPage_LenovoComponent,
    QlocPage_FortinetComponent,
    QlocPage_CrowdstrikeComponent,
    QlocPage_TenableComponent,
    QlocPage_ITSealComponent,
    QlocPage_AwarenessHeroComponent,
    QlocPage_BarcoComponent,
    QlocPage_VersicherungComponent
  ],
  imports: [
    QlocComponentsModule,
    FontAwesomeModule,
    NgbModule,
    BrowserModule,
    MatExpansionModule, 
    NgHcaptchaModule,
    // NgxCookiebotModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule
  ],
  providers: [],
  bootstrap: []
})
export class QlocPageModule { }

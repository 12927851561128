<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Powered by Nextcloud" image="assets/products/nextcloud/nextcloud.webp">
        <p>Nextcloud ist die Filesync- und -share-Lösung für Unternehmen und Organisationen, die nicht riskieren wollen, dass sensible Daten bei externen Cloud-Services verstreut werden. Nextcloud integriert sich nahtlos in die existierende IT-Infrastruktur
            und ermöglicht die Weiternutzung bereits vorhandener Security- und Management-Tools.</p>
        <div class="row mt-4">
            <div class="col-xl-6 col-lg-12 p-2">
                <a href="https://cloud4schools.de" target="_blank" style="font-size: 20px;" class="w-100 btn btn-qloc" data-umami-event="page-nextcloud-schools-link">
                    <fa-icon icon="school"></fa-icon> Für Bildungseinrichtungen</a>
            </div>
            <div class="col-xl-6 col-lg-12 p-2">
                <a href="https://cloud4businesses.de" target="_blank" style="font-size: 20px;" class="w-100 btn btn-qloc" data-umami-event="page-nextcloud-businesses-link">
                    <fa-icon icon="building"></fa-icon> Für Unternehmen</a>
            </div>
        </div>
    </qloc-col-media>
</qloc-slide-in>

<!--<qloc-page-nextcloud-calculator></qloc-page-nextcloud-calculator>-->

<div class="mt-5 row">
    <div class="col-lg-6 col-12">
        <qloc-slide-in delay="0.2">
            <qloc-tick>Hosted in Deutschland <a href="technik" data-umami-event="page-nextcloud-technik-link">Weitere Informationen</a></qloc-tick>
            <qloc-tick>Sicherheit nach deutschem Datenschutz</qloc-tick>
            <qloc-tick>Serverseitige Verschlüsselung nach 256 Bit AES Standards optional</qloc-tick>
            <qloc-tick>TLS verschlüsselte Verbindung über Let's Encrypt</qloc-tick>
            <qloc-tick>Traffic inklusiv</qloc-tick>
            <qloc-tick>Kein Benutzer Limit</qloc-tick>
            <qloc-tick>Kostenlose Domainintegration</qloc-tick>
        </qloc-slide-in>
    </div>

    <div class="col-lg-6 col-12">
        <qloc-slide-in>
            <qloc-tick>Eigene Instanz / Volle Administration</qloc-tick>
            <qloc-tick>WebDAV Synchronisierung</qloc-tick>
            <qloc-tick>PC & App Sync Client</qloc-tick>
            <qloc-tick>Inhalte teilen</qloc-tick>
            <qloc-tick>Kontakte, Kalender und Aufgaben</qloc-tick>
            <qloc-tick>Dokumente und Lesezeichen</qloc-tick>
            <qloc-tick>Tägliche Datensicherung</qloc-tick>
        </qloc-slide-in>
    </div>
</div>

<qloc-fullscreen-bg bgcolor="#12457f" color="#FFFFFF" class="mt-5" title="Datensicherheit - Unser wichtigstes Gut">
    <p class="mt-4">Die sichere und effiziente Aufbewahrung von Daten ist in der heutigen Zeit von essentiellem Wert. Mit unserem Nextcloud Produkt erhalten Sie einige gesonderte Sicherheitsfeatures die die Sicherheit der gespeicherten Daten garantieren.</p>
    <div class="row mt-5 mb-5">
        <div class="col-md-4">
            <qloc-slide-in class="h-100" delay="0.6">
                <qloc-card-overview class="h-100" icon="database" title="Eigene Instanz">
                    <p>Bei unserem Nextcloud Hosting erhalten Sie eine Nextcloud Instanz auf einem eigenen virtuellen Server. Ihre Daten werden nicht mit anderen Kunden geteilt und Sie erhalten die volle Sicherheit und Performance Ihrer ganz persönlichen
                        Nextcloud Instanz.
                    </p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>

        <div class="col-md-4">
            <qloc-slide-in class="h-100" delay="0.3">
                <qloc-card-overview class="h-100" icon="eye" title="Managed Service">
                    <p>Unser Produkt betreiben wir als Managed Service. Das bedeutet, dass wir als Dienstleister uns vollständig um den Betrieb der Instanz kümmern. Unsere Techniker überprüfen regelmäßig die Nextcloud auf den technischen Zustand und passen
                        bei Leistungsveränderungen die Ressourcen an.<br>Gerne gehen wir auf Ihre speziellen Wünsche zur Konfiguration ein!</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>

        <div class="col-md-4">
            <qloc-slide-in class="h-100">
                <qloc-card-overview class="h-100" icon="hdd" title="Backups">
                    <p>Wir erstellen täglich Backups der gesamten Nextcloud Instanz, welche für sieben Tage in unserem hochgesicherten Backup Storage gespeichert werden. Sollten einmal Daten verloren gehen, können Sie jederzeit über den Kundenservice Teile
                        oder ein ganzens Backup einspielen lassen.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
    </div>

</qloc-fullscreen-bg>


<h3 class="mt-5">Features und Optionen</h3>
<div class="row">
    <div class="col-lg-4 col-xs-12 mt-4">
        <qloc-slide-in class="h-100" delay="0.6">
            <qloc-card-overview class="h-100" icon="cloud" title="Automatische Bereitstellung">
                <p>Nach Abschluss Ihrer Bestellung wird Ihr Nextcloud Account automatisch von unserem System angelegt und die Zugangsdaten im Kundencenter freigeschaltet.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-xs-12 mt-4">
        <qloc-slide-in class="h-100" delay="0.3">
            <qloc-card-overview class="h-100" icon="history" title="99,9% Verfügbarkeit">
                <p>Wir garantieren eine 99,9% Verfügbarkeit zum Jahresmittel. Wartungsfenster und unerwartete Ausfälle sind hiervon ausgenommen.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-xs-12 mt-4">
        <qloc-slide-in class="h-100">
            <qloc-card-overview class="h-100" icon="phone" title="Kunden Support">
                <p>Der direkte Kontakt zwischen Ihnen und unseren Kundenbetreuern liegt uns sehr am Herzen. Aus diesem Grund erreichen Sie uns ganz schnell und bequem per Ticket, Email oder Telefon.
                </p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-xs-12 mt-4">
        <qloc-slide-in class="h-100" delay="0.6">
            <qloc-card-overview class="h-100" icon="lock" title="Verschlüsselung">
                <p>Verschlüsseln Sie Ihre Daten (nach Aktivierung) mit aktuellen Sicherheitsalgorythmen gemäß deutschem/europäischem Datenschutzrecht.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-xs-12 mt-4">
        <qloc-slide-in class="h-100" delay="0.3">
            <qloc-card-overview class="h-100" icon="shield-alt" title="DDoS Schutz inklusive">
                <p>Unsere Nextcloud sind mit einem DDoS-Schutz versehen, der eingehende Angriffe automatisiert blockiert und so einen störungsfreien Betrieb gewährt.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-xs-12 mt-4">
        <qloc-slide-in class="h-100">
            <qloc-card-overview class="h-100" icon="hourglass-half" title="Testen Sie unser Angebot 7 Tage">
                <p>Sie möchten sich von unseren Dienstleistungen überzeugen? Testen Sie Nextcloud Hosting Trial mit 15 GB Speicherplatz unverbindlich.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
</div>


<qloc-slide-in>
    <qloc-card class="mt-5" title="Collaboration mit Nextcloud">
        <p class="mt-4">Ihre Daten in der Cloud abzulegen ist nur der Erste Schritt auf dem Weg zur Industrie 4.0. Collaboration ermöglicht Ihnen das Erstellen und Bearbeiten von Office Dokumenten direkt im Webbrowser. Arbeiten Sie auch uneingeschränkt parallel in Teams
            an demselben Dokument. <br>Unterstützt werden alle modernen Browser (kein Plugin erforderlich). Wir integrieren die beliebtesten Collaboration Lösungen direkt in Ihre Nextcloud:</p>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4">
                <a target="_blank" href="https://www.onlyoffice.com/de/" data-umami-event="page-nextcloud-onlyoffice-link"><img src="assets/products/nextcloud/ONLYOFFICE-logo.webp" alt="ONLYOFFICE" width="80%" height="auto"></a>
                <p class="mt-3">ONLYOFFICE bietet eine hohe Kompatibilität zu Formatierungen von Microsoft Office Dokumenten. Bearbeiten Sie docx, xlsx, pptx und txt Dateien direkt in Ihrem Webbrowser. Unterstützt werden auch odt, ods, odp, doc, xls, ppt, pps, epub,
                    rtf, html und htm Dateien. </p>
                <p>Bei ONLYOFFICE ist die Anzahl der gleichzeitigen Sitzungen vom Hersteller auf 20 begrenzt.</p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4">
                <a target="_blank" href="https://nextcloud.com/collaboraonline/" data-umami-event="page-nextcloud-collabora-link"><img src="assets/products/nextcloud/Collabora-Online.webp" alt="Collabora Online" width="80%" height="auto" style="padding-bottom: 18px;"></a>
                <p class="mt-3">Collabora Online ist eine leistungstarke auf LibreOffice basierende Office Lösung. Unterstützt werden Textdokumente (odt, docx, doc,...), Tabellen (ods, xlsx, xls,...) und Präsentationen (odp, pptx, ppt,...). </p>
                <p> Bei Collabora Online ist die Anzahl der gleichzeitigen Sitzungen vom Hersteller auf 20 begrenzt.</p>
            </div>
        </div>

        <p class="mt-3">Unser Office Paket erhalten Sie bei uns als Zusatzpaket welches Sie bequem jederzeit über unseren Kundenservice bestellen können. Das Paket beinhaltet den jeweiligen Dokumentenserver der mit zusätzlichen Ressourcen Ihrer eigenen Nextcloud hinzugefügt
            wird. Alle Ihre Daten bleiben ununterbrochen in Ihrer sicheren Nextcloud Umgebung.</p>
    </qloc-card>
</qloc-slide-in>



<qloc-interesse class="mt-5"></qloc-interesse>
import { Component } from '@angular/core';

@Component({
  templateUrl: './3cx.component.html'
})
export class QlocSubheader_3CXComponent { }

@Component({
  templateUrl: './acronis.component.html'
})
export class QlocSubheader_AcronisComponent { }

@Component({
  templateUrl: './bmwi.component.html'
})
export class QlocSubheader_BMWIComponent { }

@Component({
  templateUrl: './it-service.component.html'
})
export class QlocSubheader_ITServiceComponent { }

@Component({
  templateUrl: './mailstore.component.html'
})
export class QlocSubheader_MailstoreComponent { }

@Component({
  templateUrl: './nospamproxy.component.html'
})
export class QlocSubheader_NoSpamProxyComponent { }

@Component({
  templateUrl: './o365.component.html'
})
export class QlocSubheader_O365Component { }

@Component({
  templateUrl: './sophos.component.html'
})
export class QlocSubheader_SophosComponent { }

@Component({
  templateUrl: './nextcloud.component.html'
})
export class QlocSubheader_NextcloudComponent { }

@Component({
  templateUrl: './it-security.component.html'
})
export class QlocSubheader_ITSecurityComponent { }

@Component({
  templateUrl: './citrix.component.html'
})
export class QlocSubheader_CitrixComponent { }

@Component({
  templateUrl: './fortinet.component.html'
})
export class QlocSubheader_FortinetComponent { }

@Component({
  templateUrl: './crowdstrike.component.html'
})
export class QlocSubheader_CrowdstrikeComponent { }

@Component({
  templateUrl: './tenable.component.html'
})
export class QlocSubheader_TenableComponent { }

@Component({
  templateUrl: './it-seal.component.html'
})
export class QlocSubheader_ITSealComponent { }

@Component({
  templateUrl: './awareness-hero.component.html'
})
export class QlocSubheader_AwarenessHeroComponent { }
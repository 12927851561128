<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">


    <qloc-card-media class="mt-5 mb-5" title="IT-Seal" mediastyle="float: right;" image="assets/unternehmen/partners/it-seal.webp">
        <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum architecto dolores minus quo in! Rem esse, corporis quasi, explicabo deleniti, pariatur et necessitatibus reprehenderit laborum quaerat sint ducimus praesentium assumenda.
        </p>
        <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum, commodi necessitatibus? Eligendi ad minima laborum eaque magnam, omnis iure amet unde, reiciendis dolores praesentium deserunt facilis soluta obcaecati expedita corporis.
        </p>
        <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vitae facilis nobis possimus iure maxime voluptatum nemo voluptate natus placeat reprehenderit, libero sapiente est debitis magni consectetur repudiandae id porro perferendis. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis ex aliquam consequatur vel quisquam commodi, animi aut! Facere dolores maxime, ex illum ipsum id sunt cumque beatae corrupti labore reprehenderit.
        </p>
        <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur sed voluptas dicta modi suscipit expedita dolor, maxime nihil corporis sequi quisquam, facere sit vel dolorem cupiditate nesciunt esse reprehenderit error?
        </p>
    </qloc-card-media>
</qloc-slide-in>
<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Unsere Leistungen" image="assets/products/fujitsu/Fujitsu_SELECT_Expert.webp">
        <p>Unser Leistungsspektrum reicht von der Einrichtung und Inbetriebnahme über die Betreuung und Wartung von Computersystemen, Netzwerken und Rechenzentren bis hin zur Ablösung und Migration von IT-Systemen.<br> Bei uns erhalten Sie IT-Komponenten
            aller Kategorien aus einer Hand mit hohen Qualitätsstandards aus dem Hause Fujitsu, die Sie für einen reibungslosen Arbeitsalltag in Ihrem Unternehmen benötigen.<br> Wir garantieren eine schnelle und zuverlässige Lieferung. Gerne liefern wir
            auch direkt an verschiedene Standorte Ihres Unternehmens. Darüber hinaus unterstützen wir Sie gerne bei der Einrichtung und Installation Ihrer Hardware- und Softwarekomponenten.</p>
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Professionelle Monitore und Zubehör ein Hochgenuss für Ihre Augen" image="assets/products/fujitsu/monitor.webp" [size]="6">
        <p>
            Die FUJITSU Displays bieten das perfekte Gleichgewicht zwischen Ergonomie und Produktivität. FUJITSU Displays wurden im Hinblick auf Benutzerfreundlichkeit, Benutzerkomfort und Zuverlässigkeit konzipiert und eignen sich für alle Umgebungen, in kleinen,
            mittleren sowie großen Unternehmen. FUJITSU Displays bieten eine revolutionäre Performance in Verbindung mit effizienten und umweltfreundlichen Merkmalen sowie einem breiten Spektrum von Konnektivitätsoptionen für den Arbeitsplatz der Zukunft.
        </p>
    </qloc-card-media>
</qloc-slide-in>


<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Entfesseln Sie die Leistung von Client Cloud Computing noch heute in Ihrem Unternehmen mit FUJITSU Thin Clients!" image="assets/products/fujitsu/thinclient.webp" [size]="6" [left]="true">
        <p>
            FUTRO Thin Clients sind ideal für Client Cloud Computing, SBC- und VDI-Szenarien. All unsere Thin Clients wurden im Hinblick darauf konzipiert, mittels Vereinfachung durch eine zentralisierte Administration Leistung, Sicherheit und Manageability sicherzustellen,
            damit Sie Kosten sparen und Ihre IT-Operationen beschleunigen können. FUTRO Thin Clients tragen gegenüber standardmäßigen PCs dank ihrer langen Produktlebenszyklen auch zu einer niedrigeren TCO bei. Die kleine Stellfläche sorgt in Verbindung
            mit dem lüfterlosen Design für einen geräuschlosen Betrieb und Ergonomie und so für maximalen Benutzerkomfort.
        </p>
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card-media class="mt-5" title="FUJITSU Workstations CELSIUS & FUJITSU Desktops ESPRIMO" image="assets/products/fujitsu/fatclient.webp" [size]="6">
        <p>
            CELSIUS Workstations von Fujitsu unterstützen Kreativität, Simulationen, Berechnungen und Visualisierungen auf höchstem professionellen Niveau. Dank unserer ganzheitlichen Kompetenz, von der Planung und Konstruktion bis hin zur Fertigung, sind CELSIUS
            Workstations für ihren flüsterleisen Betrieb, ein geniales Wärmemanagement und eine raffinierte Kühlung sowie für die Erzielung weltweit führender Benchmark-Ergebnisse bekannt. Alle mobilen, Desktop- und Rack-Workstations wurden für die Nutzung
            einer Vielzahl führender Workstation-Anwendungen optimiert, wie Autodesk, Dassault Systèmes, Siemens PLM oder PTC.
        </p>
        <p>
            DIE FUJITSU Desktop ESPRIMO PCs sind auf eine kontinuierliche, stabile und hohe Leistung für rechenintensive Computeranforderungen ausgerichtet. Die Desktop-PCs sind mit der neuesten Technologie, einem Chipsatz und Prozessoren von Intel® ausgestattet.
            Zudem ermöglicht ein erweiterbares Gehäuse den Anwendern, ihr Gerät nach den eigenen Computeranforderungen zu wählen.
        </p>
    </qloc-card-media>
</qloc-slide-in>


<qloc-slide-in>
    <qloc-col-media class="mt-5" title="FUJITSU LIFEBOOKS für maximale Mobilität" image="assets/products/fujitsu/laptop.webp" [size]="6" [left]="true">
        <p>
            Wenn Sie ein zuverlässiges, mobiles Gerät suchen, bietet Ihnen das umfassende Fujitsu LIFEBOOK-Portfolio eine reichhaltige Auswahl an Lösungen vom Einstiegsgerät bis hin zu leistungsstarken, individuellen Produkten. Umfangreiche Konfigurationsoptionen
            garantieren maximale Flexibilität und höchsten Komfort, während die energiesparende Technologie für eine positive Umweltbilanz der Notebooks sorgt. Darüber hinaus zeichnet sich jedes Fujitsu LIFEBOOK durch maximale Zuverlässigkeit aus, die
            auf der einzigartigen Kombination aus deutscher Präzision und japanischer Qualität aus 30 Jahren Erfahrung basiert.
        </p>
        <p>
            Für unsere Notebooks finden Sie ein breites Spektrum an Sicherheitsfunktionen: PalmSecureTM, Gesichtserkennung, ePrivacy Filter, Fingerprint-Sensor, Webcam-Abdeckung und viele mehr. Genießen Sie das Gefühl, die beste und praktischste Entscheidung für
            Ihre Sicherheit getroffen zu haben.
        </p>
    </qloc-col-media>
</qloc-slide-in>


<qloc-slide-in>
    <qloc-card-media class="mt-5" title="FUJITSU Server PRIMERGY Rack-Systeme & FUJITSU Server PRIMERGY TX Tower-Systeme" [image]="['assets/products/fujitsu/towerserver.webp ', 'assets/products/fujitsu/server_storage.webp ']" [size]="6">
        <p>
            FUJITSU Server PRIMERGY RX Rack-Systeme sind vielseitige, für den Rack-Einbau optimierte Server, die eine branchenführende Leistung und Energieeffizienz bieten und so den "Standard" in jedem Rechenzentrum bestimmen. In die PRIMERGY RX Server sind etwa
            20 Jahre an Entwicklungs- und Produktions-Know-how eingeflossen, was in extrem niedrigen, unter dem Marktdurchschnitt liegenden Ausfallraten, einem durchgängigen Betrieb und hervorragender Hardwareverfügbarkeit resultiert.
        </p>
        <p>
            Es gibt sicher keine Einheitslösung. FUJITSU Server PRIMERGY ist das branchenweit umfassendste x86-basierte Portfolio, und die neusten PRIMERGY Modelle bieten sogar eine noch größere Auswahl. Mit vielseitigen, rack-optimierten Servern, die eine branchenführende
            Leistung und Energieeffizienz liefern. Robuste Tower-Systeme, die einen leisen und einfachen Betrieb gewährleisten – und die meisten lassen sich mit einem Umrüstsatz sogar in einem Rack montieren. Oder Systeme mit massiver Scale-out-Leistung,
            auf die Sie sich hinsichtlich High Performance Computing (HPC), Hosting und hyper-konvergente Stacks verlassen können.
        </p>
        <p>
            FUJITSU Server PRIMERGY TX Tower-Systeme sind robuste und kostengünstige Server, die sich durch eine grundsolide Zuverlässigkeit auszeichnen und sich perfekt für kleine und mittlere Unternehmen sowie Zweigstellen eignen. Charakteristisch für sie sind
            ein einfacher IT-Betrieb, geringer Energieverbrauch und niedrige Geräuschemissionen. So können sie auch von Mitarbeitern ohne technische Kenntnisse bedient und in normalen Büroumgebungen eingesetzt werden. Übrigens: Fast alle FUJITSU Server
            PRIMERGY TX Tower-Systeme lassen sich in ein Rack einbauen, um maximale Flexibilität zu bieten.
        </p>
    </qloc-card-media>
</qloc-slide-in>

<qloc-gradient title="Service und Lieferung" class="mt-5">
    <div class="row row-eq-height mt-4 mb-4">
        <div class="col-lg-4 col-12 mt-4">
            <qloc-slide-in delay="0.6" class="h-100">
                <qloc-card-overview class="h-100" title="Vor Ort Service">
                    <p>Sollte es einmal zu technischen Problemen an der Hardware kommen, bietet Fujitsu mit dem Vor Ort Service einen Dienst, der zum nächsten Arbeitstag alle erfolderlichen Arbeiten ausführen kann.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-4">
            <qloc-slide-in delay="0.3" class="h-100">
                <qloc-card-overview class="h-100" title="Mission Critical Service">
                    <p>Da es oft auch einmal auf Minuten ankommt, bieten wir mit Fujitsu einen Mission Critical Service, der sich speziell an Ihre Anforderungen anpasst. <br /> Somit ist ein reibungsloser Betrieb Ihrer Systeme immer garantiert!</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-4">
            <qloc-slide-in class="h-100">
                <qloc-card-overview class="h-100" title="Individuelle Konfiguration & Lieferung">
                    <p>Wir beraten Sie persönlich und konfigurieren für Sie jedes System inidividuell entsprechend Ihrer Anforderungen. Gerne liefert wir Ihnen auch große Stückzahlen an mehrere Betriebs-Standorte und übernehmen für Sie den Aufbau sowie die
                        Konfiguration.
                    </p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
    </div>
</qloc-gradient>

<qloc-interesse></qloc-interesse>
<h3 class="mt-5 mb-4">
    Lizenzen</h3>
<div class="mb-4">
    Unser Produktportfolio beinhaltet eine umfassende Auswahl an Lizenzmodellen von verschiedenen Partnern. Hier sehen Sie einen Auszug unserer Partner. Bei Interesse kontaktieren Sie uns gerne. Wir finden gemeinsam ein auf Sie zugeschnittenes Lizenzprodukt,
    um Ihren Wünschen bestmöglich zu entsprechen.
</div>
<div class="row mb-5">
    <div class="col-lg-4 col-md-6 col-12 mt-3" *ngFor="let ref of partner;">
        <qloc-card-overview class="h-100" [title]="ref.title" [image]="ref.image" footer="Branche: {{ ref.branche }}" mediastyle="max-width: 80%; max-height:80px;">
            <p [innerHTML]="ref.text"></p>
        </qloc-card-overview>
    </div>
</div>

<qloc-interesse class="mb-5"></qloc-interesse>
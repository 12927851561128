<div class="container">
    <qloc-slide-in>
        <div class="footernav">
            <ul class="nav">
                <li><a href="/impressum">Impressum</a></li>
                <li><a href="/presse">Presse</a></li>
                <li><a href="/karriere">Jobs</a></li>
                <li><a href="/agb">AGB</a></li>
                <li><a href="widerruf">Wiederrufsbelehrung</a></li>
                <li><a href="/datenschutz">Datenschutzerklärung</a></li>
            </ul>
        </div>
    </qloc-slide-in>
    <qloc-slide-in>
        <div class="social">
            <a href="https://www.facebook.com/qloc.de" target="_blank">
                <fa-icon [icon]="['fab', 'facebook-f']"></fa-icon>
            </a>
            <a href="https://www.twitter.com/qlocDE" target="_blank">
                <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
            </a>
            <a href="https://www.youtube.com/qualitylocation" target="_blank">
                <fa-icon [icon]="['fab', 'youtube']"></fa-icon>
            </a>
        </div>
    </qloc-slide-in>
    <qloc-slide-in>
        <div class="copyright">
            <p><a href="http://www.qloc.de" target="_blank">© {{ year }} Quality Location GmbH</a></p>
        </div>
    </qloc-slide-in>
</div>
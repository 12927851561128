<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Was ist die Acronis Cyber Protect Cloud?" image="assets/products/acronis/provider_solutions_header_image_de_2.webp">
        <p>Acronis integriert professionelle Backup & Recovery-Funktionen mit einer KI-basierten Anti-malware Protection der nächsten Generation und unternehmensgerechten Endpoint Protection Management-Fähigkeiten in einer einzigen Lösung. Diese Integration
            und Automatisierung ermöglichen umfassende Cyber Protection-Lösungen bei gleichzeitiger Steigerung der Produktivität und Senkung der Gesamtbetriebskosten. Mit nur einem Agenten, einer webbasierten Management-Konsole und einer Lizenz, können
            Sie die Komplexität und Risiken, die sonst mit nicht-integrierten Lösungen verbunden sind, beseitigen und in allen fünf geschäftskritischen Phasen der Cyber Protection – nämlich Prävention, Erkennung, Reaktion, Wiederherstellung und Forensik
            – von den entsprechenden Vorteilen profitieren.</p>
    </qloc-card-media>
</qloc-slide-in>


<div class="mt-5">
    <qloc-slide-in>
        <h2 class="mb-4">Warum Acronis Cyber Protect Cloud?</h2>
        <p>Da die Angriffe von Cyberkriminellen immer raffinierter werden, wird es für Unternehmen immer schwieriger, sie zu entschärfen. Ein einziges ungepatchtes System, eine bösartige URL oder eine bösartige Datei genügt, damit Cyberkriminelle Zugang
            zu Ihrem Netzwerk erhalten, Dateien verschlüsseln und Ransomware einsetzen können.<br />Backups sind zwar nach wie vor unerlässlich für die Wiederherstellung Ihrer Daten, reichen aber nicht aus, um Ihr Unternehmen allein zu schützen. Sie müssen
            einen umfassenden Ansatz zum Schutz vor Cyberangriff4en verfolgen, um die mit den heutigen modernen Bedrohungen verbundenen Risiken zu mindern.
        </p>
    </qloc-slide-in>
    <div class="row" style="margin: 0 15px;">
        <div class="col-lg-7 col-md-6 col-sm-8 col-xs-12">
            <qloc-slide-in delay="0.3">
                <qloc-tick>
                    Hosted in Deutschland - <a target="_blank" data-umami-event="page-acronis-datacenter-link" href="http://www.acronis.com/de-de/cloud/data-centers/">Weitere Informationen</a>
                </qloc-tick>
                <qloc-tick>ISO 9001, ISO/IEC 27001 sowie ISO 50001 und Tier-IV Zertifizierung</qloc-tick>
                <qloc-tick>Sicherheit nach deutschem Datenschutz</qloc-tick>
                <qloc-tick>Client- & Serverseitige Verschlüsselung nach 256 Bit AES Standards</qloc-tick>
                <qloc-tick>Physikalische oder virtuelle Systeme</qloc-tick>
                <qloc-tick>Sicherung von Hypervisor VMWare, Hyper-V, Xen, KVM und mehr</qloc-tick>
                <qloc-tick>Komprimierung für geringstmöglichen Speicherbedarf</qloc-tick>
            </qloc-slide-in>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-8 col-xs-12">
            <qloc-slide-in>
                <qloc-tick>Malware- und Virenschutz</qloc-tick>
                <qloc-tick>Steigerung der Produktivität</qloc-tick>
                <qloc-tick>Geringe Gesamtbetriebskosten</qloc-tick>
                <qloc-tick>Eine Lizenz</qloc-tick>
                <qloc-tick>Eine Anbieter-Support-Adresse</qloc-tick>
                <qloc-tick>Ein Agent</qloc-tick>
                <qloc-tick>Eine Management Konsole</qloc-tick>
            </qloc-slide-in>
        </div>
    </div>
</div>

<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Wie wird Acronis Cyber Protect Cloud Lizenziert?" image="assets/products/acronis/Acronis-Cyber-Protect-Banner.webp">
        <p>Acronis Cyber Protect Cloud wird entweder <strong>per Workload Model</strong> <a target="_blank" data-umami-event="page-acronis-licence-link" href="https://cloud.qloc.de/s/Mo5Fz2P9fCDNFjB">lizenziert</a> oder <strong>per GB</strong>. Dieses bietet einen grundlegenden Cyber Protection-Funktionsumfang
            mit Sicherheit, erweiterten Backup-Funktionen, Disaster Recovery, Verwaltung, File Sync &amp; Share sowie Beglaubigungsfunktionen.
        </p>
        <p>Einige Leistungsmerkmale sind künftig Teil verschiedener Advanced-Pakete, die sich in jeder beliebigen Kombination zum Produkt hinzufügen lassen wodurch wir Ihnen so mehr Möglichkeiten bieten können, Acronis Cyber Protection zu verwenden, indem
            wir genau das passende Paket mit dem entsprechenden Schutz zusammenstellen, das Sie benötigen.
        </p>
    </qloc-card-media>
</qloc-slide-in>

<qloc-gradient title="Features und Optionen" class="mt-5">
    <div class="row mb-4">
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100" delay="0.6">
                <qloc-card-overview class="h-100 text-center" icon="desktop" title="CyberFit-Score">
                    <p>Verbessern Sie Ihre Sicherheitslage, indem Sie die Sicherheitslücken in Ihrer IT-Umgebung ermitteln und schließen. Der Acronis #CyberFit-Score ermittelt und bewertet eine Reihe von Sicherheitsmetriken zur Identifizierung von Sicherheitslücken
                        – und gibt dann Empfehlungen, wie Sie die Gesamtsicherheit verbessern können.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100" delay="0.3">
                <qloc-card-overview class="h-100 text-center" icon="history" title="Schwachstellen-bewertungen">
                    <p>Identifizieren und schließen Sie Sicherheitslücken in Ihren Systemen. Sie können Ihre Maschinen nach Schwachstellen (wie Sicherheitslücken) scannen lassen und sicherstellen, dass alle installierten Applikationen und verwendeten Betriebssysteme
                        aktuell sind und nicht von Cyberkriminellen ausgenutzt werden können.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100">
                <qloc-card-overview class="h-100 text-center" icon="cloud-download-alt" title="Blitzschnelle Wiederherstellung">
                    <p>Mit Acronis Universal Restore können Sie Ihre Systeme schnell ohne Kompatibilitätsprobleme auf abweichender Hardware wiederherstellen. Es ermittelt bei der Wiederherstellung den Maschinentyp und sorgt für eine automatische Installation
                        der notwendigen Hardware-Treiber.
                    </p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100" delay="0.6">
                <qloc-card-overview class="h-100 text-center" icon="chart-bar" title="Monitoring und Berichterstattung">
                    <p>Mit den vereinfachten, übersichtlichen Berichten können Sie Ihre Abläufe optimieren und Ihren IT-Mitarbeitern die Informationen bereitstellen, die diese benötigen. Passen Sie die Widgets Ihres Dashboards an, um eine breite Palette
                        von Berichten anzeigen zu lassen.</p>
                    <p>So können Sie Probleme schneller erkennen und beheben.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100" delay="0.3">
                <qloc-card-overview class="h-100 text-center" icon="shield-alt" title="Acronis Active Protection">
                    <p>Schützen Sie sich vor modernen Bedrohungen. Unsere preisgekrönte Antiransomware-Technologie, die auch automatische Datenwiederherstellungsfähigkeiten nach Ransomware-Angriffen bietet, wurde jetzt um eine KI-basierte Analyse von statischen
                        Software-Codes und Verhaltensmustern erweitert, bei der insbesondere Zero-Day-Bedrohungen im Fokus stehen.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100">
                <qloc-card-overview class="h-100 text-center" icon="id-card" title="Beglaubigung (Notarization)">
                    <p>Sie haben die Möglichkeit, die Authentizität und Integrität ihrer Daten nachzuweisen, hierbei wird eine oder mehrere Dateien digital beglaubigt und signiert.</p>
                    <p>Bei einer solchen Beglaubigung wird ein Zertifikat generiert, welches als unveränderlicher, öffentlich überprüfbarer Nachweis für die Zeitstempel sowie die Integrität und Authentizität der Datei dient.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
    </div>
</qloc-gradient>


<qloc-interesse></qloc-interesse>
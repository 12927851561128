<qloc-card class="{{class}}" [footer]="footer">
    <div class="qloc-card-ov-icon" *ngIf="icon">
        <fa-icon [icon]="icon"></fa-icon>
    </div>
    <div class="qloc-card-ov-image" *ngIf="image">
        <img [src]="image" class="img-responsive" [style]="mediastyle" />
    </div>
    <h3 class="mt-4 mb-4 qloc-card-ov-h" *ngIf="title">{{ title }}</h3>
    <ng-content></ng-content>
    <p>
        <a href="{{ url }}" class="no-link color-qloc" role="button" *ngIf="url != ''">
            <fa-icon icon="arrow-circle-right"></fa-icon>
            {{ link }}
        </a>
    </p>
</qloc-card>
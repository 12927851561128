<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">


    <qloc-card class="mt-5 mb-5" title="Mieten statt Kaufen – Hardware as a Service">
        <qloc-col-media mediastyle="margin-top: 20px; float: right; max-width: min(350px, 65%); margin-right: 25px;" image="assets/products/fortinet/FortiGate.webp">
            <h5 class="mb-3">Kein Leasing, keine Finanzierung!</h5>
            <p>
                Mieten Sie eine FortiGate, einen FortiSwitch oder eine FortiAccessPoint aus unserem Hardware as a Service Programm für Ihre Infrastruktur inkl. 24x7 FortiCare, Unified Threat Protection und Hersteller Premium Support 24x7xND und genießen Sie den Value Add ohne Vorabinvestment!
            </p>
            <p>
                Selbstverständlich übernehmen wir auch die Kommunikation mit Fortinet, sollte es erforderlich sein und halten Sie auf dem Laufenden. Falls die Hardware ausgetauscht werden muss, steht mit dem Support der Ersatz schon am nächsten Tag vor Ihrer Tür und wir holen später das defekte Gerät kostenlos bei Ihnen ab. Oft sind es die kleinen Dinge, die das Leben einfacher machen.
            </p>
        </qloc-col-media>
        
    </qloc-card>
</qloc-slide-in>

<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">

    <qloc-card class="mt-5 mb-5" title="Cyber Threat Assessment Programm (CTAP)">

        <qloc-col-media [downloads]="[['Fortinet CTAP Flyer','assets/products/fortinet/Fortinet_CTAP_Flyer.pdf'], ['Fortinet Ransomware Checklist','assets/products/fortinet/Fortinet_Ransomware_Checklist.pdf']]">
            <h5 class="mb-3">Lassen Sie Ihren aktuellen Security Stand unkompliziert und ohne Risiko mit einem Cyber Threat Assessment Programm (CTAP) bewerten</h5>
        <p>
            Hackerangriffe und Cyberattacken nehmen zu und werden immer ausgeklügelter. Sind Sie besorgt, dass Ihre derzeitige Sicherheitsinfrastruktur die heutigen ausgefeilten Angriffe nicht richtig erkennt? Dann ist ein Cyber Threat Assessment Programm genau das Richtige für Sie! 
        </p>
        <p>
            Überprüfen Sie die Sicherheitseffektivität Ihres Netzwerks, Firewall oder E-Mail-Security, indem Sie sich von einem Experten dabei beraten lassen. Wir als Fortinet-Partner verwenden eine FortiGate Firewall, um Schlüsselindikatoren unter die Lupe zu nehmen. Nach mehreren Tagen der Informationserfassung erhalten Sie einen kompakten Cyber Threat Assessment Report, der in drei Hauptabschnitte unterteilt ist:
        </p>
        </qloc-col-media>

        

        <div class="row mt-4 mb-4">
            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Security und Bedrohungsprävention" icon="shield">
                        <p>
                            Wie effektiv ist Ihre aktuelle Netzwerk & Sicherheitslösung und Ihr E-Mail-Filter? Sie erhalten Hinweise auf Schwachstellen bei Anwendungen über die Ihr Netzwerk angegriffen werden kann, einschließlich Malware/Botnetze. Auch auf anfällige, unsichere Geräte im Netzwerk wird hingewiesen.
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>

            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Online-Verhalten im Unternehmen" icon="globe">
                        <p>
                            Welche Websites und Webanwendungen werden genutzt? Social Media, Cloud Storage, Filesharing, Video Streaming etc.? (Zusammengefasste anonymisierte Statistiken, nicht nach einzelnen Nutzern unterteilt!)
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>

            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Netzwerk-Auslastung und Leistung" icon="chart-line">
                        <p>
                            Wie gestaltet sich die Bandbreitennutzung? Wie kann man diese optimieren? Gemessen wird die Durchsatz-, Sitzungs- und Bandbreitennutzung und gibt Ausschluss darüber, wann das Netzwerk am meisten ausgelastet ist.
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
        </div>

        <h5 class="mb-3">Wie läuft das CTAP ab?</h5>

        <p>
            1. Kontaktieren Sie uns<br>
            2. Wir wählen gemeinsam das passende FortiGate Modell und die Einsatzart<br>
            3. Wir integrieren das FortiGate-Testgerät in Ihr Netzwerk<br>
            4. Wir liefern Ihnen den CTAP Report als PDF und besprechen die Ergebnisse gemeinsam<br>

        </p>

        <h5 class="mb-3">Die Vorteile eines CTAP</h5>
        <p>
            Erhalten Sie einen Überblick über:
        </p>

        <div class="row mt-4 mb-4">
            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Sicherheitsrisiken" icon="bug">
                        <p>
                            Welche Schwachstellen zum Angriff auf Ihr Netzwerk genutzt werden können
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>

            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Produktivität" icon="comment-dots">
                        <p>
                            Welche Peer-to-Peer, Social Media-, Instant Messaging und andere Anwendungen laufen Sichtbarkeit und Kontrolle der Anwendungen
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>

            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Auslastung und Leistung" icon="network-wired">
                        <p>
                            Ihre Anforderungen an Durchsatz, Sitzungen und Bandbreitennutzung zu Spitzenzeiten - für das Netzwerk, das E-Mail-System und kritische Anwendungen
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
        </div>

        <p>
            Ein Bericht zur Bewertung der Cyber-Bedrohung gibt Ihnen einen unübertroffenen Einblick in Ihre aktuelle Sicherheitslage und Ihre Netzwerkaktivitäten. Erfahren Sie mehr über Ihren Security-Stand, indem Sie sich noch heute für eine Bewertung anmelden!
        </p>

    </qloc-card>
</qloc-slide-in>




<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">
    <qloc-card class="mt-5 mb-5" title="Abschied von Routern in Filialen - 4 Hauptvorteile eines sicheren SD-WANs">
        <qloc-col-media iframe="https://www.youtube-nocookie.com/embed/ZxBBgbD6pmQ"  [downloads]="[['Fortinet SD-WAN Whitepaper','assets/products/fortinet/Fortinet_SDWAN.pdf'], ['Fortinet Secure-Office-Networking Whitepaper','assets/products/fortinet/Fortinet_SecureOfficeNetworking.pdf']]">
            <p>
                Immer mehr Unternehmen ersetzen Filial-Router durch ein Secure SD-WAN, um Cloud-Lösungen zu unterstützen und die Benutzererfahrung zu verbessern. Solche sicheren SD-WAN-Lösungen sind nicht nur leicht zu verwalten, sondern vereinfachen auch den gesamten WAN-Betrieb. Unternehmen sollten auf sichere SD-WAN-Lösungen umstellen, damit Benutzer in Filialen stärker von Unified Communications, einer besseren Zusammenarbeit, wichtigen SaaS-Anwendungen und einem einfachen Zugriff auf Cloud-Ressourcen profitieren.
            </p>
            
            <p>
                Gegenüber älteren Routing-Architekturen bietet ein Secure SD-WAN vier wesentliche Vorteile:
            </p>
    
            <p>
                1. Mehr Agilität für Anwendungen<br>
                2. Einfache Skalierung mit erheblichen Einsparungen bei Investitions- und Betriebskosten<br>
                3. Einfacheres Management, einfachere Orchestrierung<br>
                4. Integrierte Security- und Netzwerk-Dienste<br>
            </p>
        </qloc-col-media>
    </qloc-card>
</qloc-slide-in>

<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">
    <qloc-card class="mt-5 mb-5" title="Überlegungen für die nächste Phase">
        <p>
            Es ist wichtig, nicht nur die Notwendigkeit einer Umstellung von einer herkömmlichen routerbasierten WAN-Strategie zu erkennen, sondern auch sorgfältig eine sichere SD-WAN-Lösung auszuwählen, die das gesamte Funktionsspektrum und die größtmögliche Anzahl von Anwendungsfällen bietet. So stellen Sie sicher, dass Ihre neue SD-WAN-Implementierung nicht nur den heutigen Anforderungen Ihres Unternehmens entspricht, sondern sich auch an schnell wechselnde Erfordernisse anpassen lässt.
        </p>
    </qloc-card>
</qloc-slide-in>

<qloc-interesse></qloc-interesse>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QlocComponentsModule } from '../components/components.module';

import { QlocTheme_HeaderComponent } from './header/header.component';
import { QlocTheme_SubHeaderComponent , QlocTheme_SubheaderOverviewComponent, QlocTheme_SubheaderProductComponent } from './subheader/subheader.component';
import { QlocTheme_FooterComponent } from './footer/footer.component';
import { QlocTheme_MenuComponent, QlocTheme_SubmenuItemComponent } from './menu/menu.component';
import { QlocTheme_PartnerComponent } from './partner/partner.component';
import { QlocTheme_TestimonialsComponent, QlocTheme_TestimonialComponent } from './testimonials/testimonials.component';
import { QlocTheme_ContentComponent } from './content/content.component';
import { QlocTheme_ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { QlocTheme_InstatusComponent } from './instatus/instatus.component';

@NgModule({
  declarations: [
    QlocTheme_InstatusComponent,
    QlocTheme_HeaderComponent,
    QlocTheme_SubHeaderComponent,
    QlocTheme_SubheaderOverviewComponent,
    QlocTheme_SubheaderProductComponent,
    QlocTheme_FooterComponent,
    QlocTheme_MenuComponent,
    QlocTheme_PartnerComponent,
    QlocTheme_TestimonialsComponent,
    QlocTheme_TestimonialComponent,
    QlocTheme_ContentComponent,
    QlocTheme_SubmenuItemComponent,
    QlocTheme_ScrollToTopComponent
  ],
  exports: [
    QlocTheme_InstatusComponent,
    QlocTheme_HeaderComponent,
    QlocTheme_SubHeaderComponent,
    QlocTheme_SubheaderOverviewComponent,
    QlocTheme_SubheaderProductComponent,
    QlocTheme_FooterComponent,
    QlocTheme_MenuComponent,
    QlocTheme_PartnerComponent,
    QlocTheme_TestimonialsComponent,
    QlocTheme_TestimonialComponent,
    QlocTheme_ContentComponent,
    QlocTheme_SubmenuItemComponent,
    QlocTheme_ScrollToTopComponent
  ], 
  imports: [
    CommonModule,
    NgbModule,
    FontAwesomeModule,
    QlocComponentsModule
  ],
  providers: [],
  bootstrap: []
})
export class QlocThemeModule { 
}

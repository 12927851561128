import { Component, HostBinding, Input } from '@angular/core'; 
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'qloc-icon',
  templateUrl: './icon.component.html',

  styleUrls: ['./icon.component.scss']
})
export class QlocComponent_IconComponent {

  @Input() fa: IconProp = 'check';
  @Input() color: string = '#7ec14c';
  @Input() image: string = '';
  @Input() class: string = '';
  
  constructor() { 
  }  

}


@Component({
  selector: 'qloc-tick',
  template: '<qloc-icon class="{{class}}"  fa="check" color="#7ec14c"><ng-content></ng-content></qloc-icon>'
})
export class QlocComponent_TickComponent  {

  @Input() class: string = '';
  constructor() { 
  }  


}

@Component({
  selector: 'qloc-cross',
  template: '<qloc-icon class="{{class}}" fa="xmark" color="#c1323e"><ng-content></ng-content></qloc-icon>'
})
export class QlocComponent_CrossComponent  {

  @Input() class: string = '';
  constructor() { 
  }  


}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-theme-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class QlocTheme_ContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

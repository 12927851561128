import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QlocPageModule } from './pages/pages.module';
import { QlocThemeModule } from './theme/theme.module';
import { QlocComponentsModule } from './components/components.module';
import { QlocSubheaderModule } from './subheader/subheader.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgwWowModule } from 'ngx-wow';
import { NgHcaptchaModule } from 'ng-hcaptcha';
// import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
// import { CookiebotConfig } from '@config/cookiebot.config';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { HttpClientModule } from '@angular/common/http';
import { NgxSliderModule } from 'ngx-slider-v2';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    QlocThemeModule,
    QlocPageModule,
    QlocSubheaderModule,
    QlocComponentsModule,
    FontAwesomeModule,
    NgbModule,
    //NgwWowModule,
    NgHcaptchaModule.forRoot({
        siteKey: '0baf4037-7b67-40df-bf5b-c290de284fe0',
        languageCode: 'de'
    }),
    // NgxCookiebotModule.forRoot(CookiebotConfig),
    NgxSliderModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIconPacks(fas, far, fab);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class InstatusService extends BaseService {

  getStatus(): Observable<any> {
    const uOb = this.query<any>(
      this.http,
      `https://status.qloc.de/summary.json`,
    );
    return uOb;
  }
}

<div class="row mt-2">
    <div class="col-lg-8 col-12 mt-3">
        <qloc-card class="h-100" title="Stellenangebote">
            <p>Als globales Systemhaus betreuen wir durch unsere Expertise über 4.000 Geschäfts- und Privatkunden. Dabei bieten wir Dienstleistungen an, die von Hardwarebeschaffung bis IT-Projektbetreuung reichen. </p>
            <p>Zudem haben wir durch unsere Partnerschaften mit nahezu allen namhaften <a href="/partner"> Herstellern</a> haben wir einen direkten Draht zu den Technologien.</p>
            <p>Bei Quality Location schaffen wir eine wertschätzende, familiäre Umgebung, in der individuelle Entfaltung, Spaß an der Arbeit und offener Austausch Priorität haben. Jede Meinung und Idee sind bei uns willkommen.</p>
            <p><b>Das bieten wir Dir:</b></p>

            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/arbeitsumfeld.svg">Familiäres Arbeitsumfeld</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/homeoffice.svg"> Möglichkeit der selbstständigen und ortsunabhängigen Arbeit im Home-Office</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/aufgaben.svg"> Abwechslungsreiche Aufgabengebiete</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/hierarchie.svg"> Flache Hierarchien und kurze Informationswege</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/events.svg"> Teamevents</qloc-icon>
            <p>Bei Fragen oder Interesse wende Dich gerne per <a data-umami-event="page-karriere-callus-link" href="callto:030220129830">Telefon</a> an uns oder schreibe uns eine <a href="mailto:bewerbung@qloc.de" data-umami-event="page-karriere-mailto-link">E-Mail</a>.</p>
        </qloc-card>
    </div>
    <div class="col-lg-4 col-12 mt-3">
        <qloc-card class="h-100" title="Personalabteilung">
            <h4>Bewerbungen und Fragen bitte richten an:</h4>
            <p class="mt-3"><strong>Telefon: </strong><a data-umami-event="page-karriere-callus-link" href="callto:030220129830">030 2201298-30</a></p>
            <p><strong>E-Mail: </strong><a href="mailto:bewerbung@qloc.de" data-umami-event="page-karriere-mailto-link">bewerbung@qloc.de</a></p>
            <h4 class="mt-5">
                <fa-icon icon="home"></fa-icon>Anschrift:</h4>
            <p class="mt-3">
                Quality Location GmbH<br> Schäferstr. 19, 14109 Berlin<br> Deutschland
            </p>
            <div class="mt-5 text-center">
                <a class="btn btn-qloc" data-umami-event="page-kontakt-kundencenter-link" href="/kontakt">
                    Kontaktieren Sie uns!</a>
            </div>
        </qloc-card>
    </div>
</div>

<h2 class="mt-5">Aktuelle Stellen</h2>

<div class="mt-4">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <h3>
                    Freelancer-Entwickler:in (PHP, MySQL, HTML, CSS, JS, TS) auf Auftragsbasis
                </h3>
            </mat-expansion-panel-header>
            <p>Wir suchen zum nächstmöglichen Zeitpunkt eine:n <strong>Softwareentwickler:in</strong> hauptsächlich für Webapplikationen. Zu Deinen Aufgabengebieten gehört das Entwickeln von Web-Anwendungen von Grund auf sowie bestehende Web-Anwendungen
                anzupassen.
            </p>
            <p><b>Welche Qualifikationen Du mitbringen solltest:</b></p>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/internet.svg"> Erfahrungen mit Webhosting, Domains und DNS</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/php.svg"> Erfahrung mit objektorientierter Programmierung in PHP</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/typescript.svg"> Erfahrung mit objektorientierter Programmierung in Typescript sowie mit REST-APIs</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/jquery.svg"> Keine Probleme mit jQuery und Bootstrap.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/git.svg"> Versionieren Deiner Software mit Git</qloc-icon>
            <qloc-icon class="mb-4" image="assets/unternehmen/karriere/html.svg"> Benutzerfreundliche und barrierefreie Webapplikationen mit HTML, CSS, PHP, JavaScript und Typescript entwickeln</qloc-icon>

            <p><b>Idealerweise...</b></p>
            <p>... hast du bereits Projekterfahrung</p>
            <p>... hast du bereits Erfahrung in Angular-Umgebugen</p>
            <p> ...kannst Du Rechnungen ausstellen</p>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div class="mt-4">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <h3>
                    Full-Stack Entwickler:in (Typescript, GoLang, HTML, CSS)
                </h3>
            </mat-expansion-panel-header>
            <p>Wir suchen zum nächstmöglichen Zeitpunkt eine:n Full-Stack Entwickler:in für die Verstärkung unseres Dev Teams. Zu Deinen Aufgabengebieten gehört das Entwickeln von Microservices und APIs sowie das erweitern bestehender Anwendungen. Gemeinsam
                mit deinen Ideen entwickeln wir die Lösungen von Morgen.</p>
            <p><b>Welche Qualifikationen Du mitbringen solltest:</b></p>


            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/git.svg"> Versionieren Deiner Software mit Git</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/angular.svg"> Du hast Erfahrungen mit Single-Page-Applications wie z. B. Angular, React oder Vue.js</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/typescript.svg"> Erfahrung mit objektorientierter Programmierung in Typescript sowie mit REST-APIs</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/html.svg"> Du kennst dich aus mit HTML, CSS und Javascript</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/cloud.svg"> IaaS, PaaS oder SaaS sind keine Fremdworte für Dich</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/container.svg"> Du hast verstanden wie Container funktionieren</qloc-icon>
            <qloc-icon class="mb-4" image="assets/unternehmen/karriere/spiderweb.svg"> Du denkst vernetzt, schätzt agile Methoden und bist ein Teamplayer</qloc-icon>
            <p><b>Idealerweise...</b></p>
            <p> ...hast Du bereits ausgeprägte Projekterfahrung</p>
        </mat-expansion-panel>
    </mat-accordion>
</div>



<div class="mt-4">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <h3>
                    IT-Servicetechniker:in
                </h3>
            </mat-expansion-panel-header>
            <p>Wir sind auf der Suche nach einer/m IT-Servicetechniker:in, mit einem fundamentalen Verständnis von Computernetzwerken, Hardware und Software.</p>
            <p><b>Welche Qualifikationen Du mitbringen solltest:</b></p>

            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/computer.svg"> Kenntnisse in verschiedenen Betriebssystemen wie Windows, Linux, und MacOS.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/network.svg"> Verständnis von Netzwerkprotokollen, Konfiguration von Routern, Switches und Firewalls.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/software.svg"> Installieren, aktualisieren und unterstützen von Softwareanwendungen.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/diagnose.svg"> Fähigkeit zur Diagnose und Behebung von IT-Problemen.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/security.svg"> Grundlegendes Verständnis von Sicherheitskonzepten und -praktiken.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/documentation.svg"> Gute Dokumentationsfähigkeiten zur Nachverfolgung von Problemen und Lösungen.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/car.svg"> Einen Führerschein.</qloc-icon>
            <qloc-icon class="mb-4" image="assets/unternehmen/karriere/spiderweb.svg"> Du denkst vernetzt, schätzt agile Methoden und bist ein Teamplayer</qloc-icon>

            <p><b>Idealerweise...</b></p>

            <p>...hast Du Zertifikate wie CompTIA A+, Network+, oder Microsoft Certified IT Professional (MCITP) </p>

        </mat-expansion-panel>
    </mat-accordion>
</div>



<div class="mt-4">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <h3>
                    Werkstudent:in
                </h3>
            </mat-expansion-panel-header>
            <p>Wir suchen einen engagierten Werkstudenten, der die Möglichkeit nutzen möchte, wertvolle praktische Erfahrungen in einem dynamischen und wachsenden Unternehmen zu sammeln und gleichzeitig sein akademisches Wissen anzuwenden.</p>
            <p><b>Welche Qualifikationen Du mitbringen solltest:</b></p>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/student.svg"> Immatrikulation an einer Hochschule oder Universität.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/calender.svg"> Flexibilität und die Fähigkeit, Arbeit und Studium zu koordinieren.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/software.svg"> Installieren, aktualisieren und unterstützen von Softwareanwendungen.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/diagnose.svg"> Fähigkeit zur Diagnose und Behebung von IT-Problemen.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/internet.svg"> Programmieren von Anwendungen, beispielsweise Web-Applikationen.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/work.svg"> Eigenverantwortung und die Fähigkeit, eigenständig zu arbeiten.</qloc-icon>
            <qloc-icon class="mb-4" image="assets/unternehmen/karriere/spiderweb.svg"> Du denkst vernetzt, schätzt agile Methoden und bist ein Teamplayer</qloc-icon>
            <p><b>Idealerweise...</b></p>
            <ul class="mb-3">
                <p>...hast Du bereits Projekterfahrungen</p>
                <p>...studierst du ein Fachrichtung oder dem Schwerpunkt IT. </p>
            </ul>

        </mat-expansion-panel>
    </mat-accordion>
</div>



<div class="mt-4">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <h3>
                    Quereinsteiger:in
                </h3>
            </mat-expansion-panel-header>
            <p>Wir bieten eine spannende Gelegenheit für motivierte Quereinsteiger, die bereit sind, ihre Karriere in einer neuen Richtung zu lenken und ihre einzigartigen Fähigkeiten in unserem Unternehmen einzubringen.</p>
            <p><b>Welche Qualifikationen Du mitbringen solltest:</b></p>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/transfer.svg"> Transferierbare Fähigkeiten und Erfahrungen aus vorherigen Berufsfeldern, die auf die ausgeschriebenen Stellen anzuwenden sind.</qloc-icon>
            <qloc-icon class="mb-2" image="assets/unternehmen/karriere/software.svg"> Fundamentales Verständnis von Computernetzwerken, Hardware und Software.</qloc-icon>
            <qloc-icon class="mb-4" image="assets/unternehmen/karriere/spiderweb.svg"> Du denkst vernetzt, schätzt agile Methoden und bist ein Teamplayer</qloc-icon>

            <p><b>Idealerweise...</b></p>
            <ul class="mb-3">
                <p>...hast Du bereits Vorerfahrungen im IT-Sektor, Kundensupport oder EDV-Bereich.</p>
                <p>...hast Du bereits relevante Zertifikate oder Weiterbildungen absolviert.</p>
            </ul>

        </mat-expansion-panel>
    </mat-accordion>
</div>
<qloc-interesse class="mt-5"></qloc-interesse>
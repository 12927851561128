<div class="mb-5"></div>
<qloc-slide-in>
    <qloc-card-media title="Quality Location Systemhaus" image="assets/logo_darkblue_without_text.svg" mediastyle="max-width: min(350px, 50%);" alt="Qloc logo in dark blue without text">
        <p>
            <strong>
                "Quality is Our Life" – getreu diesem Motto bieten wir leistungsstarke Enterprise Hardware von namhaften Herstellern sowie maßgeschneiderte IT-Dienstleistungen für Ihr Unternehmen.
            </strong>
        </p>

        <p>
            Wir von Quality Location legen gro&szlig;en Wert auf hohe Performance-Qualit&auml;t, Kundenfreundlichkeit und pers&ouml;nlichen Support. Sie möchten Ihre IT-Infrastruktur modernisieren? Als offizieller Partner von Dell, VMware, Sophos, Acronis, Microsoft,
            3CX, Yealink sowie Zugriff auf die breite Produktpalette unsere Distributoren, kombinieren wir für Sie weitreichende Kompetenzen für Ihr individuelles Rundum-sorglos-Paket.
        </p>
    </qloc-card-media>
</qloc-slide-in>

<h2 class="text-center mb-4 mt-5">Unsere Kernkompetenz</h2>
<div class="row row-eq-height">
    <div class="col-lg-6 mb-4">
        <qloc-slide-in class="h-100" delay="0.3">
            <qloc-card-overview class="h-100 text-center" icon="briefcase" title="IT-Consulting" url="it-service">
                <p>Beratung und Vertrieb vom Monitor, über Workstations, Server, Storages, Netzwerkkomponenten bis hin zur hyperkonvergenten Infrastruktur. Wir liefern was Sie wünschen!</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-6 mb-4">
        <qloc-slide-in class="h-100">
            <qloc-card-overview class="h-100 text-center" icon="shield-alt" title="IT-Security" url="it-security">
                <p>Schutz Ihrer Daten bei Verlust oder Diebstahl, Erkennung und Bereinigung von Bedrohungen sowie Angriffen, Menschliches Versagen ausschließen.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
</div>

<div class="row row-eq-height">
    <div class="col-lg-6 mb-4">
        <qloc-slide-in class="h-100" delay="0.3">
            <qloc-card-overview class="h-100 text-center" icon="cog" title="IT-Entwicklung" url="it-service">
                <p>Von der Betreuung von Bestandssystemen über die Entwicklung neuer IT-Komponenten steht bei uns die Automatisierung der Geschäftsprozesse im Fokus.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-6 mb-4">
        <qloc-slide-in class="h-100">
            <qloc-card-overview class="h-100 text-center" icon="cogs" title="Managed Service" url="it-service">
                <p>Ausführung, Verwaltung und Überwachung von Ihren IT-Projekten und Systemen bei uns im sicheren Rechenzentrum durch unsere speziell ausgebildetenen Techniker.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
</div>

<qloc-slide-in>
    <h2 class="text-center mt-5 mb-4">IT-Dienstleistungen neu definiert</h2>
    <qloc-card-overview title="Sie haben eine Vision? - Wir realisieren sie!" link="Jetzt kontaktieren" url="kontakt">
        <p>Mit der Quality Location GmbH als Ihr IT-Partner profitieren Sie von unserem großen Händlernetzwerk und jahrerlanger Erfahrung im Bereich der IT-Dienstleistungen. Unser Team an Beratern und Entwicklern freut sich auf Ihre neue Herausforderung
            um Ihre Unternehmung auf ein neues Level der Produktivität zu steigern.</p>

        <p>Sie haben besondere Anforderungen an Ihre IT und brauchen spezielle Hardware-Komponenten?<br /> Kein Problem! Zusammen mit unseren Hardware-Partnern und Distributoren können wir jede erdenkliche Art von Hardware für Sie erwerben.</p>

        <p>Sie möchten Ihre Geschäftsprozesse automatisieren und brauchen eine eigene Software-Lösung?<br /> Kein Problem! Unser Entwickler-Team ist auf die Eigenentwicklung von Systemen spezialisiert.</p>

        <p>Kontaktieren Sie uns! Unser Vertrieb freut sich auf Ihr persönliches Gespräch.</p>
    </qloc-card-overview>
</qloc-slide-in>


<h2 class="text-center mb-4 mt-5">Ihre Vorteile</h2>
<div class="row row-eq-height mb-5">
    <div class="col-lg-6 mb-4">
        <qloc-slide-in class="h-100" delay="0.3">
            <qloc-card-overview class="h-100" icon="briefcase" title="Zertifizierte Rechenzentren & Enterprise Hardware" url="technik">
                <p>Unsere Server stehen in TÜV geprüften und zertifizierten Rechenzentren in Frankfurt am Main und profitieren damit von einer direkten Anbindung an den DE-CIX - den größten Internet-Knotenpunkt der Welt. Regelmäßige Wartung, Instandsetzung
                    sowie eine verschlüsselte Datenübertragung sorgen für einen stabilen und sicheren Betrieb Ihrer Projekte und Dienste. Unsere Server sind ausschließlich mit Enterprise Hardware von Dell ausgestattet und erfüllen damit höchsten Ansprüche
                    an Zuverlässigkeit und Stabilität.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-6 mb-4">
        <qloc-slide-in class="h-100">
            <qloc-card-overview class="h-100" icon="shield-alt" title="Direkter und schneller Kundenservice" url="kontakt">
                <p>Bei uns steht die schnelle Kommunikation zwischen dem Kunden und unserem Kundenservice besonders im Mittelpunkt. Profitieren Sie von einem direkten Draht zu unseren Servicemitarbeitern die Ihnen gezielt bei der Entstörung im Problemfall,
                    oder bei der Klärung von offenen Fragen und Anliegen helfen. Sie erreichen uns jederzeit per Mail und Ticket, sowie per Telefon während unser Geschäftszeiten.</p>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
</div>
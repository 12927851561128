import { Component } from '@angular/core'; 

@Component({
  selector: 'qloc-interesse',
  templateUrl: './interesse.component.html',
  styleUrls: ['./interesse.component.scss']
})
export class QlocComponent_InteresseComponent {

  constructor() { 
  }  


}

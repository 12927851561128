<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Unsere Leistungen" image="assets/products/lenovo/Lenovo-Infrastructure-Partner.webp">
        <p>Unser Leistungsspektrum reicht von der Einrichtung und Inbetriebnahme über die Betreuung und Wartung von Computersystemen, Netzwerken und Rechenzentren bis hin zur Ablösung und Migration von IT-Systemen. Bei uns erhalten Sie IT-Komponenten aller
            Kategorien aus einer Hand mit hohen Qualitätsstandards aus dem Hause Fujitsu, die Sie für einen reibungslosen Arbeitsalltag in Ihrem Unternehmen benötigen. Wir garantieren eine schnelle und zuverlässige Lieferung. Gerne liefern wir auch direkt
            an verschiedene Standorte Ihres Unternehmens. Darüber hinaus unterstützen wir Sie gerne bei der Einrichtung und Installation Ihrer Hardware- und Softwarekomponenten.</p>
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Ultra HD-Panel trifft auf modernes Design" image="assets/products/lenovo/Monitor-L32p.webp" [left]="true">
        <p>Das Lenovo L32p-30 Display ist wie geschaffen für immersive Gestaltung und Unterhaltung. Das 80 cm (31,5") Ultra HD-Display (3840 x 2160), mit In-Plane-Switching überzeugt Gamer und Kreative durch seinen klaren Bildschirmbild mit vielen Details.
            Benutzer können über eine Vielzahl von Anschlüssen mehrere Geräte verbinden. Dazu gehört auch ein USB Typ C-Anschluss mit einer Stromversorgung von bis zu 75 W. Die exklusive Lenovo Artery Software ermöglicht die Anpassung der Benutzererfahrung
            durch erweiterte Anzeigeeinstellungen, wie z. B. einer Option für die Bildschirmteilung für effizientes Multitasking. Durch die integrierte AMD FreeSync-Technologie sorgt diese zusammen mit der breiten Farbabdeckung und hohen Auflösung für
            eine angenehme Benutzererfahrung. Hierdurch sind Sie in Ihrem Unternehmen für alle notwendigkeiten gerüstet.</p>
    </qloc-card-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Netzwerkcomputer der Enterprise-Klasse" image="assets/products/lenovo/Lenovo-ThinClient.webp">
        <p>Diese ThinkCentre Thin Clients der M Serie sind die perfekte Wahl für zukunftsorientierte IT-Manager auf der Suche nach reaktionsschnellen, sicheren und platzsparenden Desktops, die in jeder Büroumgebung verwendet werden können. Sie eignen sich
            optimal für Branchen mit hohen Datenschutz- und Sicherheitsanforderungen, beispielsweise das Gesundheitswesen, da die gesamten Daten nicht auf den einzelnen Geräten, sondern im Netzwerk gespeichert werden. Der M625q Thin Client wurde für Langlebigkeit
            in jeder Umgebung entwickelt. Als echter Think erfüllt er Militärstandards und hat entsprechend 200 strenge Qualitätstests durchlaufen. Ob arktische Temperaturen von -20 Grad oder 60 Grad Wüstenhitze, kleine Stöße oder Staub – dieses Modell
            kommt mit allem zurecht.</p>
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Workstations der P Serie - Überlegene Zuverlässigkeit & Leistung" image="assets/products/lenovo/Lenovo-ThinkStation-P350.webp" [left]="true">
        <p>Die Workstations der ThinkStation P Serie bieten beeindruckende Leistung, egal bei welchen Aufgaben: Von der P620 mit ihrem bahnbrechenden AMD Ryzen™ Threadripper PRO über die neueste Generation von Intel® Xeon® Prozessoren bis hin zu denn professionellen
            NVIDIA® Quadro® RTX Grafikkarten in Single- und Dual-Prozessorsystemen. Unsere Lenovo ThinkStations wurden von unabhängigen Softwareanbietern (ISVs) zertifiziert, sind energieeffizient und äußerst vielseitig. Sie bieten Ihnen die hohe Zuverlässigkeit,
            die Sie von Think Produkten erwarten.</p>
        <p>Diese leistungsstarken Workstations haben in Tests bewiesen, dass sie auch für die anspruchsvollsten Anwendungen die erforderliche Leistung erbringen. Die ISV-Zertifizierung bestätigt, dass Sie sich beim Kauf einer Lenovo ThinkStation der P Serie
            darauf verlassen können, dass sie die Anforderungen Ihres Unternehmens und Ihrer Branche mehr als erfüllt.</p>
    </qloc-card-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Noch mehr Produktivität mit dem ThinkBook 15 Gen 2" image="assets/products/lenovo/Thinkbook-15.webp">
        <p>Dank der erweiterten Intel® Core™ Prozessoren der 11. Generation, die durch KI-Technologie optimiert wurden, bietet Ihnen das Lenovo ThinkBook 15 Gen 2 (Intel) Notebook höchste Leistung. Aufgrund des Massenspeichers bis zu 2 TB Dual-SSD und einem
            Arbeitsspeicher bis zu 24 GB sind Sie mit diesem Business-Notebook für intensivstes Arbeiten gerüstet. Dank der intelligenten Kühlung müssen Sie sich auch wegen einer überhitzung keine Sorgen machen.</p>
        <p>Das Lenovo ThinkBook 15 Gen 2 (Intel) schützt Ihre Geschäftsdaten und Ihre Privatsphäre. Dafür sorgen Hardware- und Firmware-Funktionen wie TPM (Trusted Platform Module), Zugriffskontrolle für E/A-Ports, sicheres Löschen und mehr. Mit der Webcam-Abdeckung
            lässt sich die Kamera verdecken, wenn Sie sie nicht verwenden. Und smarte Funktionen runden das Erlebnis ab.</p>
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Zuverlässige IT-Infrastruktur die Sie für ein erfolgreiches Geschäft benötigen" [image]="['assets/products/lenovo/Rackserver-Lenovo.webp', 'assets/products/lenovo/Lenovo-Premium-Care.webp']" [left]="true">
        <p>Lenovo ThinkSystem Server wurden in der Global Server Hardware Server OS Reliability Survey 2021 von ITIC das achte Jahr in Folge als die Server mit der höchsten Zuverlässigkeit und Verfügbarkeit unter allen Intel x86-basierten Plattformen eingestuft.</p>
        <p>Lenovo Rack-Server eignen sich für die Workloads expandierender kleiner Unternehmen ebenso gut wie für die von Großunternehmen. Sie überzeugen durch ein beispielloses Preis-Leistungs-Verhältnis, Flexibilität und branchenführende Effizienz und
            werden allen Anforderungen mit legendärer Qualität und Zuverlässigkeit gerecht.</p>
        <p>Maximieren Sie die Betriebszeit für unternehmenswichtige Anwendungen und Workloads. Die Advanced Support-Ebene umfasst alle Vorteile der Essential-Services, plus Vor-Ort-Service mit zugesichertem Reparaturservice innerhalb von 6 Stunden , 24 Stunden
            am Tag und 7 Tage in der Woche:</p>
        <qloc-tick>Erweiterung der Basisgarantie, die die Installation von Ersatzteilen vor Ort umfasst</qloc-tick>
        <qloc-tick>YourDrive YourData (Einbehalten der Festplatte)</qloc-tick>
        <qloc-tick>Um von mehr Effizienz zu profitieren, können Sie die Advanced-Services auf bis zu 5 Jahre mit Zusatzleistungen erweitern.</qloc-tick>
    </qloc-card-media>
</qloc-slide-in>

<qloc-gradient class="mt-5" title="Service und Lieferung">
    <div class="row">
        <div class="row row-eq-height mt-4 mb-4">
            <div class="col-lg-4 col-12 mt-4">
                <qloc-slide-in duration="0.6" class="h-100">
                    <qloc-card-overview class="h-100" title="Lenovo Vor Ort Service">
                        <p>Sollte es einmal zu technischen Problemen an der Hardware kommen, bietet Lenovo mit dem Vor Ort Service einen Dienst, der zum nächsten Arbeitstag alle erfolderlichen Arbeiten ausführen kann. Auch eine priorisierte Ersatzteilbereitstellung
                            ist im Premier Support enthalten.</p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-lg-4 col-12 mt-4">
                <qloc-slide-in duration="0.3" class="h-100">
                    <qloc-card-overview class="h-100" title="Erweiterte technische Unterstützung">
                        <p>Der Lenovo Premier Support bietet eine erweiterte technische Unterstützung mit dedizierter Telefonnummer, rund um die Uhr, das ganze Jahr.</p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-lg-4 col-12 mt-4">
                <qloc-slide-in class="h-100">
                    <qloc-card-overview class="h-100" title="Individuelle Konfiguration & Lieferung">
                        <p>Wir beraten Sie persönlich und konfigurieren für Sie jedes System inidividuell entsprechend Ihrer Anforderungen. Gerne liefern wir Ihnen auch große Stückzahlen an mehrere Betriebs-Standorte und übernehmen für Sie den Aufbau sowie
                            die Konfiguration.
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
        </div>
    </div>
</qloc-gradient>

<qloc-interesse></qloc-interesse>
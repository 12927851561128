<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Was kann gefördert werden?" image="assets/products/bmwi/bmwi_coop.webp" [size]="2">
        <p>
            Durch die zunehmende Digitalisierung ist es wichtig mit der Zeit zu gehen. Alte Server oder veraltete Technik stellen Probleme dar und machen die Firma und Ihre Projekte angreifbar. Die Umstellung für kleine und mittlere Unternehmen (KMU) auf neue Infrastruktur
            ist aktuell eine der größten Herausforderungen für viele Unternehmen. Gerade die Digitalisierung des gesamten Geschäftsalltags stellt viele Firmen vor große Herausforderungen.
        </p>
        <p>
            Mit uns als Ihren starken Partner gehören sorgen der Vergangenheit an. Durch umfassende Beratung und Planung der neue IT-Infrastruktur können auch Sie im Jahre 2021 mit uns als starken Partner an Ihrer Seite in die Digitalisierung starten.
        </p>
        <p>
            Warten oder zögern Sie nicht mit der Umstellung Ihrer IT-Infrastruktur. Starten Sie noch heute und wir Unterstützen Sie gerne bei Ihrem vorhaben.
        </p>
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card class="mt-5">
        <div class="row bmwi-table">
            <div class="col-md-6 col-12" style="color: #4C4C4C;">Warum digitalisieren?</div>
            <div class="col-md-6 col-12 text-right" style="color: #4C4C4C;">Handlungsfelder</div>
            <div class="col-md-6 col-12" style="background-color: #266F9A;">Profitabel wachsen mit neuen Produkten, Dienstleistungen und Geschäftsmodellen</div>
            <div class="col-md-6 col-12 text-right" style="background-color: #266F9A;">Strategie und Geschäftsmodell</div>
            <div class="col-md-6 col-12" style="background-color: #6f9fc8;">Neue Kunden gewinnen und bestehende Kunden binden</div>
            <div class="col-md-6 col-12 text-right" style="background-color: #6f9fc8;">Kunden</div>
            <div class="col-md-6 col-12" style="background-color: #adb663;">Kosten sparen und effizienter werden</div>
            <div class="col-md-6 col-12 text-right" style="background-color: #adb663;">Geschäftsprozesse</div>
            <div class="col-md-6 col-12" style="background-color: #ecc744;">Durch Industrie 4.0 die Produktion flexibler, schneller und individueller gestalten</div>
            <div class="col-md-6 col-12 text-right" style="background-color: #ecc744;">Produktion</div>
            <div class="col-md-6 col-12" style="background-color: #b32e3a;">Mitarbeiter gewinnen, qualifizieren, halten und das Wissen der Mitarbeiter besser managen</div>
            <div class="col-md-6 col-12 text-right" style="background-color: #b32e3a;">Mitarbeiter</div>
        </div>
    </qloc-card>
</qloc-slide-in>

<qloc-slide-in>
    <div class="mt-5">
        <h2 class="mb-4">Das papierarme Büro</h2>
        <p>
            Täglich füllen sich in Unternehmen Schreibtische, Ordner, Regale, ja ganze Räume, mit Papier. Mitarbeiter verwenden viel Zeit, um Dokumente wie Angebote, Verträge, Lieferpapiere oder Personalunterlagen auszudrucken, abzulegen, zu archivieren und zu suchen.<br><br>Mit
            einem Dokumentenmanagementsystem (DMS) machen Sie Schluss mit aufwendigen und papiergebundenen Abläufen, denn damit verwalten Sie Dokumente Ihres Unternehmens datenbankgestützt und elektronisch. Papierdokumente können gescannt und digital
            in das System integriert werden. Die Investitionen rechnen sich oft bereits nach 18 Monaten. Weshalb also nicht auf unnötige Ausdrucke, Postsendungen und Aktenordner verzichten und damit Papier, Toner und Platz für physische Archive sparen?
        </p>
    </div>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card class="mt-5">
        <h2>
            Beispiel: Reifenhändler (kleines Unternehmen)
        </h2>
        <p>
            Ein Reifenhändler aus Stuttgart will eine größere Kundenreichweite und effizientere Prozesse durch Investitionen in digitale Technologien erreichen. Auf Basis eines Digitalisierungsplans reicht er einen Antrag ein, der folgende Angaben enthält:
        </p>
        <ul>
            <li>Unternehmensgröße: bis zu 50 Mitarbeiter</li>
            <li>Investition in einen neuen Webauftritt mit Online-Vertrieb sowie neue technologische Infrastruktur (IP-Telefonie, elektronische Warenannahme)</li>
            <li><strong>Investitionsvolumen: ca. 60.000 Euro</strong></li>
        </ul>
        <p>
            Das Vorhaben wird mit einer Förderquote von 50 (ab 01.07.2021: 40) % und somit insgesamt 30.000 (24.000) Euro durch das BMWi gefördert.
        </p>
    </qloc-card>
</qloc-slide-in>

<qloc-slide-in>
    <div class="mt-5">
        <p>
            Einzelne Förderprogramme laufen bis zum 31. Dezember 2023.
        </p>
        <p>
            Förderfähig sind nur Vorhaben, die noch nicht begonnen sind. Das Vorhaben gilt als begonnen, wenn bereits eine rechtsverbindliche Bestellung getätigt oder ein Auftrag zur Erbringung iner Dienstleistung erteilt wurde (ggfs. auch nur mündlich).
        </p>
    </div>
</qloc-slide-in>

<qloc-interesse></qloc-interesse>
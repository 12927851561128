import { Component, OnInit, Input } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';  

@Component({
  selector: 'qloc-theme-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class QlocTheme_TestimonialsComponent implements OnInit {

    testimonials = require('@config/testimonials.json');
  constructor(config: NgbCarouselConfig) {  
    config.interval = 10000;  
    config.wrap = true;  
    config.keyboard = false;  
    config.pauseOnHover = false;  
  }  

  ngOnInit(): void {
  }

}

@Component({
  selector: 'qloc-theme-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class QlocTheme_TestimonialComponent {
  @Input() text="";
  @Input() avatar="";
  @Input() name="";
  @Input() info="";
  constructor() { }


}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BaseService {

    constructor(
        public http: HttpClient,
    ) {
    }

    apiUrl(endpoint: string, version: string = 'v1'): string {
        const host: string = environment.apiEndpoint;
        const url = `${host}/${endpoint}`;
        return url;
    }


    query<T>(
        http: HttpClient,
        baseUrl: string,
        urlOrFilter?: string | object,
        httpOptions?: any,
    ): Observable<T> {
        return http.get<T>(baseUrl);
    }

    queryPost<T>(
        http: HttpClient,
        baseUrl: string,
        body?: any,
    ): Observable<T> {
        return http.post<T>(baseUrl, body);
    }
}

<h2 class="mt-5">Logos und Pressematerialien</h2>
<p class="mt-3">Die Logos dürfen bis zur nächsten Aktualisierung honorarfrei für redaktionelle Zwecke und nur im Rahmen der Konzernberichterstattung verwendet werden. Spätere Veröffentlichungen sind nur nach Rücksprache und ausdrücklicher Genehmigung der Quality Location
    GmbH möglich. Verwendung nur mit vollständigem Copyrightvermerk. Das Logo darf nicht verändert, bearbeitet und nur im Ganzen verwendet werden. Es darf nicht archiviert werden. Es darf nicht an Dritte weitergeleitet werden.</p>
<div class="row mt-2">
    <div class="col-lg-6 col-12">
        <qloc-card class="mt-5">
            <div class="text-center"><img src="https://files.qloc.de/presse/logo.png" alt="" width="100%"></div>
            <p class="mt-3">Das Firmenlogo des Quality Location Systemhauses. (512x512px)</p>
            <p><strong>Binden Sie die Grafik via BBCode in Ihrem Forum o.ä. ein.</strong></p>
            <textarea rows="3">[url=https://www.qloc.de][img]https://files.qloc.de/presse/logo.png[/img][/url]</textarea>
        </qloc-card>
    </div>
    <div class="col-lg-6 col-12">
        <qloc-card class="mt-5">
            <div class="text-center"><img src="https://files.qloc.de/presse/profile.png" alt="" width="70%"></div>
            <p class="mt-3">Das Profilbild des Quality Location Systemhauses. (500x500px)</p>
            <p><strong>Binden Sie die Grafik via BBCode in Ihrem Forum o.ä. ein.</strong></p>
            <textarea rows="3">[url=https://www.qloc.de][img]https://files.qloc.de/presse/profile.png[/img][/url]</textarea>
        </qloc-card>
    </div>
    <div class="col-lg-6 col-12">
        <qloc-card class="mt-5 mb-5">
            <div class="text-center"><img src="https://files.qloc.de/presse/banner1.png" alt="" width="100%"></div>
            <p class="mt-3">Simples Werbebanner. (920x150px)</p>
            <p><strong>Binden Sie die Grafik via BBCode in Ihrem Forum o.ä. ein.</strong></p>
            <textarea rows="3">[url=https://www.qloc.de][img]https://files.qloc.de/presse/banner1.png[/img][/url]</textarea>
        </qloc-card>
    </div>
</div>
<qloc-theme-subheader-product title="Digitalisieren Sie Ihr Unternehmen jetzt!" image="assets/products/bmwi/bmwi_banner.webp" mediastyle="max-height: 300px; padding-top:0;">
    <p>
        Individuelle, fachliche Beratung und Umsetzung in den drei Fördermodulen IT-Sicherheit, digitale Geschäfts-prozesse und digitalisierte Markterschließung</p>
    <p>
        <qloc-tick>Vereinfachung vieler Prozesse im Unternehmen</qloc-tick>
        <qloc-tick>Verbesserung von Kundenservice und Produkten</qloc-tick>
        <qloc-tick>Nutzung von Daten für das Unternehmen</qloc-tick>
        <qloc-tick>Effektives und kostengünstiges Marketing</qloc-tick>
        <qloc-tick>Mehr unternehmerische Freiheiten</qloc-tick>
    </p>
</qloc-theme-subheader-product>
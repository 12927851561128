import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-referenzen',
  templateUrl: './referenzen.component.html',
  styleUrls: ['./referenzen.component.scss']
})
export class QlocPage_ReferenzenComponent implements OnInit {

  constructor() { }

  referenzen = require('@config/referenzen.json');

  ngOnInit(): void {
  }

}

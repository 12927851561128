import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class QlocPage_PartnerComponent implements OnInit {

  constructor() { }

  partner = require('@config/partner.json');

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { InstatusService } from '../../services/instatus.service';


@Component({
  selector: 'qloc-theme-instatus',
  templateUrl: './instatus.component.html',
  styleUrls: ['./instatus.component.scss']
})
export class QlocTheme_InstatusComponent implements OnInit {

  message = {
    active: false,
    color: '',
    text: ''
  }

  constructor(private instatusService: InstatusService) { }

  ngOnInit(): void {
    this.instatusService.getStatus().subscribe(data => {
      if (data.page.status === 'HASISSUES') {
        this.message.text = 'Aktuell liegt eine Störung vor - Weitere Informationen finden Sie unter status.qloc.de'
        this.message.color = 'danger';
        this.message.active = true;
      }

      if (data.page.status === 'UNDERMAINTENANCE') {
        this.message.text = 'Aktuell laufen Wartungsarbeiten an unseren IT-Systemen - Weitere Informationen finden Sie unter status.qloc.de'
        this.message.color = 'warning';
        this.message.active = true;
      }

    }, (error) => {
      console.log('Failed to load statuspage summary!')
    });
  }
}

<qloc-card class="mt-5 mb-5" title="Allgemeine Geschäftsbedingungen und Kundeninformationen">
    <h2 class="mt-5">
        <fa-icon icon="angle-right"></fa-icon>I. Allgemeine Geschäftsbedingungen
    </h2>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>§ 1 Grundlegende Bestimmungen</h3>
    <p class="mt-3"> (1) Die nachstehenden Geschäftsbedingungen gelten für alle Verträge, die Sie mit uns als Anbieter (Quality Location GmbH) über die Internetseite qloc.de schließen. Soweit nicht anders vereinbart, wird der Einbeziehung gegebenenfalls von Ihnen verwendeter
        eigener Bedingungen widersprochen.</p>
    <p> (2) Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann. Unternehmer
        ist jede natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer selbständigen beruflichen oder gewerblichen Tätigkeit handelt.</p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>§ 2 Zustandekommen des Vertrages</h3>
    <p class="mt-3">
        (1) Wir stellen Ihnen Internet-Dienstleistungen, insbesondere Webhosting, Serverhosting und Domains, zur Verfügung. Der Leistungsumfang ergibt sich aus dem von Ihnen gebuchten Leistungspaket und der hierzu auf unserer Internetseite angegebenen Leistungsbeschreibung.</p>
    <p>
        (2) Bereits mit dem Einstellen des jeweiligen Leistungsangebots auf unserer Internetseite unterbreiten wir Ihnen ein verbindliches Angebot zum Abschluss eines Vertrages zu den in der Leistungsbeschreibung angegebenen Bedingungen.</p>
    <p> (3) Der Vertrag kommt über das Online-Warenkorbsystem wie folgt zustande:<br> Die zur Buchung beabsichtigten Dienstleistungen werden im "Warenkorb" abgelegt. Über die entsprechende Schaltfläche in der Navigationsleiste können Sie den "Warenkorb" aufrufen
        und dort jederzeit Änderungen vornehmen.<br> Nach Aufrufen der Seite "Kasse" erfolgt die Eingabe der persönlichen Daten sowie der Zahlungsbedingungen; abschließend werden hier alle Bestelldaten als Bestellübersicht angezeigt.
    </p>
    <p> Soweit Sie als Zahlungsart ein Sofortzahl-System (z.B. PayPal / PayPal Express / PayPal Plus, Amazon-Payments, Postpay, Sofort. (Klarna.), Bitcoin, Giropay, Kreditkarte, SEPA-Lastschrift) nutzen, werden Sie entweder in unserem Online-Shop auf die
        Bestellübersichtsseite geführt oder Sie werden zunächst auf die Internetseite des Anbieters des Sofortzahl-Systems weitergeleitet.<br> Erfolgt die Weiterleitung zu dem jeweiligen Sofortzahl-System, nehmen Sie dort die entsprechende Auswahl bzw.
        Eingabe Ihrer Daten vor. Abschließend werden Sie zurück in unseren Online-Shop auf die Bestellübersichtsseite geleitet.</p>
    <p> Vor Absenden der Bestellung haben Sie die Möglichkeit, auf der Bestellübersichtsseite sämtliche Angaben nochmals zu überprüfen, zu ändern (auch über die Funktion "zurück" des Internetbrowsers) bzw. die Bestellung abzubrechen.
        <br> Mit dem Absenden der Bestellung über die Schaltfläche "Zahlungspflichtig bestellen" erklären Sie rechtsverbindlich die Annahme des Angebotes, wodurch der Vertrag zustande kommt. </p>
    <p> (4) Ihre Anfragen zur Erstellung eines Angebotes sind für Sie unverbindlich. Wir unterbreiten Ihnen hierzu ein verbindliches Angebot in Textform (z.B. per E-Mail), welches Sie innerhalb von 5 Tagen annehmen können.</p>
    <p> (5) Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen erfolgt per E-Mail zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die von Ihnen bei uns hinterlegte E-Mail-Adresse
        zutreffend ist, der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird. </p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>§ 3 Leistungserbringung bei Webhosting und Serverhosting, Pflichten</h3>
    <p class="mt-3"> (1) Unsere Leistungspflichten ergeben sich aus der Leistungsbeschreibung des jeweiligen Webhosting- oder Serverhosting-Angebots. </p>
    <p> (2) Soweit wir Ihnen im Rahmen des Serverhostings volle und alleinige Administrationsrechte auf den bereitgestellten Servern einräumen, sind Sie ausschließlich und allein für die Verwaltung und Sicherheit Ihres Servers verantwortlich.
        <br> Sie sind verpflichtet, notwendige Sicherheitssoftware zu installieren, sich konstant über bekanntwerdende Sicherheitslücken zu informieren und diese selbständig zu schließen. Die Installation von Wartungsprogrammen oder sonstiger Software,
        die wir zur Verfügung stellen oder empfehlen, entbindet Sie nicht von dieser Pflicht.</p>
    <p> (3) Soweit wir Programme bereitstellen, erhalten Sie für den Zeitraum der Vertragsdauer ein nicht ausschließliches Recht zur Nutzung der bereitgestellten Programme. Sie sind verpflichtet, die jeweiligen Lizenzbestimmungen einzuhalten.
    </p>
    <p> (4) Sie sind ferner verpflichtet, Ihren Server so einzurichten und zu verwalten, dass die Sicherheit, Integrität und Verfügbarkeit der Netze, anderer Server, Software und Daten Dritter nicht gefährdet wird. Insbesondere ist es Ihnen untersagt, den
        Server für den Versand von SPAM-Mails und (d)DOS-Attacken zu verwenden oder offene Mail-Relays und andere Systeme auf dem Server zu betreiben, über die SPAM-Mails und (d)DOS-Attacken verbreitet werden können. Bei Verstößen behalten wir uns das
        Recht vor, den Server ohne vorherige Ankündigung vom Netz zu trennen und den Vertrag fristlos zu kündigen.</p>
    <p> (5) Sie haben keinen Anspruch darauf, dass dem Server dieselbe IP-Adresse für die gesamte Vertragslaufzeit zugewiesen wird. Wir behalten uns vor, diese bei technischer oder rechtlicher Notwendigkeit zu ändern und Ihnen in diesem Zusammenhang eine
        neue IP-Adresse zuzuteilen. </p>
    <p> (6) Wir behalten uns vor, die zur Erbringung der Leistungen eingesetzte Hard- und Software an den jeweiligen Stand der Technik anzupassen und Ihnen die sich hieraus möglicherweise ergebenden zusätzlichen Anforderungen an die von Ihnen auf unseren
        Servern abgelegten Inhalte rechtzeitig mitzuteilen. Wir verpflichten uns, solche Anpassungen nur in einem für Sie zumutbaren Rahmen und unter Berücksichtigung Ihrer Interessen vorzunehmen.</p>
    <p> (7) Wir erbringen unsere Leistungen mit einer Verfügbarkeit von 99 % im Jahresmittel, soweit im jeweiligen Leistungsangebot keine andere Verfügbarkeit ausgewiesen ist. Ausfallzeiten aufgrund von regelmäßiger oder sporadischer Wartung sind hierin eingeschlossen.
        <br> Hiervon ausgenommen sind Zeiten, in denen der Server aufgrund von technischen oder sonstigen Problemen, die nicht in unserem Einflussbereich liegen (höhere Gewalt, Verschulden Dritter etc.) nicht zu erreichen ist.
    </p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>§ 4 Domainverwaltung</h3>
    <p class="mt-3">
        (1) Bei der Verschaffung und/oder Pflege von Domains werden wir zwischen Ihnen und den Vergabestellen lediglich als Vermittler tätig. Wir schulden dabei die Erstellung und Übermittlung eines nach den Vorgaben der jeweiligen Registrierungsstelle (z.B.
        der Denic eG) vollständig ausgefüllten Antrages auf Anmeldung der von Ihnen gewünschten Domains.<br> Eine Gewähr für die Übertragung, die Zuteilung oder den dauernden Bestand von Domains zu Ihren Gunsten kann nicht übernommen werden, es
        gelten in diesem Zusammenhang die Registrierungsbedingungen der Vergabestellen.<br> Sie garantieren, dass die von Ihnen beantragte oder für Sie bereits registrierte Domain keine Rechte Dritter verletzt.
        <br>Sie sind weiter verpflichtet, uns einen etwaigen Verlust Ihrer Domain unverzüglich anzuzeigen.</p>
    <p> (2) Wir sind berechtigt, die Aktivierung einer Domain erst nach Zahlung der vereinbarten Entgelte nach Ziffer 5. unserer Kundeninformationen (Teil II) vorzunehmen. Ebenso können wir nach Kündigung des Vertrages die Freigabe der Domain solange verweigern,
        bis Sie alle Zahlungsverpflichtungen aus dem Vertrag uns gegenüber erfüllt haben. </p>
    <p> (3) Erteilen Sie bei Vertragsbeendigung keine klare Weisung zur Übertragung oder Löschung der Domain, können wir die Domain nach Vertragsende und Ablauf einer angemessenen Frist an die zuständige Vergabestelle zurückgeben oder löschen lassen. Gleiches
        gilt, wenn eine Freigabe der Domain von uns gem. § 4 Abs. 2 der AGB verweigert werden kann.</p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>§ 5 Weitere Pflichten Ihrerseits</h3>
    <p class="mt-3">(1) Sie haben uns über jede Änderung der für die Vertragserfüllung erforderlichen Daten unverzüglich zu informieren. Passwörter und sonstige Zugangsdaten sind streng geheim zu halten.</p>
    <p> (2) Sie sind verpflichtet, Ihre Domain und die darunter abrufbaren Inhalte so zu gestalten, dass eine übermäßige Belastung unserer Server z.B. durch Skripte, die eine hohe Rechenleistung erfordern oder überdurchschnittlich viel Arbeitsspeicher beanspruchen,
        vermieden wird.<br> Wir sind berechtigt, Internetseiten oder Server, die den obigen Anforderungen nicht gerecht werden, vom Zugriff durch Sie oder durch Dritte auszuschließen. Sie werden von einer solchen Maßnahme unverzüglich informiert.
    </p>
    <p> (3) Sie gewährleisten, dass Ihre Domains und die darunter abrufbaren Inhalte nicht gegen gesetzliche Vorschriften oder die guten Sitten verstoßen und keine Rechte Dritter verletzen. Das betrifft insbesondere die rechtlichen Regelungen zur Anbieterkennung,
        Urheber-, Marken-, Persönlichkeits- und sonstige Schutzrechte, Fernabsatzrecht, Wettbewerbsrecht, Strafrecht und Datenschutzrecht.<br> Wir sind nicht verpflichtet, Ihre Domains und die darunter abrufbaren Inhalte auf eventuelle Rechtsverstöße
        zu prüfen. Nach dem Erkennen von Rechtsverstößen oder von unzulässigen Inhalten sind wir berechtigt, die Inhalte zu sperren und die betreffende Domain unerreichbar zu machen. Sie werden von solchen Maßnahmen unverzüglich informiert.
        <br> Sie stellen uns von allen Ansprüchen, die aus einem von Ihnen zu vertretenen Verstoß gegen die obigen Pflichten entstehen, frei. Das betrifft auch die Kosten unserer notwendigen Rechtsverteidigung einschließlich sämtlicher Gerichts- und Anwaltskosten.
    </p>
    <p> (4) Sie haben Sicherungskopien von allen Daten, die Sie auf unsere Server überspielen, auf gesonderten Datenträgern selbst zu erstellen. Wir sind für die Erstellung von Datensicherungskopien nicht verantwortlich. Im Falle eines Datenverlustes werden
        Sie die betreffenden Datenbestände nochmals unentgeltlich auf unsere Server übertragen. </p>
    <p> (5) Sie werden darauf achten, dass das vereinbarte Datentransfervolumen (Traffic) nicht überschritten wird. Soweit nichts Anderes vereinbart ist, ist ein Traffic von 1 TB zulässig. Der Traffic ist „Fair Use“ zu behandeln.
    </p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>§ 6 Vertragslaufzeit, Kündigung</h3>
    <p class="mt-3"> (1) Der zwischen Ihnen und uns geschlossene Vertrag über Webhosting, Serverhosting oder Domains (mit Ausnahme von Prepaid-Verträgen) hat die vereinbarte Laufzeit. Wird der Vertrag nicht 30 Tage vor Vertragsende (soweit im jeweiligen Angebot keine
        andere Frist geregelt ist) von einer der Parteien in Textform (z.B. per E-Mail) gekündigt, verlängert er sich stillschweigend jeweils um die vereinbarte Grundlaufzeit. Beträgt die Grundlaufzeit mehr als ein Jahr, verlängert sich der Vertrag jedoch
        jeweils nur um ein Jahr.</p>
    <p> (2) Prepaid-Verträge werden mit unbestimmter Vertragslaufzeit geschlossen. Die Zahlung erfolgt durch Sie jeweils im Voraus für die von Ihnen gewählte Prepaid-Periode. Erfolgt keine weitere Vorauszahlung bis zum Ablauf der jeweiligen Prepaid-Periode,
        endet der Vertrag mit Ablauf der Prepaid-Periode; einer zusätzlichen Kündigung durch Sie bedarf es nicht.</p>
    <p> Mit dem Eingang der Vorauszahlung bis zum Ablauf der jeweiligen Prepaid-Periode erfolgt die Verlängerung über die im Prepaid-Vertrag festgehaltene Laufzeit.
        <br> Prepaid-Verträge können durch uns mit einer Frist von 14 Tagen zum Ende der jeweiligen Prepaid-Periode in Textform gekündigt werden. Gegebenenfalls von Ihnen im Voraus bereits erbrachte Leistungen werden in diesem Fall unverzüglich zurückerstattet.
    </p>
    <p> (3) Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt hiervon unberührt.<br> Insbesondere haben wir ein außerordentliches Kündigungsrecht bei widerholten Verstößen gegen Ihre Pflichten nach § 3 Abs. 2 bis 4 und § 5 der AGB. Sie sind bei
        einer außerordentlichen Kündigung durch uns zum Schadenersatz verpflichtet.
    </p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>§ 7 Leistungserbringung bei sonstigen Internet-Dienstleistungen
    </h3>
    <p class="mt-3">
        (1) Soweit sonstige Internet-Dienstleistungen Vertragsgegenstand sind, schulden wir die sich aus der Leistungsbeschreibung ergebenden Einzelleistungen. Diese erbringen wir nach bestem Wissen und Gewissen persönlich oder durch Dritte. </p>
    <p>
        (2) Sie sind zur Mitwirkung verpflichtet, soweit uns zur Erbringung der Dienstleistung weitere Informationen zur Verfügung gestellt werden müssen.</p>
    <p> (3) Die Leistungserbringung erfolgt zu den vereinbarten Terminen.
    </p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>§ 8 Zurückbehaltungsrecht </h3>
    <p class="mt-3">
        Ein Zurückbehaltungsrecht können Sie nur ausüben, soweit es sich um Forderungen aus demselben Vertragsverhältnis handelt.</p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>§ 9 Haftung</h3>
    <p class="mt-3">
        (1) Wir haften jeweils uneingeschränkt für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit. Weiter haften wir ohne Einschränkung in allen Fällen des Vorsatzes und grober Fahrlässigkeit, bei arglistigem Verschweigen eines Mangels,
        bei Übernahme der Garantie für die Beschaffenheit des Kaufgegenstandes und in allen anderen gesetzlich geregelten Fällen. </p>
    <p> (2) Die Haftung für Mängel im Rahmen der gesetzlichen Gewährleistung richtet sich nach der entsprechenden Regelung in unseren Kundeninformationen (Teil II).</p>
    <p> (3) Sofern wesentliche Vertragspflichten betroffen sind, ist unsere Haftung bei leichter Fahrlässigkeit auf den vertragstypischen, vorhersehbaren Schaden beschränkt. Wesentliche Vertragspflichten sind wesentliche Pflichten, die sich aus der Natur
        des Vertrages ergeben und deren Verletzung die Erreichung des Vertragszweckes gefährden würde sowie Pflichten, die der Vertrag uns nach seinem Inhalt zur Erreichung des Vertragszwecks auferlegt, deren Erfüllung die ordnungsgemäße Durchführung
        des Vertrags überhaupt erst möglich machen und auf deren Einhaltung Sie regelmäßig vertrauen dürfen.</p>
    <p> (4) Bei der Verletzung unwesentlicher Vertragspflichten ist die Haftung bei leicht fahrlässigen Pflichtverletzungen ausgeschlossen.</p>
    <p> (5) Die Datenkommunikation über das Internet kann nach dem derzeitigen Stand der Technik nicht fehlerfrei und/oder jederzeit verfügbar gewährleistet werden. Wir haften insoweit weder für die ständige noch ununterbrochene Verfügbarkeit der Webseite
        und der dort angebotenen Dienstleistung.</p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>§ 10 Rechtswahl, Erfüllungsort, Gerichtsstand</h3>
    <p class="mt-3">(1) Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl nur, soweit hierdurch der durch zwingende Bestimmungen des Rechts des Staates des gewöhnlichen Aufenthaltes des Verbrauchers gewährte Schutz nicht entzogen wird (Günstigkeitsprinzip).</p>
    <p>
        (2) Erfüllungsort für alle Leistungen aus den mit uns bestehenden Geschäftsbeziehungen sowie Gerichtsstand ist unser Sitz, soweit Sie nicht Verbraucher, sondern Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen
        sind. Dasselbe gilt, wenn Sie keinen allgemeinen Gerichtsstand in Deutschland oder der EU haben oder der Wohnsitz oder gewöhnliche Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt ist. Die Befugnis, auch das Gericht an einem anderen gesetzlichen
        Gerichtsstand anzurufen, bleibt hiervon unberührt.</p>
    <p> (3) Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung.<br>
    </p>
    <hr>
    <h2 class="mt-5">
        <fa-icon icon="angle-right"></fa-icon>II. Kundeninformationen </h2>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>1. Identität des Anbieters</h3>
    <p class="mt-3">
        Quality Location GmbH<br> Schäferstr. 19<br> 14109 Berlin<br> Deutschland
        <br> Telefon: 030 2201298-30<br> E-Mail: info@qloc.de<br> Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>.</p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>2. Informationen zum Zustandekommen des Vertrages</h3>
    <p class="mt-3">
        Die technischen Schritte zum Vertragsschluss, der Vertragsschluss selbst und die Korrekturmöglichkeiten erfolgen nach Maßgabe des § 2 unserer Allgemeinen Geschäftsbedingungen (Teil I).
    </p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>3. Vertragssprache, Vertragstextspeicherung</h3>
    <p class="mt-3">
        3.1. Vertragssprache ist deutsch.</p>
    <p> 3.2. Der vollständige Vertragstext wird von uns nicht gespeichert. Vor Absenden der Bestellung über das Online-Warenkorbsystem können die Vertragsdaten über die Druckfunktion des Browsers ausgedruckt oder elektronisch gesichert werden. Nach Zugang
        der Bestellung bei uns werden die Bestelldaten, die gesetzlich vorgeschriebenen Informationen bei Fernabsatzverträgen und die Allgemeinen Geschäftsbedingungen nochmals per E-Mail an Sie übersandt.</p>
    <p> 3.3. Bei Angebotsanfragen außerhalb des Online-Warenkorbsystems erhalten Sie alle Vertragsdaten im Rahmen eines verbindlichen Angebotes in Textform übersandt, z.B. per E-Mail, welche Sie ausdrucken oder elektronisch sichern können.</p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>4. Wesentliche Merkmale der Dienstleistung</h3>

    <p>
        Die wesentlichen Merkmale der Dienstleistung finden sich in der Leistungsbeschreibung und den ergänzenden Angaben auf unserer Internetseite oder bei Angebotsanfragen in unserem verbindlichen Angebot.
    </p>
    <h3 id="PreiseundZahlungsmodalitaeten">
        <fa-icon icon="angle-double-right"></fa-icon>5. Preise und Zahlungsmodalitäten</h3>
    <p class="mt-3">
        5.1. Die in den jeweiligen Angeboten angeführten Preise stellen Gesamtpreise dar. Sie beinhalten alle Preisbestandteile einschließlich aller anfallenden Steuern.</p>
    <p> 5.2. Sie haben folgende Zahlungsmöglichkeiten, soweit im jeweiligen Angebot oder im Online-Bestellvorgang nichts anders ausgewiesen ist:</p>
    <p> - Vorkasse per Überweisung<br> - Zahlung per PayPal<br> - Zahlung per PayPal Express<br> - Zahlung per paysafecard<br> - Zahlung per Sofort. (Klarna.)<br> - Zahlung per Bitcoin<br> - Zahlung per Kreditkarte<br> - Zahlung per SEPA-Lastschrift<br> -
        Zahlung per Giropay</p>
    <p> 5.3. Soweit im jeweiligen Angebot oder im Online-Bestellvorgang nicht anders angegeben, sind die Zahlungsansprüche aus dem geschlossenen Vertrag sofort zur Zahlung fällig.</p>
    <p> 5.4. Alle Rechnungen werden mit einer Vorlaufzeit ausgestellt. Bei Produkten mit monatlicher Zahlungsweise erfolgt die Ausstellung der Rechnung des jeweiligen Folgemonats 14 Tage vor der nächsten Fälligkeit. Bei quartalsweiser, halbjährlicher und
        jährlicher Zahlungsweise beträgt diese Vorlaufzeit 30 Tage.</p>
    <p> 5.5. Der Anbieter gewährt seinen Kunden für die Zahlung der für die Dienstleistungen ausgestellten Rechnungen die Einzahlung von Guthaben. Eine Auszahlung dieses Guthabens ist bedingt § 11 Abs. 1a GwG nicht möglich. Bei der Ausstellung neuer Rechnungen
        erfolgt die automatische Verbuchung mit dem Guthaben.</p>
    <p> 5.6. Verkürzung der Ankündigungsfrist: Die Frist für die Vorabinformation der SEPA-Lastschrift wird auf einen Tag verkürzt. Erfolgt eine Rücklastschrift behalten wir es uns vor Ihnen die hierdurch entstehenden Gebühren von 11€ in Rechnung zu stellen.
    </p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>6. Gesetzliches Mängelhaftungsrecht</h3>

    <p>
        Es bestehen die gesetzlichen Mängelhaftungsrechte.
    </p>
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>7. Vertragslaufzeit, Kündigung</h3>

    <p>
        Informationen zur Laufzeit des Vertrages sowie den Bedingungen der Kündigung finden Sie in der Regelung "Vertragslaufzeit, Kündigung" in unseren Allgemeinen Geschäftsbedingungen (Teil I), sowie in der jeweiligen Leistungsbeschreibung.</p>
    <p>
        <br> Diese AGB und Kundeninformationen wurden von den auf IT-Recht spezialisierten Juristen des Händlerbundes erstellt und werden permanent auf Rechtskonformität geprüft. Die Händlerbund Management AG garantiert für die Rechtssicherheit der Texte
        und haftet im Falle von Abmahnungen. Nähere Informationen dazu finden Sie unter: <a href="http://www.haendlerbund.de/agb-service">http://www.haendlerbund.de/agb-service</a>.<br>
    </p>
</qloc-card>
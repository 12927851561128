
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QlocThemeModule } from '../theme/theme.module';
import { QlocComponentsModule } from '../components/components.module';
import { QlocSubheader_HomeComponent } from './home/home.component';
import { QlocSubheader_DellComponent, QlocSubheader_FujitsuComponent, QlocSubheader_LenovoComponent, QlocSubheader_IPTelefoneComponent } from './hardware/hardware.component';
import { QlocSubheader_3CXComponent, QlocSubheader_ITSecurityComponent, QlocSubheader_AcronisComponent, QlocSubheader_BMWIComponent, QlocSubheader_ITServiceComponent, QlocSubheader_MailstoreComponent, QlocSubheader_NextcloudComponent, QlocSubheader_NoSpamProxyComponent, QlocSubheader_O365Component, QlocSubheader_SophosComponent, QlocSubheader_CitrixComponent, QlocSubheader_FortinetComponent, QlocSubheader_CrowdstrikeComponent, QlocSubheader_TenableComponent, QlocSubheader_AwarenessHeroComponent, QlocSubheader_ITSealComponent } from './dienstleistungen/dienstleistungen.component';
import { QlocSubheader_KarriereComponent } from './unternehmen/unternehmen.component';

@NgModule({
  declarations: [
    QlocSubheader_HomeComponent,
    QlocSubheader_DellComponent, QlocSubheader_FujitsuComponent, QlocSubheader_LenovoComponent, QlocSubheader_IPTelefoneComponent,
    QlocSubheader_3CXComponent, QlocSubheader_AcronisComponent, QlocSubheader_BMWIComponent, QlocSubheader_ITServiceComponent, QlocSubheader_ITSecurityComponent, QlocSubheader_ITServiceComponent, QlocSubheader_MailstoreComponent, QlocSubheader_NoSpamProxyComponent, QlocSubheader_O365Component, QlocSubheader_SophosComponent, QlocSubheader_NextcloudComponent,
    QlocSubheader_KarriereComponent, QlocSubheader_CitrixComponent, QlocSubheader_FortinetComponent,
    QlocSubheader_CrowdstrikeComponent, QlocSubheader_TenableComponent, QlocSubheader_ITSealComponent, QlocSubheader_AwarenessHeroComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    NgbModule,
    QlocThemeModule,
    QlocComponentsModule
  ],
  providers: [],
  bootstrap: []
})
export class QlocSubheaderModule {
}
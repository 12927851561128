<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Unified Communication" image="assets/products/3cx/iPhone%20_Android_WinClient.webp">
        <p>3CX kann Ihre Business-Kommunikation auf das nächste Level bringen, die Zusammenarbeit forcieren, die Produktivität erhöhen, Mobilität steigern und die Kundenerfahrung bereichern. Mit modernen Voice-over-IP basierten Softphones, Web Client und
            Smartphone Clients haben Anwender direkten Zugang zu integrierten Kommunikationswerkzeugen wie Präsenzanzeige und Web- & Videokonferenzen, sowie Unified Messaging per Chat, Fax & Voicemail-to-Email und vielem mehr.</p>
    </qloc-card-media>
</qloc-slide-in>


<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Collaboration" image="assets/products/3cx/webmeeting_group.webp" [left]="true">
        <p>Durch einen modernen WebRTC-basierten Webclient und integrierten Videokonferenzen stehen Ihnen eine Vielzahl an Funktionalitäten für eine effektive Echtzeit-Kommunikation und Zusammenarbeit zur Seite. Halten Sie Meetings per Video- oder Audiokonferenz,
            laden Sie Dokumente hoch und teilen Sie diese direkt mit Ihren Gesprächspartnern. Profitieren Sie von digitalen Whiteboards, Desktop Sharing und Umfragen.</p>
    </qloc-card-media>
</qloc-slide-in>


<qloc-fullscreen-bg class="mt-5" bgcolor="#12457f">
    <div class="row mt-5 mb-5">
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100" delay="0.6">
                <qloc-card-overview class="h-100" title="Präsenzstatus" icon="phone-slash">
                    <p>Vermeiden Sie wiederholte Anrufversuche oder erfolgloses Weitervermitteln von Kundenanrufen. Ihre Kollegen sehen, ob Sie derzeit verfügbar, beschäftigt oder z.B. im Außendienst sind (personalisierbar).</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100" delay="0.3">
                <qloc-card-overview class="h-100" title="Fax und Voicemail" icon="voicemail">
                    <p>Eingehende Faxe werden automatisch in das PDF-Format konvertiert und Ihnen per E-Mail zugestellt. Erhalten Sie auch Mailbox-Nachrichten im Audioformat direkt per E-Mail auf Ihre Endgeräte.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100">
                <qloc-card-overview class="h-100" title="Instant Messaging / Chat" icon="comments">
                    <p>Ein unternehmensweiteres Instant Messaging vereinfacht Ihre Kommunikationswege. Senden und Empfangen Sie Textnachichten, Links usw. über Ihre Mac, Windows, iOS und Android Endgeräte.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
    </div>
</qloc-fullscreen-bg>


<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Kostenreduktion" image="assets/products/3cx/ip-telephony-webmeeting.webp">
        <p>3CX ermöglicht es Ihnen nicht nur, Ihre laufenden Telekommunikationskosten zu reduzieren, sondern auch erhebliche Kosten bei der Beschaffung, Erweiterung und Wartung Ihrer Telefonanlage zu sparen.</p>
        <ul>
            <li>Reduzieren Sie Ihre Telefonrechnung um bis zu 80%. </li>
            <li>Profitieren Sie von Lizenzen basieren auf der Anzahl simultaner Anrufe statt Nebenstellen.</li>
            <li>Vermeiden Sie Lizenzgebühren je Nebenstelle - zahlen Sie stattdessen pro Telefonanlage.</li>
            <li>Tätigen Sie kostenfreie interne Anrufe zwischen Ihren Büros und Außenstellen.</li>
            <li>Nutzen Sie gängige SIP-Trunks für die Reduktion von Gebühren für ausgehende Anrufe.</li>
            <li>Reduzieren Sie Reisekosten dank integrierten Webkonferenzen.</li>
            <li>Verzichten Sie auf teure 0800-Nummer-Telefonrechnungen dank WebRTC-Integration.</li>
            <li>Verwenden Sie internationale DIDs &amp; IP-Telefone, um Ihren Kunden eine günstige Einwahl zu ermöglichen.</li>
        </ul>
    </qloc-card-media>
</qloc-slide-in>


<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Einfache Installation und Wartung" image="assets/products/3cx/3CXManagementConsole.webp" [left]="true">
        <p> Die Installation und Konfiguration mit 3CX ist völlig unkompliziert:</p>
        <ul>
            <li>vorkonfigurierte Templates für eine einfach Integration gängiger SIP-Trunk-Anbieter</li>
            <li>Plug-and-Play Interoperabilität von IP-Telefonen und Gateways für eine automatische Konfiguration</li>
            <li>Vermeidung von Interoperabilitätsproblemen mit von 3CX <a href="/ip-telefone" data-umami-event="page-3cx-phones-link">getesteten IP-Telefonen</a>.</li>
        </ul>
        <p> Über die moderne, intuitive Verwaltungskonsole können Sie:</p>
        <ul>
            <li>Nebenstellen und DIDs erstellen, bearbeiten oder entfernen</li>
            <li>die Firmware Ihrer IP-Telefone upgraden</li>
            <li>IP-Telefone ferngesteuert erneut provisionieren und neu starten</li>
            <li>alle Kontakte gleichzeitig importieren oder löschen</li>
            <li>serverseitige CRM-Integrationen umsetzen</li>
        </ul>
    </qloc-card-media>
</qloc-slide-in>


<qloc-slide-in>
    <div class="mt-5">
        <h3 class="mb-4">Unser Service für Sie</h3>
        <p>Wir übernehmen für Sie die Planung sowie Einrichtung Ihrer 3CX-Telefonanlange, Schulen Ihre Mitarbeiter und bieten Ihnen einen Wartungsvertrag. Gerne übernehmen wir für Sie auch die Beschaffung sowie den Aufbau der Hardware vor Ort.</p>
        <p>Als erfahrener IT-Dienstleister beraten wir Sie zum Hosting in der Cloud bei etablierten Cloud-Anbietern wie Google, Microsoft Azure, Amazon Lightsail, usw. <br>Entsprechend deutscher/europäischer Datenschutzstandards bieten wir Ihnen auch das
            Hosting in unseren zertifizierten Rechenzentren sowie On-Premise bei Ihnen vor Ort auf einer bestehenden Windows- oder Linux-Maschine, einem MiniPC oder vitualisiert auf Hyper-V, VMware oder KVM an.</p>
    </div>
</qloc-slide-in>


<qloc-interesse class="mt-5"></qloc-interesse>
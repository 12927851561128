<div class="menu">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-10">
                <a href="/" class="xs-logo">
                    <img src="assets/logo_text_right_white.svg" alt="Quality Location GmbH" draggable="false" class="logo">
                </a>
            </div>
            <div class="col-lg-8  col-2">
                <ul class="nav mobile-nav-active">
                    <div class="mobile-nav-close" (click)="closeMobileNav()">
                        <fa-icon icon="times"></fa-icon>
                    </div>
                    <li class="menu-item" *ngFor="let navitem of navitems;">
                        <a *ngIf="navitem.link;" href="{{ navitem.link }}">{{ navitem.title }}</a>
                        <div *ngIf="!navitem.link;">
                            <a (click)="openSubMenu($event)">{{ navitem.title }}</a>
                            <div class="submenu container">
                                <div class="row" *ngIf="navitem.items">
                                    <div class="col-lg-{{navitem.col}}" *ngFor="let subnavitem of navitem.items;">
                                        <qloc-theme-submenu-item title="{{ subnavitem.title }}" text="{{ subnavitem.text }}" icon="{{ subnavitem.icon }}" link="{{ subnavitem.link }}"></qloc-theme-submenu-item>
                                    </div>
                                </div>
                                <div class="row mb-4" *ngIf="navitem.category">
                                    <div class="col-lg-{{navitem.col}}" *ngFor="let category of navitem.category;">
                                        <div class="submenu-cat">
                                            <h6>{{ category.title }}</h6>
                                            <p>{{ category.text }}</p>
                                        </div>
                                        <div class="submenu-cat-item" *ngFor="let subnavitem of category.items;">
                                            <qloc-theme-submenu-item title="{{ subnavitem.title }}" text="{{ subnavitem.text }}" icon="{{ subnavitem.icon }}" link="{{ subnavitem.link }}"></qloc-theme-submenu-item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="mobile-nav" id="mobile-nav" (click)="openMobileNav()">
                    <fa-icon icon="bars"></fa-icon>
                </div>
                <div class="mobile-nav-blur mobile-nav-active" (click)="closeMobileNav()"></div>

            </div>
        </div>
    </div>
</div>
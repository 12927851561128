import { Component, Input, OnInit } from '@angular/core';
@Component({
	selector: 'qloc-theme-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class QlocTheme_MenuComponent {

	constructor() {}

	mobileNav = false;
	navitems = require('@config/navigation.json');

	openMobileNav() {
		(document.querySelectorAll('.mobile-nav-active') as any as Array<HTMLElement>).forEach(element => {
			element.style.display = "block";
		});
			this.mobileNav = true;
		};
	 closeMobileNav() {
		(document.querySelectorAll('.mobile-nav-active') as any as Array<HTMLElement>).forEach(element => {
			element.style.display = "";
		});
			this.mobileNav = false;
	 };
	 openSubMenu(e:any) {
		 if(this.mobileNav) {
			(document.querySelectorAll('.submenu') as any as Array<HTMLElement>).forEach(element => {
				element.style.display = "";
			});
			(e.target.parentNode.querySelectorAll('.submenu') as any as Array<HTMLElement>).forEach(element => {
				element.style.display="block";
			});
		 }
		}
	 

}

@Component({
	selector: 'qloc-theme-submenu-item',
	templateUrl: './submenu-item.component.html',
	styleUrls: ['./submenu-item.component.scss']
})
export class QlocTheme_SubmenuItemComponent implements OnInit {

	@Input() title = 'Titel'; 
	@Input() text = 'Text'; 
	@Input() icon:any = "server"
	@Input() link = '#'; 

	constructor() { }

	ngOnInit(): void {
	}

}
import { Component } from '@angular/core';

@Component({
  selector: 'qloc-subheader-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class QlocSubheader_HomeComponent {

    subheaderlist = require('@config/subheader_home.json');
  constructor() { }


}

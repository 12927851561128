import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Options } from 'ngx-slider-v2';
import  NextcloudPriceCfg  from '@config/nextcloud_price.config';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'qloc-page-nextcloud',
  templateUrl: './nextcloud.component.html',
  styleUrls: ['./nextcloud.component.scss']
})
export class QlocPage_NextcloudComponent {

  

  constructor() {}
}


@Component({
    selector: 'qloc-page-nextcloud-calculator',
    templateUrl: './nextcloud_calculator.component.html',
    styleUrls: ['./nextcloud_calculator.component.scss']
  })
export class QlocPage_NextcloudCalculatorComponent implements OnInit {

    isBrowser: boolean;
    gb_value: number = 100;
    gb_options: Options = {
      floor: 100,
      ceil: 10000,
      step: 50,
      translate: (value: number): string => {
          if (value > 1000) {
            return value/1000 + " TB";
          } else {
            return value + " GB";
          }
      },
      logScale: true
    };
    user_value: number = 0;
    user_options: Options = {
      showTicks: true,
      showTicksValues: false,
      stepsArray: [],
      translate: (value: number, label): string => {
        return NextcloudPriceCfg.plans[value].name;
      }
    };
    antivir = false;
    office = false;
    talk = false;
    price: number = 0;
    price_brutto: number = 0;
    price_extras:number = 0;
    price_vm: number = 0;
    price_storage: number = 0;
    price_before_dc:number = 0;
    price_dc: number = 0;
    paket = NextcloudPriceCfg.plans[this.user_value].name;

    onInputChange(): void {
        this.price_extras = 0;
        let cores = NextcloudPriceCfg.plans[this.user_value].cores;
        let ram = NextcloudPriceCfg.plans[this.user_value].ram;
        let extra_cores = 0;
        let extra_ram = 0;
        if (this.antivir) {
            extra_cores += NextcloudPriceCfg.antivir_add_cpu;
            extra_ram += NextcloudPriceCfg.antivir_add_ram;
            this.price_extras +=  NextcloudPriceCfg.antivir_add_price;
        }
        if (this.office) {
            extra_cores += NextcloudPriceCfg.office_add_cpu;
            extra_ram += NextcloudPriceCfg.office_add_ram;
            this.price_extras +=  NextcloudPriceCfg.office_add_price;
        }
        if (this.talk) {
            extra_cores += NextcloudPriceCfg.talk_add_cpu;
            extra_ram += NextcloudPriceCfg.talk_add_ram;
            this.price_extras +=  NextcloudPriceCfg.talk_add_price;
        }
        this.price_extras +=  extra_cores * NextcloudPriceCfg.cores_price + extra_ram * NextcloudPriceCfg.ram_price
        this.price_vm = cores*NextcloudPriceCfg.cores_price +  ram*NextcloudPriceCfg.ram_price
        this.price_storage = this.gb_value*NextcloudPriceCfg.storage_price;
        this.price_before_dc = this.price_extras + this.price_vm + this.price_storage;
        this.price_dc = 0 - (this.price_before_dc*NextcloudPriceCfg.discount_relative + NextcloudPriceCfg.discount_absolute + NextcloudPriceCfg.discount_function(this.price_before_dc));
        this.price = this.price_before_dc + this.price_dc;
        this.price_brutto = this.price * NextcloudPriceCfg.ust;
        this.paket = NextcloudPriceCfg.plans[this.user_value].name;
        
      }

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
        let user_steps : any = [];
        NextcloudPriceCfg.plans.forEach((value, index) => {
            user_steps.push({
                value: index
            })
		});
        const newuser_options: Options = Object.assign({}, this.user_options);
        newuser_options.stepsArray = user_steps;
        this.user_options = newuser_options;
      this.onInputChange();
  }


}

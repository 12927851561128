import {Injectable} from '@angular/core'; 
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  constructor(private title: Title, private meta: Meta) { }


  updateTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    //<title>55-64 chars</title>
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }
  //<meta name="description" content="max 160 chars" />

  updateKeywords(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords })
  }
  //<meta name="keywords" content="komma seperiert" />

  updateOgTitle(title: string) {
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    //<meta property="og:title" content="titel" />
    //<meta name="twitter:title" content="titel">
  }
  updateOgDescription(desc: string) {
    this.meta.updateTag({ name: 'og:description', content: desc });
    this.meta.updateTag({ name: 'twitter:description', content: desc });
  }
  //<meta property="og:description" content="description" />
  //<meta name="twitter:description" content="description">
  updateOgImage(image: string) {
    this.meta.updateTag({ name: 'og:image', content: image });
    this.meta.updateTag({ name: 'twitter:image', content: image });
  }
  //<meta property="og:image" content="Ein Bild das zum jeweiigem Seiteninhalt passt." />
  //<meta name="twitter:image" content="same wie eben"> 
  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url })
  }
  //<meta property="og:url" content="Die URL unter der man die Seite erreichen soll" />

}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-it-security',
  templateUrl: './it-security.component.html',
  styleUrls: ['./it-security.component.scss']
})
export class QlocPage_ITSecurityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'qloc-col-media',
  templateUrl: './col_media.component.html',
  styleUrls: ['./col_media.component.scss']
})
export class QlocComponent_ColMediaComponent {
  @Input() class: string = '';
  @Input() title: string = '';
  @Input() url: string = '';
  @Input() link: string = 'Mehr anzeigen';
  @Input() image: any = '';
  @Input() alt: string = '';
  @Input() iframe: string = '';
  @Input() size: number = 5;
  @Input() left: boolean = false;
  @Input() mediastyle: string = "";
  @Input() textstyle: string = "";
  @Input() downloads: Array<Array<String>> = [];

  images: Array<String> = [];
  iframeURL: SafeResourceUrl = '';


  constructor(private sanitizer: DomSanitizer) { }



  ngOnInit(): void {
    if ((typeof this.image) === "string") {
      this.images = [this.image];
      if (this.image == "")
        this.images = [];
    } else {
      this.images = this.image;
    }
    if (this.iframe != '')
      this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframe);

  }

}


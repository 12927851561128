<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Der Standard für rechtssichere E-Mail-Archivierung" image="assets/products/mailstore/mailstore-button-partner-authorized.webp">
        <p>Sie den Aufwand und die Kosten für das Projekt E-Mail-Archivierung auf ein Minimum. Wir begleiten Sie fachmännisch und Schritt für Schritt durch einen kurzen Einrichtungs-prozess und übernehmen fortan für Sie den Betrieb des Archivs.</p>
    </qloc-col-media>
</qloc-slide-in>


<qloc-slide-in>
    <div class="row">
        <div class="col-lg-6 col-12">
            <p>Die gesetzliche Anforderungen schreiben vor E-Mails über viele Jahre hinweg vollständig, manipulationssicher und originalgetreu aufzubewahren. Zur Lösung dieses Problems, bieten wir unseren Kunden E-Mail-Archivierung als Managed Service an.</p>
            <p>Wir setzen hierbei auf MailStore, welche als tausendfach erprobte Standard-Software einfach und schnell einsetzbar und dennoch, auch bei hohen Anforderungen, wartungsarm und zuverlässig ist. Durch den niedrigen Einstiegspreis und die integrierte
                Speichertechnologie werden zudem die Gesamtkosten auf niedrigstem Level gehalten. Über 60.000 Unternehmen aller Größen und Branchen setzen auf MailStore.</p>
        </div>
        <div class="col-lg-6 col-12">
            <qloc-tick>Keine hohen Investitionskosten</qloc-tick>
            <qloc-tick>Keine Anschaffung von Hardware erforderlich</qloc-tick>
            <qloc-tick>Keine zusätzliche Software-Lizenzierung</qloc-tick>
            <qloc-tick>Planung der Lösung individuell nach Ihren Anforderungen</qloc-tick>
            <qloc-tick>Betrieb und die Wartung der Lösung durch uns</qloc-tick>
            <qloc-tick>Monitoring und Skalierung von Ressourcen bei steigendem E-Mail-Volumen</qloc-tick>
            <qloc-tick>Rechenzentrum in Frankfurt-am-Main ISO9001, ISO27001 zertifiziert</qloc-tick>
            <qloc-tick>Software zertifiziert nach IDW PS 880</qloc-tick>
        </div>
    </div>
</qloc-slide-in>


<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Flexible Archivierung" image="assets/products/mailstore/flexible_email_archivierung_mit_mailstore-730x268.webp">
        <p>E-Mails können durch das Aufsetzen einer Journal-Archivierung nach unseren empfohlenen Vorgaben direkt bei Ein- und Ausgang archiviert werden.</p>
        <p>Der Vorteil: die Vollständigkeit der Archivierung kann sichergestellt werden, auch wenn Anwender E-Mails aus ihren Postfächern löschen. E-Mails können auch durch technische Fehler wie defekte PST-Dateien verloren gehen. Wir helfen Ihnen dabei
            diese Risiken auszuschließen.<br /> Wir ermöglichen zudem die Archivierung bereits vorhandener E-Mails aus verschiedenen Quellen. Durch das regelbasierte Löschen von E-Mails nach der Archivierung kann das E-Mail-Volumen auf dem E-Mail-Server
            gleichbleibend gering gehalten werden.</p>
        <p>
            Beispielsweise können täglich alle E-Mails, die älter sind als 18 Monate, aus den Postfächern gelöscht werden (nach ihrer erfolgreichen Archivierung).<br /> Neben regelmäßigen Zertifizierungen ermöglicht ein umfassendes Technologiekonzept,
            dass Ihr Unternehmen alle relevanten rechtlichen Anforderungen zuverlässig erfüllen kann. Dazu zählen die Vollständigkeit der Archivierung, Manipulationssicherheit, Protokollierung und Aufbewahrungsrichtlinien.</p>
    </qloc-card-media>
</qloc-slide-in>


<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Komfortabler und schneller Zugriff" image="assets/products/mailstore/zugriff-auf-das-mailstore-archiv.webp" [left]="true">
        <p>Ihre Mitarbeiter können über eine nahtlose Integration in Microsoft Outlook, über Web Access oder auch mobil auf die archivierten E-Mails zugreifen und diese schnell durchsuchen und steigert so die Produktivität in Ihrem Unternehmen. Anwender
            können E-Mails mit nur einem Mausklick aus dem Archiv heraus wiederherstellen. Das aufwändige Restore von Backups durch den Administrator entfällt völlig.</p>
        <p>E-Mails können bei Bedarf aus dem Archiv heraus exportiert und in Standard-Dateiformaten zur Verfügung gestellt werden. Die exportierten E-Mails entsprechen in jeder Hinsicht dem Original. Exportierten E-Mails kann eine kryptografische Signatur
            hinzufügt werden, um sie auch außerhalb des Archivs vor Manipulationen zu schützen.</p>
        <p>Durch Bildung von SHA-Hashwerten über die Inhalte der E-Mails und einer internen AES256-Verschlüsselung hilft MailStore Server die archivierten Daten vor Manipulationen zu schützen.</p>
    </qloc-col-media>
</qloc-slide-in>


<qloc-gradient class="mt-5">
    <div class="row mb-4">
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100" delay="0.6">
                <qloc-card-overview class="h-100" title="Absolute Vertraulichkeit Ihrer Daten">
                    <p>Obwohl wir für die Administration Ihres Archivs verantwortlich sind, haben wir keinen direkten Zugriff auf die enthaltenen Daten. Ihre Daten werden verschlüsselt gespeichert und jeder Zugriff wird protokolliert.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100" delay="0.3">
                <qloc-card-overview class="h-100" title="Ein vollwertiges Archiv für jeden Kunden">
                    <p>Ihre Daten werden nicht zusammen mit den Daten anderer Unternehmen in einer gemeinsamen Archivlösung abgelegt. Stattdessen wird für jeden Kunden eine exklusive Archivinstanz eingerichtet, die völlig separat betrieben wird.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-3">
            <qloc-slide-in class="h-100">
                <qloc-card-overview class="h-100" title="Langfristige Unabhängigkeit">
                    <p>ManagedArchiveService ist keine Einbahnstraße. Alle E-Mails können zu jeder Zeit und in Standard-formaten aus dem Archiv heraus wiederhergestellt werden. Dies garantiert eine langfristige Unabhängigkeit auch von ManagedArchiveService
                        selbst.
                    </p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
    </div>
</qloc-gradient>


<qloc-interesse class="mt-5"></qloc-interesse>
<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Unsere Leistungen" image="assets/partner/DellEMC-Authorized-Partner_gray.webp">
        Unser Leistungsspektrum reicht von der Einrichtung und Inbetriebnahme über die Betreuung und Wartung von Computersystemen, Netzwerken und Rechenzentren bis hin zur Ablösung und Migration von IT-Systemen.<br> Bei uns erhalten Sie IT-Komponenten
        aller Kategorien aus einer Hand mit hohen Qualitätsstandards aus dem Hause Dell, die Sie für einen reibungslosen Arbeitsalltag in Ihrem Unternehmen benötigen.<br> Wir garantieren eine schnelle und zuverlässige Lieferung. Gerne liefern wir auch
        direkt an verschiedene Standorte Ihres Unternehmens. Darüber hinaus unterstützen wir Sie gerne bei der Einrichtung und Installation Ihrer Hardware- und Softwarekomponenten.
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Professionelle Monitore und Zubehör" image="assets/products/dell/monitore/Dell_U2719D.webp" [size]="6">
        <p><strong>Egal ob Fotografie und Design, Essentials, Effizienz oder als High-Tech Arbeitsplatz - Dell bietet für alle Anwendungsfälle ein flexibles Portfolio von <24 Zoll Monitoren bis hin zu eindrucksvollen 86 Zoll Konferenzraummonitoren.</strong>            </p>
        <p>Die gesamte Produktpalette zeichnet eine außerordentliche Qualität aus und eignet sich für den täglichen professionellen Betrieb. Eine Vielzahl besonderer Merkmale wie Curved, Infinityedge, Premier Colour, HDR, Touch, 144 Hz, NVIDIA G-Sync, Free-Sync,
            4K und 8K lassen keine Wünsche offen. Passend hierzu gibt es Dockingstations, Montagehalterungen, Farbkalibrierer und vieles mehr!</p>
    </qloc-card-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-col-media class="mt-5 mb-5" title="Workstations und Desktop-PCs" image="assets/products/dell/workstations/dell_precision_7820_tower.webp" [size]="6" [left]="true">
        <p><strong>Hohe Leistungsfähigkeit spielt für alle Geschäftsbereiche eine wichtige Rolle. Umso wichtiger ist es, das budgetgerechte Optimum zu erhalten.</strong></p>
        <p>Von erschwinglichen Tower-Workstations für professionelle Leistung, über kompakte Tower-Workstations für geschäftskritische Leistung und Zuverlässigkeit, bis hin zu speziell für hohe Skalierbarkeit konzipierte leistungsstarke Tower- und Rack-Workstations,
            ist alles möglich. <br> Eine große Flexibilität bei der Wahl der Hardwarekonfiguration ermöglicht eine bedarfsgerechte und effiziente Investition. So lassen sich leistungsstarke Intel Xeon Prozessoren mit mehreren AMD Radeon Pro oder NVIDIA
            Quadro Grafikkarten, massig Arbeits- und Festplattenspeicher, ultraschnelle NVME-SSDs, diverse Peripherie sowie der ausgezeichnete Dell ProSupport kombinieren.<br> Die Optiplex-Reihe gilt als die weltweit sichersten, zuverlässigsten Desktop-PCs
            mit den besten Verwaltungsfunktionen für Unternehmen, die für jede Arbeitsumgebung geeignet sind. Sie ermöglichen eine hohe Flexibilität und sind aufgrund ihrer Robustheit und hoher Wärmetoleranz auch Industrietauglich.
        </p>
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card-media class="mt-4" title="Laptops für maximale Mobilität" image="assets/products/dell/laptops/dell-xps-15-9570.webp" [size]="6">
        <p><strong>Ob Homeoffice, Pendler, Außendienst mit Kundenkontakt oder im Konferenzraum. Vielerorts ist ein Laptop Ihr ständiger Begleiter. Umso wichtiger ist ein vielseitiges, leistungsstarkes, aber auch leichtes Gerät mit hoher Akkulaufzeit.</strong></p>
        <p>Auch hier bietet Dell für jeden Bedarf ein optimales Preis-Leistungsverhältnis. Beginnend bei erschwinglichen, soliden Office-Laptops mit 13-/15-/17 Zoll über robuste Allrounder mit Intel vPro und LTE, Ultrabooks mit atemberaubenden Designs und
            InfinityEdge-Display bis hin zur mobilen Workstation mit Intel Xeon Prozessor sowie NVIDIA Quadro Grafikkarte für High-End-2D/3D-Visualisierung, lässt Dells Portfolio keine Wünsche offen.<br> Optional schützen Sie Ihren idealen Reisebegleiter
            gegen alltägliche Missgeschicke und in risikoreiche Situationen mit dem Dell Unfallschutzservice.
        </p>
    </qloc-card-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-col-media class="mt-5 mb-5" title="Rack- / Towerserver" [image]="['assets/products/dell/server/Dell-EMC-PowerEdge_T640.webp', 'assets/products/dell/server/Dell-EMC-PowerEdge-14th-Generation-Stack.webp']" [size]="6" [left]="true">
        <p><strong>PowerEdge-Server kombinieren eine hochgradig skalierbare Architektur mit optimaler Balance zwischen Rechner und Speicher, um die Leistung verschiedenster Anwendungen zu maximieren.</strong></p>
        <p>
            Ob Fileserver, Container-Orchestrierung mit Kubernetes, Virtualisierung mit hoher Latenzempfindlichkeit, Virtual Desktop Infrastructure oder GPU-Beschleunigung für Datenanalyse, KI und maschinelles Lernen - jeder Anwendungsfall stellt unterschiedliche
            Anforderungen.
            <br /> Umso wichtiger ist eine zukunfsfähige, flexible, solide und zugleich wirtschaftliche Basis.<br /> Profitieren Sie von leistungsstarken rackmountfähigen Tower- und Rack-Servern mit aktuellsten Intel Xeon und AMD EPYC CPUs als Single-Socket,
            Dual-Socket oder Quad-Socket sowie modernster aufeinander abgestimmte Hardwarekomponenten. ECC fähiger Arbeitsspeicher, SAS Festplatten & SSDs, NVME-SSDs, RAID-Controller, redundante Netzteile und JBOD-Storageerweiterungen sind nur der Anfang.
        </p>
        <p>
            Intuitives Management sowohl remote als auch lokal, hohe Kompatibilität zu Drittanbieter Software, Zertifizierung zur Frischluft-Kühlung, Erfüllung hoher industrieller Qualitäts- und Sicherheitsstandards sowie eine lange garantierte Lebensdauer in anspruchsvollen
            Umgebungen oder auf beengtem Raum, ohne Kompromisse bei der Leistung einzugehen. Automatisieren Sie den gesamten Serverlebenszyklus von der Bereitstellung bis zur Stilllegung mit integrierter Intelligenz, die Ihre Produktivität erheblich steigert.
            Schützen Sie Ihre Kunden und Ihr Unternehmen mit einer umfassenden Verteidigungslinie, die in die Hardware und Firmware jedes Servers integriert ist.<br /> Dell PowerEdge ermöglicht Ihrem Unternehmen einen nachhaltigen, kontinuierlichen
            und effizienten Wachstum.
        </p>
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card-media class="mt-4" title="Netzwerk-Switches" [image]="['assets/products/dell/networking/dell-networking-dell-x-series.webp ' , 'assets/products/dell/networking/stats-networking.webp ']" [size]="6">
        <p><strong>Maximieren Sie Ihre Optionen, befreien Sie sich vom Anbieterzwang und profitieren Sie von innovativen, frei kombinierbaren Hardware- und Softwarefunktionen für Netzwerke von Dell EMC sowie Dell-Netzwerkpartnern. Setzen Sie Dell Switches überall dort ein, wo Sie diese benötigen – vom Rechenzentrum über den Campus bis zum Edge.</strong></p>
        <p>Stellen Sie modernste softwarebasierte Architekturen bereit, die auf die Skalierung und das Management moderner Netzwerke ausgelegt sind. Nutzen Sie innovative Technologien, um physische Infrastruktur, virtuelle Infrastruktur und Managementvorgänge
            zu konvergieren. Stellen Sie die Netzwerkleistung bereit, die moderne Workloads und virtuelle Umgebungen heute erfordern, und zwar innerhalb des Racks, zwischen Racks und zwischen Rechenzentren. Entwickelt für hohe Leistung und Flexibilität.<br>            Intelligente Lösungen gibt es auch zur einfachen Bereitstellung kleinerer Netzwerke und zur reibungslosen Verwaltung von Niederlassungen mit eingeschränktem IT-Personal.<br> Profitieren Sie von bis zu 50% geringeren Gesamtbetriebskosten sowie
            bis zu 3x größere Serviceflexibilität gemäß <a href="https://dellemcevents.com/uploads/CREATING-AGILITY-EFFICIENCY-AT-SCALE_ACG-2017.pdf" data-umami-event="page-dell-acgresearch-link" target="_blank">ACG RESEARCH</a>.</p>
    </qloc-card-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-col-media class="mt-5 mb-5" title="Hyperkonvergente Infrastruktur" [image]="['assets/products/dell/hci/Dell-PowerEdge-VRTX_6-small.webp' , 'assets/products/dell/hci/dell_emc_vxrail_sseries.webp', 'assets/products/dell/hci/vxrack-converged-small.webp']"
        [size]="6" [left]="true">
        <p>
            Modernisieren Sie Ihr Software Defined Data Center mit der einzigen gemeinsam entwickelten, vorkonfigurierten und vorab getesteten hyperkonvergenten VMware-Infrastruktur-Appliance für eine <strong>nahtlose Erfahrung und vereinfachte IT-Servicebereitstellung.</strong>
        </p>
        <p>
            Sparen Sie sich die gesamte Netzwerk- und Storage Backbone. Mit einer HCI (hyperkonvergenten Infrastruktur) können die Rechner-, Speicher- und Netzwerkfunktionen von der zugrunde liegenden Infrastruktur entkoppelt werden, wodurch sich die <strong>Effizienz der Infrastruktur gemäß <a href="https://germany.emc.com/collateral/analyst-report/vxblock-value-realized.pdf" target="_blank">IDC-Studie</a> um bis zu 66% verbessert und die Betriebskosten um bis zu 61% sinken</strong>.
        </p>
        <p>
            Dell EMC VxRail skaliert linear auf bis zu 64 Nodes mit Unterstützung für tausende von VMs und umfasst dabei Datenreplikation, Backup und Cloud-Tiering.
        </p>
        <p>
            Auch kleine wachsene Unternehmen profitieren von einer hyperkonvergenten Infrastruktur. PowerEdge VRTX verfügt über sämtliche Komponenten eines Rechenzentrums bis hin zum Edge-Switch für den Betrieb im Büro und ersparen hiermit die Planung sowie den Ausbau
            eines Rechenzentrums. Mit den diversen aktuell erhältlichen PowerEdge Blade-Serveroptionen und der Unterstützung von Blade-Servern zukünftiger Generationen sind Sie heute und auch morgen optimal gerüstet.
        </p>
    </qloc-col-media>
</qloc-slide-in>


<qloc-gradient title="Service und Lieferung">
    <div class="row row-eq-height mb-5">
        <div class="col-lg-4 col-12 mt-4">
            <qloc-slide-in delay="0.6" class="h-100">
                <qloc-card-overview class="h-100" title="ProSupport Enterprise Suite">
                    <p>Erhalten Sie Zugriff auf umfassende Fachkompetenz von Dell, damit Sie sich ganz auf Ihr Kerngeschäft konzentrieren können. Individuelle Support-Level je nach Wichtigkeit der einzelnen Systeme mit automatisierten prädiktiven Analysen,
                        Drittanbieterunterstützung und Dienstkontoverwaltung.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-4">
            <qloc-slide-in delay="0.3" class="h-100">
                <qloc-card-overview class="h-100" title="Unfallschutzservice">
                    <p>Schützen Sie Ihre Geräte vor alltäglicher Missgeschicke und risikoreicher Situationen. Schneller Reparatur- oder Austauschservice von Systemen, die durch Herunterfallen, Eindringen von Flüssigkeiten, Überspannung oder Zusammenstöße
                        unbeabsichtigt beschädigt wurden.
                    </p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-lg-4 col-12 mt-4">
            <qloc-slide-in class="h-100">
                <qloc-card-overview class="h-100" title="Individuelle Konfiguration & Lieferung">
                    <p>Wir beraten Sie persönlich und konfigurieren für Sie jedes System inidividuell entsprechend Ihrer Anforderungen. Gerne liefert wir Ihnen auch große Stückzahlen an mehrere Betriebs-Standorte und übernehmen für Sie den Aufbau sowie die
                        Konfiguration.
                    </p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
    </div>
</qloc-gradient>

<qloc-interesse></qloc-interesse>
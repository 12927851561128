import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-awareness-hero',
  templateUrl: './awareness-hero.component.html',
  styleUrls: ['./awareness-hero.component.scss']
})
export class QlocPage_AwarenessHeroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

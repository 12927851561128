<div class="testimonial">
    <p class="text">
        {{ text }}
    </p>
    <div class="bio">
        <div class="avatar">
            <img src="{{ avatar }}" class="img-fluid" alt="avatar">
        </div>
        <div class="bio-content">
            <h4 class="name">{{ name }}</h4>
            <p class="info">{{ info }}</p>
            <i class="icon icon-quote"></i>
        </div>
    </div>
</div>
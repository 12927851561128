import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-ip-telefone',
  templateUrl: './ip-telefone.component.html',
  styleUrls: ['./ip-telefone.component.scss']
})
export class QlocPage_IPTelefoneComponent implements OnInit {

	items = require('@config/ip-telefone.json');

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="container">
    <div class="row">
        <div class="col-md-6 col-sm-6 col-6">
            <ul class="qloc-header qloc-header-l xs-top-bar-info">
                <li>
                    <a href="tel:+4930220129830" data-umami-event="header-phone-link">
                        <fa-icon icon="phone"></fa-icon>030 2201298-30</a>
                </li>
                <li>
                    <a href="mailto:info@qloc.de" data-umami-event="header-email-link">
                        <fa-icon icon="envelope"></fa-icon>info@qloc.de</a>
                </li>
            </ul>
        </div>
        <div class="col-md-6 col-sm-6 col-6">
            <ul class="qloc-header qloc-header-r top-menu">
                <li>
                    <a href="https://kundencenter.qloc.de" data-umami-event="header-kundencenter-link">
                        <fa-icon icon="sign-in-alt"></fa-icon>Kundencenter</a>
                </li>
                <li>
                    <a href="/kontakt" data-umami-event="header-kontakt-link">
                        <fa-icon icon="question-circle"></fa-icon>Kontakt</a>
                </li>
            </ul>
        </div>
    </div>
</div>
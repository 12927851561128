<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">


    <qloc-card class="mt-5 mb-5" title="IT-Seal">
        <p>
            IT-Seal hat für Sie die innovative und preisgekrönte Awareness Engine entwickelt. Diese Awareness-Technologie trainiert Ihre Mitarbeiter bedarfsgerecht und vollautomatisiert für eine nachhaltige und effiziente Sensibilisierung. Das Ergebnis ist eine aktive Sicherheitskultur und aufgeklärte Mitarbeiter, die ihre Verantwortung für ihr Unternehmen kennen und wahrnehmen. Die Awareness Engine bildet das technologische Herzstück unserer Awareness Academy und bietet Training im Autopiloten: Jeder Teilnehmer erhält so viel Training wie nötig und so wenig wie möglich.
        </p>
        <p>
            Zu Beginn legen Sie Ihr persönliches Sicherheits-Zielniveau fest. Hierfür bietet IT-Seal den Employee Security Index ESI®, unsere wissenschaftlich fundierte, standardisierte Security-Awareness-Kennzahl. Anhand dieser werden Ihre Mitarbeiter kennzahlenbasiert trainiert, um Ihren Ziel-ESI® zu erreichen und langfristig zu halten.
        </p>
    
        <qloc-col-media [downloads]="[['Awareness Hero Features','assets/products/awareness-hero/Awareness_Hero_Features.pdf'], ['Awareness Hero Leistungsbestimmungen','assets/products/awareness-hero/Awareness_Academy_Leistungsbestimmungen.pdf'], ['Awareness Hero Onepager','assets/products/awareness-hero/Awareness_Hero_Onepager.pdf']]">
            <ul>
                <li>Mit der preisgekrönten Awareness Engine von IT-Seal trainieren Ihre Mitarbeiter so viel wie nötig und so wenig wie möglich.</li>
                <li>Die Awareness Engine bietet Training im Autopiloten und pausiert beziehungsweise startet das Training Ihrer Mitarbeiter automatisch.</li>
                <li>Das Awareness-Training erfolgt gruppenspezifisch und es ist dank des Ziel-ESI® stets kennzahlenbasiert und bedarfsgerecht.</li>
            </ul>
        </qloc-col-media>
    </qloc-card>

    
</qloc-slide-in>


<qloc-fullscreen-bg bgcolor="#12457f" color="#FFFFFF" class="mt-5">
    <div class="row mt-5 mb-5">
        <div class="col mb-4">
            <qloc-card class="h-100" title="Training im Autopiloten">
                <p>
                    Die einzigartige Awareness Engine von IT-Seal trainiert Ihre Mitarbeiter zu allen relevanten Awareness-Themen – und zwar eigenständig im Autopiloten. Sie können sich entspannt zurücklehnen und gewiss sein, dass Ihre Mitarbeiter kontinuierlich und effektiv trainieren.
Unsere preisgekrönte Awareness Engine checkt regelmäßig die Security Awareness Ihrer Mitarbeiter und stellt fest, welche Gruppe noch Trainingsbedarf hat und welche bereits sicher ist. Entsprechend wird das Training einer Gruppe automatisch pausiert, weitergeführt oder wiederaufgenommen.

                </p>
            </qloc-card>
        </div>
        <div class="col mb-4">
            <qloc-card class="h-100" title="So viel wie nötig - so wenig wie möglich">
                <p>
                    Nicht jeder Mitarbeiter benötigt gleich viel Training: Manche sind bereits sehr sicher im Umgang mit Informationssicherheit und erkennen Phishing-Mails mit Leichtigkeit, wohingegen andere Teilnehmer noch unsicher sind und höheren Trainingsbedarf haben.
                </p>
                <p>
                    Um diesem individuellen Bedarf gerecht zu werden, erhält jede Mitarbeitergruppe durch die Awareness Engine genau so viel Training wie nötig – aber so wenig wie möglich. So können sichere Mitarbeitergruppen, die bereits den Ziel-ESI® erreicht haben, sich voll auf ihre eigentliche Arbeit konzentrieren und Mitarbeitergruppen unterhalb des angestrebten Ziel-ESI® werden zielgerichtet trainiert.
                </p>
            </qloc-card>
        </div>
        <div class="col mb-4">
            <qloc-card class="h-100" title="Vielfältige Trainingsmethoden">
                <p>
                    So vielfältig wie Security Awareness, sind auch unsere Trainingsmethoden, mit denen wir Ihre Mitarbeiter effektiv und nachhaltig trainieren. Neben E-Learnings, Kurzvideos, Online-Seminaren, Präsenzschulungen und Awareness Materialien werden Ihre Mitarbeiter:innen vor allem via authentischen Spear-Phishing-Simulationen trainiert und auf Angriffe vorbereitet.
                </p>
                <p>
                    Die preisgekrönte Awareness Engine sorgt automatisch dafür, dass Ihre Mitarbeiter:innen alle relevanten Trainingsinhalte und Methoden erhalten, die sie benötigen, sodass das Awareness Training stets abwechslungsreich und effektiv bleibt.
                </p>
            </qloc-card>
        </div>
        <div class="col mb-4">
            <qloc-card class="h-100" title="Unsere Awareness Engine ist Datenschutz-konform zum Schutz Ihrer Mitarbeiter">
                <p>
                    Der Mitarbeiterschutz steht im Vordergrund, weshalb die Ergebnisse stets gruppenbasiert und anonym ausgewertet werden. Nach Abteilung, Hierarchie oder regional – entscheiden Sie selbst, welche Gruppen Sie vergleichen möchten. Die Awareness Engine stellt fest, welche Gruppe bereits das gewünschte Sicherheitsniveau erreicht hat und welche noch unter dem Ziel-ESI® liegt und steuert so das Training gruppenbasiert und bedarfsgerecht.
                </p>
            </qloc-card>
        </div>
    </div>
</qloc-fullscreen-bg>

<qloc-slide-in>
    <qloc-card class="mt-5 mb-5">
        <video width="100%" height="100%" controls>
            <source src="https://it-seal.de/wp-content/uploads/2021/09/ESI_HD.mp4#t=1" type="video/mp4">
        </video>
    </qloc-card>
</qloc-slide-in>


<qloc-interesse></qloc-interesse>
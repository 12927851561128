<qloc-slide-in class="mt-4">
    <h2>
        Was Sie von unserem IT-Service erwarten können</h2>
    <p class="mt-4">Wir bieten klassischen IT-Support und IT-Services aus der Cloud für Unternehmen und Privatanwender. Unsere wirtschaftlichen IT-Lösungen liefern Ihnen skalierbare und individuelle IT-Servicepakete aus unterschiedlichen IT-Dienstleistungen. Mit unseren
        Kernkompetenzen IT-Service und -Support, Serveradministration, Netzwerkadministration sowie diversen Hosting- und Clouddiensten sind wir Ihr fairer und zuverlässiger IT-Serviceanbieter.</p>
    <h4 class="mt-4 mb-4">Quality Location bedeutet:</h4>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <p>
                <qloc-tick>Support per Fernwartung oder vor Ort</qloc-tick>
                <qloc-tick>IT-Service in geplanten Intervallen</qloc-tick>
                <qloc-tick>Individuelle IT-Servicepakete</qloc-tick>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <p>
                <qloc-tick>IT-Beratung</qloc-tick>
                <qloc-tick>Managed IT-Services</qloc-tick>
                <qloc-tick>Vertrieb von Hard- und Software</qloc-tick>
        </div>
    </div>
    <p>Auf Wunsch unterstützen wir auch Sie bei der Planung, Ausführung und Wartung Ihres IT-Projektes. Ganz egal, ob es dabei um die regelmäßige Serverpflege geht, Sie Ihre Systeme auf dem aktuellsten Stand halten möchten, Sicherheitslücken aufspüren und
        schließen müssen, die Performance erhöhen oder Ihren Workflow optimieren wollen.</p>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card class="mt-5">
        <div class="row">
            <div class="col-lg-6 col-12">
                <h2>Wie gut ist Ihr Netzwerk aufgestellt? Erfahren Sie es mit einem Klick!</h2>
                <p class="mt-4">Ein gutes Netzwerk stellt nicht nur im Geschäftsalltag, sondern auch in der IT eine essenzielle Kernkomponente dar. Hierbei spielen Bandbreite und Ping eine große Rolle. Während es sich bei Ersterem um die Menge an Daten handelt, die innerhalb
                    eines bestimmten Zeitraums einen Übertragungskanal passieren kann, beziffert der Ping die Zeit in Millisekunden, die vergeht, bis die Anfrage des eigenen Rechners von der Gegenseite beantwortet wird. Je niedriger der Ping-Wert, desto
                    schneller ist die Internetverbindung. Um herauszufinden, wie gut Ihre Internetverbindung ist und ob es hier Optimierungspotenzial gibt, brauchen Sie sich nicht zwingend sofort an unsere IT-Service-Experten zu wenden. Denn hierfür genügt
                    erst einmal ein Klick auf den Button "Jetzt testen". Probieren Sie es doch einmal selbst aus und testen Sie, wie rund Ihr Internet tatsächlich läuft. Ist Ihnen Ihre Infrastruktur nicht performant genug, sind wir für Sie da!</p>
            </div>
            <div class="col-lg-6 col-12">

                <a href="https://qloc.speedtestcustom.com/" data-umami-event="page-itservice-speedtest-link">
                    <div class="network-button-animation">
                        <div>
                            <svg class="button-animation__border-inner network-stroke-primary" preserveAspectRatio="xMinYMin meet" viewBox="0 0 320 320">
                            <circle id="spinner-shape" cx="160" cy="160" r="105" stroke-width="3"></circle>
                        </svg>
                            <svg class="button-animation__border-outer network-stroke-primary" preserveAspectRatio="xMinYMin meet" viewBox="0 0 320 320">
                            <circle id="spinner-shape2" cx="160" cy="160" r="105" stroke-width="3"></circle>
                        </svg>
                        </div>
                        <button class="network-button background-primary-hover network-text-primary" aria-label="start your speedtest">
                        <span style="font-size: unset; overflow-wrap: unset;">JETZT TESTEN</span>
                    </button>
                    </div>
                </a>
            </div>
        </div>
    </qloc-card>
</qloc-slide-in>

<qloc-fullscreen-bg class="mt-5" src="assets/products/it-service/bg.webp">
    <div class="row mt-1 mb-5">
        <div class="col-xl-3 col-lg-6 col-12 mt-4">
            <qloc-slide-in class="h-100" delay="0.6">
                <qloc-card-overview class="h-100" title="IT-Beratung">
                    <p>Unsere Systemadministratoren und Servicetechniker können Ihnen in fast allen Aspekten weiterhelfen. Für Spezialthemen ziehen wir spezialisierte Kollegen aus dem Team hinzu, so dass Sie immer bestmöglich betreut werden.</p>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-xl-3 col-lg-6 col-12 mt-4">
            <qloc-slide-in class="h-100" delay="0.4">
                <qloc-card-overview class="h-100" title="IT-Sicherheit">
                    <ul>
                        <li>Sicherheitsanalyse</li>
                        <li>Antivirus und Antispam</li>
                        <li>E-Mail-Sicherheit</li>
                        <li>Firewall- und Zugriffsschutz</li>
                        <li>Unified Thread Management</li>
                    </ul>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-xl-3 col-lg-6 col-12 mt-4">
            <qloc-slide-in class="h-100" delay="0.2">
                <qloc-card-overview class="h-100" title="IT-Notfallplan">
                    <ul>
                        <li>Absicherung gegen Störfälle</li>
                        <li>Sofortmaßnahmen bei Systemausfällen</li>
                        <li>Zeitnahe Wiederinbetriebnahme Ihrer Systeme</li>
                    </ul>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-xl-3 col-lg-6 col-12 mt-4">
            <qloc-slide-in class="h-100">
                <qloc-card-overview class="h-100" title="Virtualisierung">
                    <ul>
                        <li>Zustands- und Leistungsüberwachung Ihrer Systeme</li>
                        <li>Alarmierung bei kritischen Problemen</li>
                        <li>Frühzeitige Erkennung von kritischen Problemen</li>
                    </ul>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-xl-3 col-lg-6 col-12 mt-4">
            <qloc-slide-in class="h-100" delay="0.6">
                <qloc-card-overview class="h-100" title="Datensicherung">
                    <ul>
                        <li>effiziente Skalierung</li>
                        <li>optimale Auslastung der Hardware</li>
                        <li>Hardwarekosten-Optimierung</li>
                        <li>hohe Verfügbarkeit</li>
                    </ul>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-xl-3 col-lg-6 col-12 mt-4">
            <qloc-slide-in class="h-100" delay="0.4">
                <qloc-card-overview class="h-100" title="E-Mail Sicherheit">
                    <ul>
                        <li>Backup-Strategien</li>
                        <li>Ausfallsicherheit</li>
                        <li>Schutz vor Datenverlust</li>
                        <li>Maßnahmen gegen absichtliches oder versehentliche Datenlöschung</li>
                        <li>Archivierung</li>
                    </ul>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-xl-3 col-lg-6 col-12 mt-4">
            <qloc-slide-in class="h-100" delay="0.2">
                <qloc-card-overview class="h-100" title="Office 365 & VoIP">
                    <ul>
                        <li>E-Mail-Verschlüsselung</li>
                        <li>digitale Signatur</li>
                        <li>Anti-SPAM</li>
                        <li>Schutz vor Manipliation</li>
                        <li>sichere und Datenschutz-Konforme Archivierung</li>
                    </ul>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
        <div class="col-xl-3 col-lg-6 col-12 mt-4">
            <qloc-slide-in class="h-100">
                <qloc-card-overview class="h-100" title="IT-Überwachung">
                    <ul>
                        <li>Alle Office-Produkte in der Cloud</li>
                        <li>Weltweite Zusammenarbeit</li>
                        <li>Aktuelle Software</li>
                        <li>VoIP Telefonie</li>
                        <li>Telecomunication as a Service</li>
                        <li>Software as a Service</li>
                    </ul>
                </qloc-card-overview>
            </qloc-slide-in>
        </div>
    </div>
</qloc-fullscreen-bg>

<qloc-interesse></qloc-interesse>
import { Component, Input, OnInit } from '@angular/core'; 
import { DomSanitizer } from '@angular/platform-browser';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


interface ProductObject {
  title: string,
  piktogramm: string,
  subtitle: string,
  datenblatt: string,
  text: string,
  image: string,
  overview: Array<String>,
  promobox: Array<{
    icon: IconProp,
    text: string,
  }>
}

@Component({
  selector: 'qloc-product-overview',
  templateUrl: './product_overview.component.html',

  styleUrls: ['./product_overview.component.scss']
})
export class QlocComponent_ProductOverviewComponent {

  @Input() data: ProductObject = {
    "title": "",
    "piktogramm": "",
    "subtitle": "",
    "datenblatt": "",
    "text": "",
    "image": "",
    "overview": [""],
    "promobox": [{
        "icon": "home",
        "text": ""
    }]};
    @Input() class: string = "";

  constructor(private sanitizer: DomSanitizer) { 
  }  


  transformHTML(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}

<div class="row">
    <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
        <h2 class="title">{{ headline }}</h2>
        <h3 class="text">{{ text }}</h3>
        <div class="row overview-items">
            <div class="col-lg-{{ overview.length == 4 ? 6 : 4 }} col-md-6 col-sm-6 col-xs-6" *ngFor="let item of overview">
                <h4 class="overview-item">
                    <qloc-tick>{{ item }}</qloc-tick>
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <p class="slidebutton">
                    <a href="{{ link }}" class="btn btn-success" role="button">
                        <fa-icon icon="arrow-circle-right"></fa-icon>Mehr anzeigen</a>

                </p>
            </div>
            <div class="col-6">
                <img *ngIf="simage" src=" {{ simage }} " alt="small brand image" class="secondary-image ">
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12 text-center qloc-sho-images">
        <img *ngFor="let img of images;" src="{{img}}" [style]="mediastyle" alt="big slider image" class="qloc-sho-image">
        <div *ngIf="html" [innerHTML]="safehtml" class="qloc-sho-html"></div>
    </div>
</div>
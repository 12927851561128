<div class="qloc-product-overview {{ class }}">
    <h2 class="headline">
        {{data.subtitle}}
        <a target="_blank" *ngIf="data.datenblatt" href="{{ data.datenblatt }}" class="btn btn-qloc pull-right" style="margin-right: 15px;">Datenblatt</a>
    </h2>
    <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <p [innerHTML]="data.text"> </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <img [src]="data.image" class="img-responsive img-product">
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="promobox">
                <h4 class="headline">Optimal für:</h4>
                <div class="row">
                    <div class="col-lg-{{ data.promobox.length > 1 ? 6 : 12 }} col-12" *ngFor="let promo of data.promobox;">
                        <fa-icon [icon]="promo.icon"></fa-icon>
                        <span>{{ promo.text }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="overview">
                <qloc-tick *ngFor="let overview of data.overview;">{{ overview }}</qloc-tick>
            </div>
        </div>
    </div>
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'qloc-page-barco',
  templateUrl: './barco.component.html',
  styleUrls: ['./barco.component.scss']
})
export class QlocPage_BarcoComponent implements OnInit {

  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  ngOnInit(): void {
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src = 'https://content.channext.com/js/widget.js';

    this._renderer2.appendChild(this._document.body, script);
  }

}

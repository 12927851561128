<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">
    <qloc-card class="mt-5 mb-5" title="Falcon Prevent">
        <h5 class="mb-3">Cloud-nativer Virenschutz der nächsten Generation (NGAV)</h5>
        <p>
            Falcon Prevent ist der ideale Ersatz für Ihren vorhandenen Virenschutz. Die Lösung verbindet wirksame Präventionstechnologien und einen umfassenden Einblick in feindliche Angriffe. Die Einrichtung ist eine Sache von wenigen Minuten.
        </p>

        <h5 class="mb-4 mt-4">Prävention auf dem neuesten Stand der Technik</h5>

        <div class="row">
            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100">
                        <p>
                            Maschinelles Lernen und künstliche Intelligenz zur Erkennung bekannter und unbekannter Malware und Ransomware
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>

            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100">
                        <p>
                            Verhaltensbasierte Angriffsindikatoren (IOAs) verhindern versierte Angriffe; auch solche ohne Dateien oder Malware
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>

            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100">
                        <p>
                            Exploit Blocking stoppt die Ausführung und Verbreitung von Bedrohungen über nicht gepatchte Schwachstellen
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>

            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100">
                        <p>
                            Präventive Bedrohungsaufklärung sperrt alle als bösartig bekannte Aktivitäten
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
        </div>

        <h5 class="mb-4 mt-4">Umfassender Schutz</h5>

        <qloc-col-media [downloads]="[['CrowdStrike Falcon Prevent Produktübersicht','assets/products/crowdstrike/Crowdstrike_Falcon_Prevent.pdf']]">
            <p>
                Breite Plattformunterstützung – Windows, Windows Server, macOS und Linux
            </p>
            <p>
                Der schlanke Falcon-Agent wird auf jedem Endgerät ausgeführt und umfasst alle Präventionstechnologien, die zum Schutz von Endgeräten erforderlich sind – unabhängig davon, ob diese online oder offline sind.
            </p>
            <p>
                Schließt die von älteren Antivirenlösungen hinterlassenen Lücken.
            </p>
        </qloc-col-media>
    </qloc-card>
</qloc-slide-in>

<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">
    <qloc-card class="mt-5 mb-5" title="Falcon Device Control">
        
        <qloc-col-media [downloads]="[['CrowdStrike Falcon Device Control Produktübersicht','assets/products/crowdstrike/Crowdstrike_Falcon_DeviceControl.pdf']]">
            <p>
                Die breite Nutzung von USB-Geräten stellt ein bedeutendes Sicherheitsrisiko dar, da sie Bedrohungen enthalten und zu Datenlecks führen können. Mit CrowdStrike® Falcon® Device Control™ können Administratoren in Ihren Umgebungen verwendete USB-Geräte steuern und die damit verbundenen Risiken verringern.
            </p>
        </qloc-col-media>

        <h5 class="mb-4 mt-4">Kontrolle über USB-Geräte gewinnen</h5>

        <div class="row mt-4 mb-4">
            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Mühelose und einzigartige Transparenz der gesamten USB-Gerätenutzung" icon="desktop">
                        <ul>
                            <li>
                                Liefert automatische, völlige Transparenz der USB-Gerätenutzung
                            </li>
                            <li>
                                Entdeckt und erfasst detaillierte Geräteinformationen
                            </li>
                            <li>
                                Überwacht in den USB-Speicher geschriebene Dateien
                            </li>
                            <li>
                                Bietet Nutzungsdaten in Echtzeit und für die Vergangenheit
                            </li>
                            <li>
                                Enthält vordefinierte Dashboards und eine leistungsstarke Suchfunktion
                            </li>
                        </ul>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Nahtlose Integration in Falcon Endgeräteschutz" icon="shield">
                        <ul>
                            <li>
                                Verwaltung über einen Agenten, eine Konsole und eine Plattform
                            </li>
                            <li>
                                Bietet 100 % Cloud-basierte Gerätekontrolle
                            </li>
                            <li>
                                Auf einem einzigen Agenten in die CrowdStrike EDR-Lösung, Falcon Insight, integriert
                            </li>
                        </ul>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>

            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Präzise, detaillierte Richtlinenkontrolle" icon="balance-scale-right">
                        <ul>
                            <li>
                                Bietet detaillierte Zugriffsrechte
                            </li>
                            <li>
                                Liefert Geräteidentifikation nach Anbieter, Produkt und Seriennummer
                            </li>
                            <li>
                                Ermöglicht einfachen Richtlinienerstellungs-Workflow
                            </li>
                            <li>
                                Erlaubt das Testen der Richtlinienauswirkungen vor deren Durchsetzung
                            </li>
                        </ul>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
        </div>


    </qloc-card>
</qloc-slide-in>

<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">
    <qloc-card class="mt-5 mb-5" title="Falcon Firewall Management: Host Firewall">
        
        <qloc-col-media [downloads]="[['CrowdStrike Falcon Firewall Management Produktübersicht','assets/products/crowdstrike/Crowdstrike_Falcon_FirewallManagement.pdf']]">
            <p>
                Falcon Firewall Management ermöglicht eine einfache, zentralisierte Firewall-Verwaltung, die die Administration und Durchsetzung von Host-Firewall-Richtlinien leicht macht
            </p>
        </qloc-col-media>

        

        <div class="row mt-4 mb-4">
            <div class="col-lg-6 mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Einfache Firewall-Verwaltung">
                        <ul>
                            <li>Einfache Erstellung, Durchsetzung und Verwaltung von Firewall-Regeln und -Richtlinien</li>
                            <li>Neue Richtlinien auf der Grundlage von Vorlagen – Sie beginnen wahlweise mit einer leeren Richtlinie, Ihrer Vorlage oder einer CrowdStrike-Vorlage</li>
                            <li>Firewall-Regelgruppen einmal erstellen und in mehreren Richtlinien wiederverwenden</li>
                            <li>Änderungen an den entsprechenden Richtlinien schnell anwenden</li>
                        </ul>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-lg-6 mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Geringere Komplexität">
                        <ul>
                            <li>Vereinfacht den Betrieb durch Verwendung desselben schlanken Falcon Agenten, derselben Verwaltungskonsole und derselben cloud-nativen Architektur</li>
                            <li>Bereitstellung und Inbetriebnahme sind eine Sache von wenigen Minuten; ohne Neustart, Feinabstimmung oder komplexe Konfiguration</li>
                            <li>Keine zusätzliche Belastung der Endgeräte – von der Erstinstallation bis zum täglichen Betrieb</li>
                            <li>Rationalisiert die Arbeitsabläufe und erhöht die Transparenz der Sicherheitskontrollen an den Endgeräten dank der einheitlichen Verwaltungskonsole für den Endgeräteschutz und die Host-Firewall-Verwaltung</li>
                        </ul>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-lg-6 mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Besserer Schutz">
                        <ul>
                            <li>Automatische Identifizierung und Anzeige spezifischer Aktivitäten, potenzieller Bedrohungen und Netzwerkanomalien</li>
                            <li>Der Aktivitätsüberblick gibt sofortigen Aufschluss: Sie können von dort aus wichtige Regeln überwachen und berichtigen oder Firewall-Richtlinien direkt ändern</li>
                            <li>Anwendungs- und standortsensitive Firewall-Richtlinien verbessern die Sicherheit und ermöglichen eine detaillierte Steuerung</li>
                        </ul>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>

            <div class="col-lg-6 mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Protokollierung, Fehlerbehebung und Compliance">
                        <ul>
                            <li>
                                Schnellere Fehlerbehebung mit detaillierter Steuerung und Sichtbarkeit
                            </li>
                            <li>
                                Einfaches Aktivieren oder Deaktivieren einer bestimmten Regel, einer ganzen Regelgruppe oder einer Richtlinie
                            </li>
                            <li>
                                Alle Firewall-Regeln und Richtlinienänderungen überprüfen - Sie sehen, wer was wann geändert hat
                            </li>
                            <li>
                                Rollenbasierte Zugangssteuerung, damit sichergestellt ist, dass nur die entsprechenden Administratoren die Firewall-Regeln anzeigen und verwalten können
                            </li>
                        </ul>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
        </div>


    </qloc-card>
</qloc-slide-in>

<qloc-interesse></qloc-interesse>
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { filter, map, mergeMap } from 'rxjs';
import { SEOService } from './services/seo.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'qloc';

	constructor(private wowService: NgwWowService, private _seoService:SEOService, private router: Router, private activatedRoute: ActivatedRoute){
				this.wowService.init();
	}
	ngOnInit() {
		this.router.events.pipe(
			 filter((event) => event instanceof NavigationEnd),
			 map(() => this.activatedRoute),
			 map((route) => {
				 while (route.firstChild) route = route.firstChild;
				 return route;
			 }),
			 filter((route) => route.outlet === 'primary'),
			 mergeMap((route) => route.data)
			)
			.subscribe((event) => {
				if(event['title'])
					this._seoService.updateTitle(event['title']);
				if(event['desc'])
					this._seoService.updateDescription(event['desc'])
				if(event['keywords'])
					this._seoService.updateKeywords(event['keywords'])
				if(event['ogTitle']) {
					this._seoService.updateOgTitle(event['ogTitle'])
				} else {
					if(event['title'])
						this._seoService.updateOgTitle(event['title'])
				}
				if(event['ogDesc']) {
					this._seoService.updateOgDescription(event['ogDesc'])
				} else {
					if(event['desc'])
						this._seoService.updateOgDescription(event['desc'])
				}
				if(event['ogImage'])
					this._seoService.updateOgImage(event['ogImage'])
				//this._seoService.updateOgUrl(event['ogUrl']);
			});
		}
}

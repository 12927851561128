
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QlocComponent_CardComponent, QlocComponent_CardOverviewComponent, QlocComponent_CardMediaComponent, QlocComponent_CardDownloadComponent } from './card/card.component';
import { QlocComponent_GradientComponent } from './gradient/gradient.component';
import { QlocComponent_InteresseComponent } from './interesse/interesse.component';
import { QlocComponent_ColMediaComponent } from './col/col.component';
import { QlocComponent_IconComponent, QlocComponent_TickComponent, QlocComponent_CrossComponent  } from './icon/icon.component';
import { QlocComponent_FullscreenBGComponent } from './fullscreen_bg/fullscreen_bg.component';
import { QlocComponent_ProductOverviewComponent } from './product/product.component';
import { QlocComponent_SlideInComponent } from './animations/slide-in.component';


@NgModule({
  declarations: [
    QlocComponent_CardComponent,
    QlocComponent_CardOverviewComponent,
    QlocComponent_CardMediaComponent,
    QlocComponent_GradientComponent,
    QlocComponent_InteresseComponent,
    QlocComponent_ColMediaComponent,
    QlocComponent_IconComponent,
    QlocComponent_TickComponent,
    QlocComponent_CrossComponent,
    QlocComponent_FullscreenBGComponent,
    QlocComponent_CardDownloadComponent,
    QlocComponent_ProductOverviewComponent,
    QlocComponent_SlideInComponent

  ],
  exports: [
    QlocComponent_CardComponent,
    QlocComponent_CardOverviewComponent,
    QlocComponent_CardMediaComponent,
    QlocComponent_GradientComponent,
    QlocComponent_InteresseComponent,
    QlocComponent_ColMediaComponent,
    QlocComponent_IconComponent,
    QlocComponent_TickComponent,
    QlocComponent_CrossComponent,
    QlocComponent_FullscreenBGComponent,
    QlocComponent_CardDownloadComponent,
    QlocComponent_ProductOverviewComponent,
    QlocComponent_SlideInComponent
  ], 
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: []
})
export class QlocComponentsModule { }

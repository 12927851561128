<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">
    <qloc-card-media class="mt-5 mb-4" title="Citrix DaaS"
        iframe="https://www.youtube-nocookie.com/embed/C4U9woDXKWY?time_continue=2">
        <h5 class="mb-3">Sicheres, flexibles Arbeiten mit dem Marktführer für virtuelle Anwendungen und Desktops</h5>

        <p>
            Starten Sie in wenigen Minuten mit Citrix DaaS (ehemals Citrix Virtual Apps and Desktops Service), um Ihren
            Anwendern einen vertrauten, leistungsstarken digitalen Arbeitsplatz zu bieten. Wir verwalten die
            Infrastruktur und Sicherheit. Sie konzentrieren sich auf Ihr Geschäft.
        </p>

        <p>
            Mit Citrix DaaS können Sie einen sicheren Arbeitsplatz auf jedem Endgerät bereitstellen. Sie profitieren von
            allen Kostenvorteilen einer verwalteten DaaS-Lösung sowie den zusätzlichen Vorteilen einer höheren
            IT-Agilität, Unternehmenssicherheit und Anwenderproduktivität.
        </p>

        <h5 class="mb-3">Welche Vorteile bietet Ihnen Citrix DaaS:</h5>

        <div class="row">
            <div class="col-lg-6">
                <qloc-tick>Umfassende Sicherheit</qloc-tick>
                <qloc-tick>Herausragender Benutzerkomfort</qloc-tick>
                <qloc-tick>Hybrid Multi-Cloud</qloc-tick>
                <qloc-tick>Verwertbare Analysen</qloc-tick>
                <qloc-tick>Vereinfachtes Management</qloc-tick>
            </div>
            <div class="col-lg-6">
                <qloc-tick>Flexibilität</qloc-tick>
                <qloc-tick>Skalierbarkeit</qloc-tick>
                <qloc-tick>Kosteneinsparungen</qloc-tick>
                <qloc-tick>Kompatibilität</qloc-tick>
                <qloc-tick>Management</qloc-tick>
            </div>
        </div>
    </qloc-card-media>

    <qloc-card class="mt-4 mb-4">
        <h5 class="mb-3">Definition und Bedeutung von DaaS</h5>
        <p>
            Desktop-as-a-Service, oder einfach DaaS, ist ein cloudbasiertes Angebot, das virtuelle Anwendungen und
            Desktops von der Cloud an jedes beliebige Endgerät bereitstellt. Diese verwaltete Lösung zur
            Desktop-Virtualisierung schützt DaaS- und herkömmliche Anwendungen sowie komplette Windows-basierte
            virtuelle Desktops in einer Desktop-Umgebung und stellt sie Ihren Mitarbeitern bereit. Mit einem einfachen,
            berechenbaren Abonnementmodell auf Pay-as-you-go-Basis können Sie bei DaaS Ressourcen problemlos nach Bedarf
            hoch- oder herunterskalieren. Der Service lässt sich leicht managen und vereinfacht zahlreiche
            IT-Administratoraufgaben von Desktoplösungen.
        </p>

    </qloc-card>

    <qloc-card class="mt-4 mb-4">
        <h5 class="mb-3">Wie funktioniert DaaS?</h5>

        <div class="row mt-4">
            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100">
                        <p class="mb-0">
                            DaaS stellt virtuelle Anwendungen und Desktopservices über einen Public- oder <a
                                href="https://www.citrix.com/de-de/solutions/digital-workspace/what-is-a-cloud-service.html"
                                target="_blank">Private-Cloud-Service</a> bereit. Ihre Mitarbeiter können über eine
                            Internetverbindung per HTML-basiertem Webbrowser oder eine sichere Anwendung, die sie auf
                            ein Gerät wie einen Laptop, Desktop-PC, Thin Client, ein Smartphone oder ein Tablet
                            heruntergeladen haben, auf diesen Service zugreifen.
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>

            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100">
                        <p class="mb-0">
                            DaaS wird als Abonnementlösung angeboten und ist mandantenfähig. Die <a
                                href="https://www.citrix.com/de-de/solutions/vdi-and-daas/what-is-vdi-virtual-desktop-infrastructure.html"
                                target="_blank">Backend-VDI (Infrastruktur virtueller Desktops)</a>, einschließlich der
                            <a href="https://www.citrix.com/de-de/solutions/vdi-and-daas/what-is-a-virtual-machine.html"
                                target="_blank">virtuellen Maschinen</a>, auf denen Desktop-Betriebssysteme laufen, wird
                            von einem externen Cloud Service Provider gehostet. Der DaaS-Anbieter streamt dann die
                            virtuellen Desktops auf die Geräte der Endbenutzer.
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>

            <div class="col mb-4">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100">
                        <p class="mb-0">
                            DaaS-Anbieter verwalten die VDI-Bereitstellung sowie die Pflege, Sicherheit, Aktualisierung,
                            Datensicherung und Speicherung. Der Kunde verwaltet die Anwendungen und Desktop-Images. DaaS
                            ist eine gute Wahl für Unternehmen, die sich die Investition in VDI im eigenen Rechenzentrum
                            mit Servern und die dazugehörige Verwaltung sparen möchten.
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
        </div>

    </qloc-card>

    <qloc-card class="mt-4 mb-5">
        <h5 class="mb-3">Warum DaaS?</h5>
        <p>
            Mitarbeiter erwarten heutzutage, jederzeit und mit jedem Gerät arbeiten zu können. Unternehmen wiederum
            suchen nach einer kostengünstigen, einfach zu handhabenden Lösung für die sichere Bereitstellung der
            Anwendungen und Desktops, die die Mitarbeiter für ihre Arbeit benötigen. Das Management herkömmlicher
            PC-Umgebungen stellt die IT vor zahlreiche Aufgaben, die vom einfachen Zurücksetzen des Passworts für
            Mitarbeiter bis hin zu komplexen Patches und Updates zur Datensicherheit reichen. Diese nehmen nicht nur die
            wertvolle Zeit von IT-Administratoren in Anspruch, sondern können auch den Benutzerkomfort und die
            Produktivität beeinträchtigen.
        </p>
        <p>
            Viele Kunden setzen zwar auf <a
                href="https://www.citrix.com/de-de/solutions/vdi-and-daas/what-is-virtualization.html"
                target="_blank">Virtualisierung</a>, aber dieser Prozess erfordert Investitionen und spezialisierte
            IT-Kenntnisse. Als Reaktion darauf suchen viele Unternehmen nach einer vereinfachten Lösung, die innerhalb
            der Kostenstruktur ihres Betriebs funktioniert. DaaS kann eine rentable Option für Kunden sein, die die
            Zentralisierung, Sicherheit und Verwaltung einer VDI mit der Einfachheit einer Lösung verbinden möchten, bei
            der grundlegende Desktop-Management-Aufgaben von Experten extern abgewickelt werden.
        </p>

    </qloc-card>

</qloc-slide-in>


<qloc-fullscreen-bg bgcolor="#12457f" color="#FFFFFF" class="mt-5">

    <qloc-card class="mt-5 mb-4" title="Citrix Secure Private Access">
        <h5 class="mb-3">Zero-Trust-Netzwerkzugriff (ZTNA) für alle von der IT genehmigten Anwendungen und Steigerung
            der Produktivität
        </h5>
        <p>
            Mitarbeiter arbeiten von Zuhause aus. Die von ihnen verwendeten Geräte werden möglicherweise nicht
            verwaltet. Und der netzwerkzentrierte Ansatz, mit dem Sie einst zuverlässig Daten schützen konnten, reicht
            nicht mehr aus. Citrix Secure Private Access bietet ZTNA mit adaptivem Zugriff auf alle von der IT
            genehmigten Anwendungen – Web, SaaS und Client-Server – unabhängig davon, ob sie vor Ort oder in der Cloud
            bereitgestellt werden. So können Sie Angriffe auf Netzwerkebene verhindern. Und gleichzeitig für höheren
            Benutzerkomfort sorgen. (Bitte dieses Video mit einbauen)
        </p>
    </qloc-card>

    <qloc-card class="mb-4">
        <h5 class="mb-3">Mehr als nur herkömmliche Sicherheitskontrollen</h5>
        <p>
            Unbefugten Zugriff – und Gefahren – von nicht verwalteten und BYOD-Geräten verhindern
        </p>
        <p>
            Herkömmliche Sicherheitsansätze erweitern das Unternehmensnetzwerk auf Anwender. Bei den mittlerweile
            gängigen hybriden Arbeitsmodellen sind Ihre Ressourcen jedoch sehr anfällig für Angriffe. Hierin liegt der
            Unterschied von Citrix Secure Private Access. Die Lösung stellt Verbindungen auf Anwendungsebene her und
            macht es einfach, Ihre Sicherheitslage im Zeitalter der Remote-Arbeit zu verbessern. Sie können Ihr VPN
            durch Cloud-ZTNA ersetzen. Entwickeln Sie ein erfolgreiches BYOD-Programm. Verwenden Sie adaptive
            Authentifizierung und adaptive Zugriffsrichtlinien basierend auf Gerätesicherheit, Standort und
            Risikobewertungen. Ihre Mitarbeiter können über ihre bevorzugten Geräte auf alle wichtigen Ressourcen
            zugreifen. Ihre Daten bleiben dabei geschützt.
        </p>

    </qloc-card>

    <qloc-card class="mb-5">
        <div class="row">
            <div class="col mb-2">
                <h5 class="mb-3">5 Vorteile von Citrix Secure Private Access:</h5>

                <div class="row">
                    <div class="col-6">
                        <qloc-tick>Adaptiver Zugriff</qloc-tick>
                        <qloc-tick>Sicherheitsrichtlinien</qloc-tick>
                        <qloc-tick>Browser-Isolation</qloc-tick>
                    </div>
                    <div class="col-6">
                        <qloc-tick>Anwendungsschutz</qloc-tick>
                        <qloc-tick>Citrix Workspace Browser</qloc-tick>
                    </div>
                </div>
            </div>
            <div class="col mb-2">
                <h5 class="mb-3">Was Sie mit Citrix Secure Private Access erreichen können:</h5>

                <ul>
                    <li>
                        <a target="_blank" href="https://www.citrix.com/de-de/solutions/secure-access/replace-traditional-vpn.html">Anwenderanforderungen mit einer sicheren VPN-Alternative erfüllen</a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.citrix.com/de-de/solutions/secure-access/secure-unmanaged-byo-devices.html">BYOD und nicht gemanagte Geräte sicher unterstützen</a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.citrix.com/de-de/solutions/digital-workspace/modernize-it-security.html">IT-Sicherheit modernisieren</a>
                    </li>
                </ul>
            </div>
        </div>
    </qloc-card>

</qloc-fullscreen-bg>


<qloc-interesse></qloc-interesse>
<div class="col-image {{class}}">
    <h3 class="mb-4" *ngIf="title != ''">{{ title }}</h3>
    <div class="row row-eq-height">
        <div class="col-lg-{{ 12 - size }} col-12 h-100 order-lg-2" [style]="textstyle">
            <ng-content></ng-content>
            <p>
                <a href="{{ url }}" class="no-link color-qloc" role="button" *ngIf="url != ''">
                    <fa-icon icon="arrow-circle-right"></fa-icon>
                    {{ link }}
                </a>
            </p>
        </div>

        <div class="col-lg-{{ size }}  col-12 col-img {{ left ? 'order-lg-1' : 'order-lg-3' }}">
            <img *ngFor="let img of images;" src="{{img}}" [style]="mediastyle" alt="{{ alt }}">
            <iframe *ngIf="iframe" [src]="iframeURL" frameborder="0 " style="width:80%; height: auto; aspect-ratio: 16 / 9;" allowfullscreen=" " allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            <qloc-card-download *ngIf="downloads.length> 0" class="mt-3" [downloads]="downloads"></qloc-card-download>
        </div>
    </div>
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, HostListener } from '@angular/core';

@Component({
  selector: 'qloc-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class QlocTheme_ScrollToTopComponent implements OnInit {

    windowScrolled: boolean = false;
    constructor(@Inject(DOCUMENT) private document: Document) {}
    @HostListener("window:scroll", [])
    onWindowScroll() {

        if (this.document.defaultView !== null) {
            if (this.document.defaultView.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
                this.windowScrolled = true;
            } 
           else if (this.windowScrolled && this.document.defaultView.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
                this.windowScrolled = false;
            }
        }
    }
    scrollToTop() {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.scrollTo(0, 0);
            }
        })();
    }
    ngOnInit() {}
}
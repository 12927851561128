<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Cyberversicherung - Warum?" image="assets/products/nospamproxy/gateway-security-small-dark.webp" [size]="3" mediastyle="width: 80%; ">
        <p>Die Digitalisierung Ihres Unternehmens sorgt für eine stets wachsende IT-Infrastruktur. Zunehmende Komplexität führt zu potenziellen Schwachstellen in der IT sowie im Umgang Ihrer Mitarbeiter mit dieser. All dies erhöht das Cyber-Risiko Ihres
            Unternehmens.
        </p>
        <p>Häufig hört man von Hacker-Angriffen und gestohlenen Persönlichen Daten. Eine Umfrage des TÜV-Verbandes hat ergeben, dass mehr als jedes 10. Unternehmen bereits von einem Cyberangriff betroffen war. Besonders oft kommt es zu Phishingangriffen
            und zur kompromittierung mit Ransomsoftware.</p>
        <br />
        <p>Die Angriffe haben weitreichende Folgen, dazu zählen finanzielle Einbußen, Ausfall von Diensten und Produktionen und der Diebstahl sensibler Daten.</p>
    </qloc-card-media>
</qloc-slide-in>
<div class="row mt-5">
    <div class="col-lg-6 col-12">
        <qloc-slide-in delay="0.3">
            <div>
                <h2 class="mb-4">Vorsorge statt Nachsorge</h2>
                <p>Eine Lösung sucht man oft erst, nach dem der Schadensfall bereits eingetreten ist.<br /> Wir unterstützen Sie präventiv, um einen Schadensfall im Vorraus zu verhindern.</p>
                <qloc-tick>Sensibilisierung und Schulung der Mitarbeiter</qloc-tick>
                <qloc-tick>Reportingbereich inklusive Trainingsstatistiken</qloc-tick>
                <qloc-tick>Regelmäßige Phishing-Tests</qloc-tick>
                <qloc-tick>E-Mail-Scanner, Browser-Check, Angriffsalarm</qloc-tick>
                <qloc-tick>Passwort-Generator & -Erinnerung</qloc-tick>
            </div>
        </qloc-slide-in>
    </div>
    <div class="col-lg-6 col-12">
        <qloc-slide-in>
            <div>
                <h2 class="mb-4">Opfer und Mitverursacher</h2>
                <p>Die Rechtsprechung vertritt einen klaren Standpunkt: Wer z. B. durch unzureichende Sicherung seines Datenbestandes eine Schädigung eines Dritten begünstigt, ist Mitschuldiger (siehe u. a. auch IT-Sicherheitsgesetz, EU Datenschutz-Grundverordnung,
                    § 202a ff StGB)!</p>
                <p>Möchten Sie Ihr Unternehmen ernsthaft vor den finanziellen Folgen von Cyber-Risiken schützen, müssen sowohl Eigen- wie auch Fremdschaden abgesichert werden.</p>
            </div>
        </qloc-slide-in>
    </div>
</div>

<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Wer braucht eine Cyber-Versicherung?" image="assets/products/nospamproxy/trust-small.webp" [left]="true" [size]="4">
        <p>Jedes Unternehmen kann Opfer eines Angriffes werden. Besonders gefährdet sind Unternehmen, die
            <qloc-tick class="mt-2">mit vertraulichen Daten umgehen,</qloc-tick>
            <qloc-tick class="mt-2">einen zuverlässige Verfügbarkeit Ihrer Geschäftsabläufe benötigen,</qloc-tick>
            <qloc-tick class="mt-2">die persönlichen und geheimen Daten Ihres Unternehmens sowie der Kunden schützen wollen und</qloc-tick>
            <qloc-tick class="mt-2">auf Finanzielle Einbußen verzichten wollen.</qloc-tick>
        </p>
    </qloc-card-media>
</qloc-slide-in>

<qloc-slide-in>
    <div class="mt-5">
        <h2 class="mb-4">Wovor sollten Sie sich absichern?</h2>
        <p>Je nach Umfang des Versicherungsvertrages sind unterschiedliche Kosten versichert.</p>
    </div>
    <qloc-fullscreen-bg class="mt-5" bgcolor="#12457f">
        <div class="row mt-5 mb-5">
            <div class="col-lg-4 col-12 mt-3">
                <qloc-slide-in class="h-100" delay="0.9">
                    <qloc-card-overview class="h-100" title="Haftpflichtansprüche" icon="gavel">
                        <p>Ensteht eine Verpflichtung zum Schadensersatz leistet die Versicherung eine Entschädingungszahlungen maximal bis zur vertraglich festgesetzten Deckungssumme.</p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Eigenschäden" icon="house-chimney-crack">
                        <qloc-tick>Kosten für IT-Forensik</qloc-tick>
                        <qloc-tick>Rechtsberatung</qloc-tick>
                        <qloc-tick>Kosten für Krisenmanagement</qloc-tick>
                        <qloc-tick>Kosten für PR-Beratung</qloc-tick>
                        <qloc-tick>Betriebsunterbrechungsschäden</qloc-tick>
                        <qloc-tick>Vertragsstrafen</qloc-tick>
                        <qloc-tick>Lösegeldzahlungen</qloc-tick>
                        <qloc-tick>Wiederherstellungskosten</qloc-tick>
                        <qloc-tick>Sicherheitsverbesserungen</qloc-tick>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <qloc-slide-in class="h-100" delay="0.3">
                    <qloc-card-overview class="h-100" title="oft ausgeschlossene Kosten" icon="circle-xmark">
                        <qloc-cross>Verletzung von Gesetzen.</qloc-cross>
                        <qloc-cross>Vorsätzliche Verursachung.</qloc-cross>
                        <qloc-cross>Geldbußen oder Geldstrafen.</qloc-cross>
                        <qloc-cross>Schäden im Image des Unternehmens.</qloc-cross>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
        </div>
    </qloc-fullscreen-bg>
</qloc-slide-in>


<qloc-slide-in>
    <div class="mt-5">
        <h2 class="mb-4">Welche Risiken gibt es?</h2>
        <p>Aufgrund der immer größer werdender IT-Infrastrukur von Unternehmen sind immer mehr Cyber-Risiken entstanden. Im folgenden zeigen wir Ihnen einige Beispiele, die Ihr Unternehmen schaden könnten.</p>
    </div>
</qloc-slide-in>


<div class="row mt-2">
    <div class="col-lg-6 col-12 mt-3">
        <qloc-slide-in class="h-100" delay="0.3">
            <qloc-card-overview class="h-100" title="DOS-Atacke" image="assets/products/versicherung/dos.webp" mediastyle="max-width:30%; padding-bottom: 2rem;">
                <p> Denial of Service (kurz DoS, englisch für "Dienstverweigerung") bedeutet eine Nichtverfügbarkeit eines Dienstes. Bei DoS spricht man dabei hauptsächlich von einer mutmaßlichen und böswilligen Überlastung eines Dienstes. Bei Besonders großen
                    Angriffen spricht man auch von Distributed Denial of Service (DDoS). Der Ursprung dieser Angriffe sind meist riesige Computernetzwerke von überall auf der Welt.</p>

                <p>Beispiel: Ein Unternehmen betreibt einen Onlineshop zum Verkauf der angebotenen Ware. Durch einen DoS Angriff, der mehrere Tage fortgeführt wurde, bricht der Server zusammen und die regulären Kunden können aufgrund von Überlastung nicht
                    mehr zugegreifen.</p>
                <h5>Folgen:</h5>
                <qloc-cross>Entgangene Umsätze für die Zeit des Angriffes.</qloc-cross>
                <qloc-cross>Untersuchung des Vorfalls.</qloc-cross>
                <qloc-cross>Technische Optmimierungen.</qloc-cross>
                <qloc-cross>Imageschaden.</qloc-cross>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-6 col-12 mt-3">
        <qloc-slide-in class="h-100">
            <qloc-card-overview class="h-100" title="Datenmissbrauch" image="assets/products/versicherung/missbrauch.webp" mediastyle="max-width:30%; padding-bottom: 2rem;">
                <p>Es gibt viele verschiedene Arten des Datenmissbrauchs. Am häufigsten ist hier der betrügerische Missbrauch von Bank- und Kreditkartdaten der Kunden. Weiterhin kommt es oft zu Industriespionage oder zumSchädigen von Geschäftsabläufen. Zugang
                    zu den sensiblen Daten erhält der Angreifer beispielsweise durch Schadsoftware, Hardware oder durch Mitarbeiter gelangen.</p>
                <p>Beispiel: Die Datenbank einer Firma wird "gehackt". Die Angreifer bekommen Zugang zu Kreditkartendaten aller Kunden und missbrauchen diese, um hohe Summen zu erschleichen.</p>
                <h5>Folgen:</h5>
                <qloc-cross>Schadenersatzforderungen.</qloc-cross>
                <qloc-cross>Untersuchung des Vorfalls.</qloc-cross>
                <qloc-cross>Forensik.</qloc-cross>
                <qloc-cross>Technische Optmimierungen.</qloc-cross>
                <qloc-cross>Imageschaden.</qloc-cross>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-6 col-12 mt-3">
        <qloc-slide-in class="h-100" delay="0.3">
            <qloc-card-overview class="h-100" image="assets/products/versicherung/sabotage.webp" title="Datensabotage" mediastyle="max-width:30%; padding-bottom: 2rem;">
                <p>Bei der Datensabotage werden Daten beschädigt, verändert oder gelöscht.</p>
                <p>Beispiel: Ein Mitarbeiter lädt eine Datei herunter. Diese Datei ist mit einem Virus versehen. Beim Aufrufen wird das gesamte Firmennetzwerk kompromittiert. Es kommt zur Löschung 10.000er geschäftskritischer Dateien wie Angebote, Rechnungen,
                    Kontaktdaten, Projekte etc.</p>
                <h5>Folgen:</h5>

                <qloc-cross>Verlust von bereits geleisteter Arbeit.</qloc-cross>
                <qloc-cross>Überstunden für Ihre Mitarbeiter.</qloc-cross>
                <qloc-cross>Versäumen von Fertigstellungsterminen.</qloc-cross>
                <qloc-cross>Schadenersatzforderungen.</qloc-cross>
                <qloc-cross>Probleme bei Buchhaltung, Kundenkontakt etc.</qloc-cross>
                <qloc-cross>Untersuchung des Vorfalls.</qloc-cross>
                <qloc-cross>Forensik.</qloc-cross>
                <qloc-cross>Technische Optmimierungen.</qloc-cross>
                <qloc-cross>Imageschaden.</qloc-cross>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-6 col-12 mt-3">
        <qloc-slide-in class="h-100">
            <qloc-card-overview class="h-100" title="Digitale Erpressung" image="assets/products/versicherung/erpressung.webp" mediastyle="max-width:30%; padding-bottom: 2rem;">
                <p>Bei der Digitalen Erpressung kommt es meist durch Schadsoftware zur Blockierung von Computersystemen. Es wird suggeriert, dass durch die Zahlung von großen Summen die Blockierung wieder aufgehoben wird. Auch das drohen vor Veröffentlichung
                    von gestohlenen persönlichen Daten der Kunden oder von Geschäftsprozessen ist ein häufiges Prozedere.</p>
                <p>Beispiel: Angreifern gelingt es, auf das Computersystems eines Krankenhauses Zugriff zu erlangen und sperrt sämtliche Computer dieses Unternehmens. Das gesamte Personal kann keine Arbeit verrichten, der Betrieb muss vorrübergehend eingestellt
                    werden. Gefordert wird eine Summe in Millionen-Höhe um die Sperrung aufzuheben.</p>
                <h5>Folgen:</h5>
                <qloc-cross>Stören des Geschäftsprozesses bis zum vollständigen Erliegen.</qloc-cross>
                <qloc-cross>Veröffentlichung sensibler Daten.</qloc-cross>
                <qloc-cross>Versäumen von Fertigstellungsterminen.</qloc-cross>
                <qloc-cross>Schadenersatzforderungen.</qloc-cross>
                <qloc-cross>Untersuchung des Vorfalls.</qloc-cross>
                <qloc-cross>Forensik.</qloc-cross>
                <qloc-cross>Technische Optmimierungen.</qloc-cross>
                <qloc-cross>Imageschaden.</qloc-cross>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
</div>


<qloc-interesse class="mt-5"></qloc-interesse>
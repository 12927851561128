<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Microsoft 365 – vielseitig und praktisch überall zur Hand" image="assets/products/o365/ms3651.webp">
        <p>Ob im Büro oder unterwegs – die vertrauten Produktivitätswerkzeuge der Spitzenklasse sind immer griffbereit. Mit den stets aktuellen Office-Anwendungen können Sie Dateien auf Ihrem PC oder Mac oder Ihrem iOS-, Android™- oder Windows-Gerät in Echtzeit
            erstellen, bearbeiten und mit anderen teilen.</p>

        <qloc-tick>Vertraute und bewährte Office-Funktionalitäten</qloc-tick>
        <qloc-tick>Voller Funktionsumfang, online oder offline, im Büro und unterwegs</qloc-tick>
        <qloc-tick>Erleichterung der Zusammenarbeit</qloc-tick>
        <qloc-tick><a target="_blank" data-umami-event="page-o365-availability-link" href="http://www.microsoftvolumelicensing.com/DocumentSearch.aspx?Mode=3&amp;DocumentTypeId=37">Garantierte</a> Verfügbarkeit von 99,9%</qloc-tick>
    </qloc-col-media>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-card-overview class="mt-5" title="Steigern Sie Ihre Produktivität">
        <p><strong>Durch die bewährten Office-Lösungen aus dem Hause Microsoft zu mehr Produktivität in Ihrem Unternehmen</strong></p>
        <p>
            Microsoft 365 bietet alle Programme die Sie und Ihre Mitarbeiter in Ihrem Unternehmen benötigen. Die Office-Lösung ist seit 1989 die Nummer 1 in Sachen Office-Suits.
        </p>
        <p>
            Erhalten Sie geräteübergreifenden Zugriff auf Ihre Dokumente. Teilen und bearbeiten Sie Ihre Dokumente aus Word, Excel &amp; Co. auf allen Ihrer Geräte oder direkt über einen Webbrowser Ihrer Wahl. Arbeiten Sie und Ihre Kollegen oder Partnern gleichzeitig
            an Dokumenten und speichern diese zentral in OneDrive.
        </p>
        <p>
            Microsoft 365 ist hochskalierbar und eignet sich sowohl für Kleinbetriebe, als auch für größere Unternehmen. Gegenüber frührer Office Versionen handelt es sich bei Microsoft 365 um Abonnement-Modell, dass fortlaufend und ohne weitere anfallende Kosten
            auf den aktuellsten Stand gehalten wird.
        </p>
    </qloc-card-overview>
</qloc-slide-in>


<qloc-gradient class="mt-4">
    <qloc-gradient title="Microsoft 365 und Microsoft Teams: Arbeit neu definiert">
        <div class="row row-eq-height mb-4">
            <div class="col-xl-3 col-lg-6 col-12 mt-3">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class=" h-100 " title="Gemacht für mobiles Arbeiten ">
                        <p>Bleiben Sie produktiv und mit anderen verbunden – ganz gleich, ob Sie vor Ort oder von unterwegs arbeiten.</p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-xl-3 col-lg-6 col-12 mt-3">
                <qloc-slide-in class="h-100" delay="0.4">
                    <qloc-card-overview class="h-100" title="Sicherheit für Ihr Unternehmen">
                        <p>Schützen Sie Ihre Unternehmensdaten mit integrierten Sicherheitsfunktionen.</p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-xl-3 col-lg-6 col-12 mt-3">
                <qloc-slide-in class="h-100" delay="0.2">
                    <qloc-card-overview class=" h-100 " title="Alles in einer kostengünstigen Lösung ">
                        <p>Nutzen Sie eine kostensparende einheitliche Produktivitätslösung für die schnelle Einrichtung und Verwaltung Ihrer IT.</p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-xl-3 col-lg-6 col-12 mt-3">
                <qloc-slide-in class="h-100">
                    <qloc-card-overview class="h-100" title="Jederzeit synchron">
                        <p>Von Kalender bis Dateien. Jedes Endgerät mit einer Internetverbindung wird synchronisiert. Somit ist eine einfache Kommunikation in Ihrem Unternehmen sichergestellt.</p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
        </div>
    </qloc-gradient>
</qloc-gradient>


<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Microsoft 365 Vorteile &amp; Lizenzierung" iframe="https://www.youtube-nocookie.com/embed/Hm3MWcqvl-M" [downloads]="[['Datenblatt: Lizenzierung &amp; Funktionsumfang','assets/products/o365/Microsoft-365-Pläne.pdf']]">
        <p>Mit Freunden und der Familie verbunden bleiben und nahtlos im Team arbeiten – mit Microsoft Teams können Sie an einem Ort Besprechungen halten, chatten, telefonieren und zusammenarbeiten. Die Lösung für Ihren perfekt organisierten Alltag.</p>
        <p>Verwirklichen Sie Ihre Ideen gemeinsam mit Kollegen, Mitschülern und Kommilitonen oder Familienmitgliedern.</p>
        <p>Mit leistungsstarken Apps wie Word, Excel und PowerPoint auf allen Ihren Geräten. Der sichere OneDrive-Cloudspeicher sorgt dafür, dass Sie Dateien einfach teilen und abrufen können.</p>
        <p>>Produktiv und sicher arbeiten auf dem Gerät Ihrer Wahl – mit umfassenden, intelligenten Sicherheitsfunktionen für Unternehmen können Sie darauf vertrauen, dass personenbezogene Informationen, Geräte, Anwendungen und Daten geschützt sind.
        </p>
        <p>Microsoft 365 bietet Ihnen die Möglichkeit, Ihr Abonnement flexibel jeden Monat aufs Neue an Ihre Bedürfnisse anzupassen. Egal ob neue Mitarbeitende anfangen, Sie das Lizenzmodell einiger Nutzer ändern wollen oder jemand das Unternehmen verlässt.
            Die Anzahl und Art der benötigten Lizenzen können Sie monatlich, abhängig von Ihrem Bedarf, ändern. </p>
        <p>Da es sich bei Microsoft 365 um Software-as-a-Service handelt, müssen Sie als Kunde keine eigene Infrastruktur für Microsoft 365 vorhalten und sind so deutlich flexibler.</p>
    </qloc-col-media>
</qloc-slide-in>


<qloc-slide-in>
    <qloc-card class="mt-5">
        <qloc-tick>Von überall auf Ihre Daten zugreifen: Egal ob Sie im Büro, im Home-Office oder im Lieblings-Café sind.</qloc-tick>
        <qloc-tick>Nicht an ein Gerät gebunden: Nutzbar auf PCs, Macs, Tablets und Smartphones.</qloc-tick>
        <qloc-tick>Immer die aktuellste Software: Die aktuellste Version von Word, Excel, PowerPoint, Outlook uvm.</qloc-tick>
        <qloc-tick>Keine eigene IT-Infrastruktur nötig: Dank bereitstellung als Software-as-a-Service nicht nötig.</qloc-tick>
        <qloc-tick>Geringe Belastung des IT-Personals: Kein Personal für die Verwaltung der Infrastruktur benötigt. Stattdessen findet die gesamte Verwaltung im Admin-Portal statt</qloc-tick>
        <qloc-tick>Keine hohe einmalige Investition: Ein monatlicher Beitrag pro Nutzer. Dies schont Ihre Liquidität.</qloc-tick>
    </qloc-card>
</qloc-slide-in>


<qloc-interesse class="mt-5"></qloc-interesse>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-mailstore',
  templateUrl: './mailstore.component.html',
  styleUrls: ['./mailstore.component.scss']
})
export class QlocPage_MailstoreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

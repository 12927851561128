<h2 class="mt-5">Ihre Vorteile</h2>

<div class="row mt-2">
    <div class="col-lg-4 col-12 mt-3">
        <qloc-slide-in class="h-100" delay="0.6">
            <qloc-card-overview class="h-100" image="assets/products/nospamproxy/working-person-xsmall.webp" footer="Konzentrieren Sie sich auf Ihre Kernaufgaben ohne auf wichtige E-Mails zu warten.">
                <h4>Als Anwender</h4>
                <qloc-tick>NoSpamProxy arbeitet für User „unsichtbar“.</qloc-tick>
                <qloc-tick>Keine Schulung erforderlich.</qloc-tick>
                <qloc-tick>Keine Berührung mit Verschlüsselungschinesisch.</qloc-tick>
                <qloc-tick>Kein Durchsuchen von Spamordnern.</qloc-tick>
                <qloc-tick>Versenden auch größter Dateien ohne Admin-Kontakt.</qloc-tick>
                <qloc-tick>Anwender kann abgewiesene Mails selbst „freischalten“.</qloc-tick>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-12 mt-3">
        <qloc-slide-in class="h-100" delay="0.3">
            <qloc-card-overview class="h-100" image="assets/products/nospamproxy/CTO-xsmall.webp" footer="Sparen Sie Kosten und verringern Sie das Risiko von Folgen eines IT-Blackouts Ihres Unternehmens." mediastyle="max-height:280px; padding: 20px 0;">
                <h4>Als Geschäftsführer</h4>
                <qloc-tick>Kosteneinsparung durch Anwendungskonsolidierung.</qloc-tick>
                <qloc-tick>Sicherheit vor Geschäftsführer-Trick / CEO Fraud.</qloc-tick>
                <qloc-tick>Freie Wahl des günstigsten Betriebsmodells (On-Premise, Hybrid, Managed Services).</qloc-tick>
                <qloc-tick>Volle Konformität mit dem deutschen Recht (insbesondere gemäß $206 StGB, §88 Telekommunikationsgesetz).</qloc-tick>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
    <div class="col-lg-4 col-12 mt-3">
        <qloc-slide-in class="h-100">
            <qloc-card-overview class="h-100" image="assets/products/nospamproxy/it-nerd-xsmall.webp" footer="Einfache Implementierung sowie nachhaltig geringer administrativer Aufwand.">
                <h4>Als Administrator</h4>
                <qloc-tick>Praxiserprobtes Reporting ohne Sucherei nach „Warum ist die Mail abgewiesen worden?“.</qloc-tick>
                <qloc-tick>Weniger User-Anfragen.</qloc-tick>
                <qloc-tick>Einfache Bedienung – MMC-integriertes GUI, AD-Integration.</qloc-tick>
                <qloc-tick>Disclaimer Gestaltung durch Marketing oder Admin.</qloc-tick>
                <qloc-tick>Unterstützung gängiger Mailserver inkl. Microsoft Umgebungen, Microsoft 365 und Azure.</qloc-tick>
            </qloc-card-overview>
        </qloc-slide-in>
    </div>
</div>

<qloc-slide-in>
    <qloc-card-media class="mt-5" title="Weit mehr als nur ein Spam-Filter" image="assets/products/nospamproxy/gateway-security-small-dark.webp" [size]="4" mediastyle="">
        <p>NoSpamProxy kombiniert intelligente Analysefunktonen, Lernfähigkeit sowie etliche bewährte Methoden wie Absenderreputation, SPF, DKIM und DMARC. Damit sind wir in der Lage einen hohen Wirkungsgrad zu erreichen.<br /> Darüber hinaus erhalten Sie
            eine hohe Transparenz über die erfolgte Filterung sowie eine individuelle Erstellung von Regelwerken.</p>
        <p>Eine automatische E-Mail-Verschlüsselung inkl. Signatur mittel S/MIME und PGP sowie ein sicherer Transfer großer Dateien runden das Gesamtpaket ab.</p>
    </qloc-card-media>
</qloc-slide-in>


<qloc-slide-in>
    <div class="mt-5">
        <h2 class="mb-4">Funktionsweise</h2>
        <p>Die NoSpamProxy Protection dient beim Empfang von E-Mails als erstes SMTP-Gateway und klassifiert sie anhand unterschiedlicher Anti-Spam-Filter. Eine als Spam oder potenziell gefährlich eingestufte E-Mail gelangt gar nicht zu Ihrem Mailserver.
            Der Absender abgewiesener E-Mails wird über die verhinderte Zustellung informiert.</p>
        <p><strong>Nicht jede schadhafte E-Mail muss gänzlich blockiert werden!</strong> Gefährliche Inhalte werden unschädlich gemacht. E-Mail-Anhänge im Word-, Excel- oder PDF-Format lassen sich regelbasiert in ungefährliche PDF-Dateien umgewandelt bzw.
            entschärft werden, so dass dem Empfänger ein garantiert ungefährlicher Anhang zugestellt wird. Der „Klick aus Neugier“ wird entschärft sowie die Gefahr von False-Positive-Erkennungen minimiert.</p>
        <p>Mit der automatisierten Absenderidentifikation kann NoSpamProxy eindeutig feststellen, ob eine E-Mail tatsächlich vom angegebenen Absender stammt. Hierbei bedient es sich den Methoden der Absenderreputation, SPF, DKIM sowie DMARC. Um gezielt vor
            Phishing- und CEO-Fraud-Angriffen zu schützen, wird zudem eine umfangreiche Prüfung des Header-FROM - also der Kopfzeile einer E-Mail - durchgeführt. Somit wird beispielsweise verhindert, dass sich Angreifer in E-Mails als Ihr Chef oder Kollege
            ausgeben können.
        </p>
    </div>
</qloc-slide-in>


<qloc-slide-in>
    <qloc-card-media class="mt-5" title="„Level-of-Trust“-Konzept" image="assets/products/nospamproxy/trust-small.webp" [left]="true" [size]="4">
        <p>Mit dem „Level-of-Trust“-Konzept lernt NoSpamProxy ständig, mit wem Sie oder Mitarbeiter Ihres Unternehmens kommunizieren. Dabei werden anhand vieler Merkmale Vertrauenspunkte vergeben, die viel mehr sind, als eine dynamische Whitelist. So werden
            gewünschte Kommunikationsbeziehungen erlernt und Ihr System wächst intelligent mit. Dieses Verfahren reduziert fälschliches Blockieren von E-Mails Ihrer bekannten Geschäftspartner.</p>
    </qloc-card-media>
</qloc-slide-in>


<qloc-slide-in>
    <div class="mt-5">
        <h2 class="mb-4">Reporting</h2>
        <p>Mit den Reporting-Funktionen von NoSpamProxy Protection haben Sie Ihren E-Mail-Verkehr immer unter Kontrolle. So lassen sich das Datenvolumen sowie das E-Mail- und Spam-Aufkommen detailliert bis auf die Benutzerebene analysieren.<br /> Die integrierte
            Nachrichtenverfolgung protokolliert jede E-Mail und wie sie weiterverarbeitet wurde. Welche Regeln waren aktiv? Welche Anti-Spam-Filter am Mail-Gateway haben Einfluss genommen und welche Aktionen wurden für die E-Mail ausgeführt?</p>
        <p>Administratoren haben mit den Protokollen und Berichten von NoSpamProxy Protection <strong>alle Meldungen ständig im Blick</strong> und können Rückfragen einfach und ohne langes Suchen beantworten.</p>
    </div>
</qloc-slide-in>


<qloc-interesse class="mt-5"></qloc-interesse>
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'qloc-theme-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class QlocTheme_HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

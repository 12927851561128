import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

interface PartnerObject {
  title: string,
  image: string,
  link: string
}

@Component({
  selector: 'qloc-theme-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class QlocTheme_PartnerComponent implements OnInit {

  partnersJSON:Array<PartnerObject> = require('@config/partner_footer.json');
  partner:Array<Array<PartnerObject>> = [[]];
  max_rows=6;
  rows = 6;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {

	let width = 0;
	if (this.document.defaultView !== null) {
		width = this.document.defaultView.innerWidth - 40;
	} else {
		width = 1040;
	}

	this.max_rows = Math.floor(width/200);
	if(this.max_rows > 6) {
	  this.max_rows = 6;
	}
	if(this.max_rows < 1) {
	  this.max_rows = 1;
	}

    this.rows = Math.ceil(this.partnersJSON.length / Math.ceil(this.partnersJSON.length / this.max_rows))
    //window.console.log(this.max_rows); window.console.log(this.rows);



	let i = 0;
	let j = 0;
	this.partnersJSON.forEach((element) => {
	  if(i===this.rows) {
		i = 0;
		this.partner.push([]);
		j++;
	  }
	  this.partner[j].push(element);
	  i++;
	});
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-versicherung',
  templateUrl: './versicherung.component.html',
  styleUrls: ['./versicherung.component.scss']
})
export class QlocPage_VersicherungComponent implements OnInit {


    /*
Navigation:
,
            {
                "title": "Cyberversicherungen",
                "text": "",
                "icon": "file-shield",
                "link": "versicherung"
            }

    */
  constructor() { }

  ngOnInit(): void {
  }

}

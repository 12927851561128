<qloc-slide-in class="mt-5" src="assets/products/it-service/bg.webp">


    <qloc-card-media class="mt-5 mb-5" title="Wie modern und sicher ist Ihre IT-Infrastruktur?" mediastyle="margin-right: 20px; max-width: min(350px, 55%);" image="assets/products/it-security/it-security-picture.webp">
        <p>
            Mit unserer maßgeschneiderten IT-Infrastruktur-Prüfung untersuchen wir Ihre bestehende Infrastruktur auf Schwachstellen und mögliche Verbesserungen.
        </p>
        <p>
            Bei unserer IT-Prüfung kommen wir zu Ihnen in Ihr Unternehmen und begutachten Ihre aktuellen IT-Systeme. Die Begutachtung erfolgt auf Grundlage der Prüfungskriteren, die wir im Gespräch mit Ihnen ermitteln. Aus den Prüfungskriteren leiten wir eine Liste an Prüfpunkten ab, die dann hinsichtlich des Erfüllungsgrades und des Risikos bewertet werden.
        </p>
        <p>
            Zu jedem Prüfpunkt erhalten Sie eine genaue Situationsbeschreibung und einen Katalog an Sofortmaßnahmen, die bei dringlichen Problemen direkt in Anspruch genommen werden. Zusätzlich erhalten Sie maßgeschneiderte Empfehlungen zu Produkten und Dienstleistungen unserer Partner.
        </p>
        <p>
            Sie wollen mehr über unsere IT-Prüfung erfahren? <a data-umami-event="page-itsecurity-contact-link" href="/kontakt">Kontaktieren Sie uns!</a>
        </p>
    </qloc-card-media>
</qloc-slide-in>

<qloc-fullscreen-bg bgcolor="#12457f" color="#FFFFFF" class="mt-4 mb-4">
    <div class="mt-5 mb-5">
        <h2>Unsere Lösungen für Ihre IT-Security</h2>
        <p>
            Profitieren Sie von modernster Technik und jahrelanger Erfahrung im Bereich der IT-Security. Unsere breite Produktpalette bietet eine Lösung für jeden Anwendungsbereich.
        </p>
        <div class="mt-4 row">
            <div class="col-lg-4 col-12 mt-3">
                <qloc-slide-in class="h-100" delay="0.6">
                    <qloc-card-overview class="h-100" title="Sophos" image="assets/unternehmen/partners/sophos_matched.webp" mediastyle="max-height: 50px;">
                        <h5 class="text-center">Firewalls, Antivirus</h5>
                        <p class="text-center mt-4">
                            <a class="qloc-color no-link text-center" href="/sophos"><fa-icon icon="arrow-circle-right"></fa-icon> Mehr erfahren</a>
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <qloc-slide-in class="h-100" delay="0.3">
                    <qloc-card-overview class="h-100" title="Fortinet" image="assets/unternehmen/partners/fortinet_matched.webp" mediastyle="max-height: 50px;">
                        <h5 class="text-center">Firewalls, Antivirus</h5>
                        <p class="text-center mt-4">
                            <a class="qloc-color no-link text-center" href="/fortinet"><fa-icon icon="arrow-circle-right"></fa-icon> Mehr erfahren</a>
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <qloc-slide-in class="h-100" delay="0">
                    <qloc-card-overview class="h-100" title="Crowdstrike" image="assets/unternehmen/partners/crowdstrike_matched.webp" mediastyle="max-height: 50px;">
                        <h5 class="text-center">Antivirus</h5>
                        <p class="text-center mt-4">
                            <a class="qloc-color no-link text-center" href="/crowdstrike"><fa-icon icon="arrow-circle-right"></fa-icon> Mehr erfahren</a>
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <qloc-slide-in class="h-100" delay="0">
                    <qloc-card-overview class="h-100" title="tenable.io" image="assets/unternehmen/partners/teneable_matched.webp" mediastyle="max-height: 50px;">
                        <h5 class="text-center">Vulnerability Management</h5>
                        <p class="text-center mt-4">
                            <a class="qloc-color no-link text-center" href="/tenable"><fa-icon icon="arrow-circle-right"></fa-icon> Mehr erfahren</a>
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <qloc-slide-in class="h-100" delay="0">
                    <qloc-card-overview class="h-100" title="Awareness Hero" image="assets/unternehmen/partners/awareness_hero_matched.webp" mediastyle="max-height: 50px;">
                        <h5 class="text-center">Anti-Phishing Trainings</h5>
                        <p class="text-center mt-4">
                            <a class="qloc-color no-link text-center" href="/awareness-hero"><fa-icon icon="arrow-circle-right"></fa-icon> Mehr erfahren</a>
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <qloc-slide-in class="h-100" delay="0">
                    <qloc-card-overview class="h-100" title="NoSpamProxy" image="assets/unternehmen/partners/nsp_matched.webp" mediastyle="max-height: 50px;">
                        <h5 class="text-center">E-Mail Security</h5>
                        <p class="text-center mt-4">
                            <a class="qloc-color no-link text-center" href="/nsp"><fa-icon icon="arrow-circle-right"></fa-icon> Mehr erfahren</a>
                        </p>
                    </qloc-card-overview>
                </qloc-slide-in>
            </div>
        </div>
    </div>
</qloc-fullscreen-bg>

<qloc-interesse></qloc-interesse>
<qloc-card class="mt-5 mb-5" title="Impressum">
    <h3 class="mt-3">
        <fa-icon icon="angle-double-right"></fa-icon>Angaben gemäß § 5 TMG</h3>
    <p class="mt-3">
        Quality Location GmbH<br> Schäferstr. 19<br> 14109 Berlin
    </p>
    <p>
        Handelsregister: HRB 128612B<br> Registergericht: Amtsgericht Berlin - Charlottenburg
    </p>
    <p>
        <strong>Vertreten durch:</strong><br> Herrn Carsten Fineske
    </p>
    <h3 class="mt-5">
        <fa-icon icon="angle-double-right"></fa-icon>Kontakt</h3>
    <p class="mt-3">
        Telefon: +49 (0) 30 2201 298 30<br> Telefax: +49 (0) 30 2201 298 39<br> E-Mail: <a href="mailto:info@qloc.de">info&#64;qloc.de</a>
    </p>
    <h3 class="mt-5">
        <fa-icon icon="angle-double-right"></fa-icon>Umsatzsteuer-ID</h3>
    <p>
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br> DE 272 941 786
    </p>
    <h3 class="mt-5">
        <fa-icon icon="angle-double-right"></fa-icon>Initiative "FairCommerce"</h3>
    <p class="mt-3">
        Wir sind Mitglied der Initiative "FairCommerce".<br> Nähere Informationen hierzu finden Sie unter <a href="http://www.fair-commerce.de">www.fair-commerce.de</a>.
    </p>
    <h3 class="mt-5">
        <fa-icon icon="angle-double-right"></fa-icon>EU-Streitschlichtung</h3>
    <p class="mt-3">
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.<br> Unsere E-Mail-Adresse finden
        Sie oben im Impressum.
    </p>
    <h3 class="mt-5">
        <fa-icon icon="angle-double-right"></fa-icon>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
    </h3>
    <p class="mt-3">
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>
    <h3 class="mt-5">
        <fa-icon icon="angle-double-right"></fa-icon>Haftung für Inhalte</h3>
    <p class="mt-3">
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen
        zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br>
        <br> Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
        möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    </p>
    <h3 class="mt-5">
        <fa-icon icon="angle-double-right"></fa-icon>Haftung für Links</h3>
    <p class="mt-3">
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
        Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        <br>
        <br> Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
    </p>
    <h3 class="mt-5">
        <fa-icon icon="angle-double-right"></fa-icon>Urheberrecht</h3>
    <p class="mt-3">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
        Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br>
        <br> Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
        werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
    </p>

    <h3 class="mt-5"><fa-icon icon="angle-double-right"></fa-icon>Kontaktstelle gemäß Verordnung (EU) 2021/784 des Europäischen Parlaments und des Rates</h3>
    <p class="mt-3">
        E-Mail: <a href="mailto:tco-kontaktstelle@qloc.de">tco-kontaktstelle&#64;qloc.de</a><br><br>
        Kontaktaufnahme ist in folgenden Sprachen möglich: Deutsch, Englisch. Diese E-Mail-Adresse ist ausschließlich für die Kommunikation gemäß Verordnung (EU) 2021/784 bestimmt. Andere Anfragen werden nicht beantwortet.
    </p>

    <h3 class="mt-5">
        <fa-icon icon="angle-double-right"></fa-icon>Copyright Hinweise</h3>
    <p class="mt-3">
        Angaben der Quelle für verwendetes Bilder- und Grafikmaterial:<br>
    </p>
    <ul>
        <li><a href="http://www.fotolia.com" target="_blank">fotolia.com</a></li>
        <li><a href="http://www.flaticon.com" target="_blank">flaticon.com (mynamepong, Gregor Cresnar)</a></li>
        <li><a href="http://www.unsplash.com" target="_blank">unsplash.com (Plush Design Studio, YTCount, John Salvino,
    Neonbrand, Patrick Tomasso)</a></li>
        <li><a href="http://www.freepik.com" target="_blank">Freepik (fullvector, iconicbestiary, Makyzz, rawpixel,
    katemangostar, macrovector, vectorjuice)</a></li>
    </ul>
</qloc-card>

import { Component, Input } from '@angular/core'; 

@Component({
  selector: 'qloc-fullscreen-bg',
  templateUrl: './fullscreen_bg.component.html',
  styleUrls: ['./fullscreen_bg.component.scss']
})
export class QlocComponent_FullscreenBGComponent {


  @Input() class: string = '';
  @Input() src: string = '';
  @Input() color: string = '';
  @Input() bgcolor: string = '';
  @Input() title: string = '';

  constructor() { 
  }  


}

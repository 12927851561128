<h2 class="mt-5">Partner</h2>
<div class="mt-5" *ngFor=" let cat of partner;">
    <h3>{{cat.title}}</h3>
    <div class="row mb-5 ">
        <div class="col-lg-4 col-md-6 col-12 mt-3 " *ngFor="let ref of cat.items; ">
            <qloc-card-overview class="h-100 " [title]="ref.title " [image]="ref.image " footer="Branche: {{ ref.branche }} " mediastyle="max-width: 80%; max-height:80px; ">
                <p [innerHTML]="ref.text "></p>
            </qloc-card-overview>
        </div>
    </div>
</div>
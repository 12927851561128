import { Component } from '@angular/core';

@Component({
  templateUrl: './dell.component.html'
})
export class QlocSubheader_DellComponent {}

@Component({
    templateUrl: './fujitsu.component.html'
})
export class QlocSubheader_FujitsuComponent {}

@Component({
    templateUrl: './ip-telefone.component.html'
})
export class QlocSubheader_IPTelefoneComponent {}

@Component({
    templateUrl: './lenovo.component.html'
})
export class QlocSubheader_LenovoComponent {}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class QlocComponent_CardComponent {
  
  @Input() class: string = '';
  @Input() title: string = '';
  @Input() footer: string = '';
  constructor() { }


}

@Component({
  selector: 'qloc-card-overview',
  templateUrl: './card_overview.component.html',
  styleUrls: ['./card_overview.component.scss']
})
export class QlocComponent_CardOverviewComponent {
  
  @Input() class: string = '';
  @Input() title: string = '';
  @Input() url: string = '';
  @Input() link: string = 'Mehr anzeigen';
  @Input() icon: any = '';
  @Input() image: string = '';
  @Input() mediastyle: string = '';
  @Input() footer: string = '';

  constructor() { }


}

@Component({
  selector: 'qloc-card-media',
  templateUrl: './card_media.component.html',
  styleUrls: ['./card_media.component.scss']
})
export class QlocComponent_CardMediaComponent {
  
  @Input() class: string = '';
  @Input() title: string = '';
  @Input() url: string = '';
  @Input() link: string = 'Mehr anzeigen';
  @Input() image: any = '';
  @Input() alt: string = '';
  @Input() iframe: string = '';
  @Input() size:number = 5;
  @Input() left: boolean = false;
  @Input() downloads: Array<Array<String>> = [];
  @Input() footer: string = '';
  @Input() mediastyle: string = '';


  constructor() { }

}

@Component({
  selector: 'qloc-card-download',
  templateUrl: './card_download.component.html',
  styleUrls: ['./card_download.component.scss']
})
export class QlocComponent_CardDownloadComponent {
  
  @Input() class: string = '';
  @Input() downloads: Array<Array<String>> = [];

  constructor() { }


}
import { Component, Input } from '@angular/core'; 

@Component({
  selector: 'qloc-gradient',
  templateUrl: './gradient.component.html',
  styleUrls: ['./gradient.component.scss']
})
export class QlocComponent_GradientComponent {

  @Input() class: string = '';
  @Input() title: string = '';
  constructor() { 
  }  


}

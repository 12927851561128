<div class="interesse">
    <qloc-slide-in>
        <h1>
            Wir haben Ihr Interesse geweckt?
        </h1>
        <h4>
            Sie haben Fragen zu der Produktpalette oder möchten <br />mehr über die Anwendung speziell in Ihrem Unternehmen erfahren?
        </h4>
        <p>
            <a class="btn btn-qloc" data-umami-event="interesse-contact-link" href="/kontakt">
            Kontaktieren Sie uns!
        </a>
        </p>
    </qloc-slide-in>
</div>
<qloc-slide-in>
    <qloc-card class="mt-5">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem *ngFor="let item of items;">
                <a ngbNavLink>{{item.category}}</a>
                <ng-template ngbNavContent>
                    <ul ngbNav #nav="ngbNav" class="nav-tabs">
                        <li ngbNavItem *ngFor="let phone of item.items;">
                            <a ngbNavLink>
                                <img src="{{ phone.piktogramm }}" class="img-responsive" />
                                <span>{{ phone.title }}</span>
                            </a>
                            <ng-template ngbNavContent>
                                <qloc-product-overview class="mt-3" [data]="phone"></qloc-product-overview>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-3 ml-3"></div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Zubehör</a>
                <ng-template ngbNavContent>
                    <!-- --------------------------------------------------------------------- -->
                    <table class="table table-striped product-table">
                        <tr>
                            <th class="center" style="padding-left: 40px;">Produkt</th>
                            <th class="center" style="padding-left: 40px;">Beschreibung</th>
                            <th class="center" style="padding-left: 40px;">Kompatibilität</th>
                            <th class="center" style="padding-left: 40px;">Produktdatenblatt</th>
                        </tr>
                        <tr>
                            <td class="product-1" colspan="5"><strong>Yealink T4 Serie</strong></td>
                        </tr>
                        <tr>
                            <td class="product-1-sub"><strong>POE-Injektor (<i>YLPOE30</i>)</strong></td>
                            <td>IEEE802.3af, IEEE802.3at<br />10/100/1000M Ports</td>
                            <td>Alle Yealink Komponenten mit<br />Power-Over-Ethernet</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534727890319.pdf"><button type="button" class="btn btn-primary product-1">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-1-sub"><strong>LCD-Erweiterung (<i>EXP40</i>)</strong></td>
                            <td>Beleuchtetes LCD Display für biszu 40 Kontakttasten <br />Kurzwahl, Weiterleitung, Übernehmen, u.v.m.</td>
                            <td>T46S + T48S</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534729463317.pdf"><button type="button" class="btn btn-primary product-1">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-1-sub"><strong>Headset mit RJ9 Stecker (<i>YHS33</i>)</strong></td>
                            <td>Ergonomisches kabelgebundenes Headset<br />mit hoher Audioqualität und Umgebungsgeräuchfilterung</td>
                            <td>T42S, T46S + T48S</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534728621921.pdf"><button type="button" class="btn btn-primary product-1">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-1-sub"><strong>Headset mit USB + 3,5mm Klinke Stecker<br />(<i>YHS33-USB</i>)</strong></td>
                            <td>Ergonomisches kabelgebundenes Headset<br />Anschluss an Telefon oder an Windows/Mac OS</td>
                            <td>T42S, T46S + T48S</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534728647836.pdf"><button type="button" class="btn btn-primary product-1">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-1-sub"><strong>Wireless Headset Adapter<br />(<i>EHS36</i>)</strong></td>
                            <td>Adapter für kabellose Headsets von Jabra, Plantronics & Sennheiser</td>
                            <td>T42S, T46S + T48S</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534729490483.pdf"><button type="button" class="btn btn-primary product-1">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-1-sub"><strong>Bluetooth 4.0 USB Dongle<br />(<i>BT40</i>)</strong></td>
                            <td>Kopplung von Bluetooth Headsets<br />10m Reichweite</td>
                            <td>T42S, T46S + T48S</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201809/1536809304778.pdf"><button type="button" class="btn btn-primary product-1">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-1-sub"><strong>Wi-FI USB Dongle 2,4GHz<br />(<i>WF40</i>)</strong></td>
                            <td>Anbindung des Telefons via WLAN<br />IEEE802.11 b/g/n<br />WEP, WPA-PSK + WPA2-PSK</td>
                            <td>T42S, T46S + T48S</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534728826678.pdf"><button type="button" class="btn btn-primary product-1">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-1-sub"><strong>Wi-FI USB Dongle 2,4GHz + 5GHz<br />(<i>WF50</i>)</strong></td>
                            <td>Anbindung des Telefons via WLAN<br />IEEE 802.11 a/n/ac, IEEE 802.11 b/g/n<br />WEP, WPA-PSK + WPA2-PSK</td>
                            <td>T42S, T46S + T48S</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201811/1543312944567.pdf"><button type="button" class="btn btn-primary product-1">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-2" colspan="5"><strong>Yealink T5 Serie</strong></td>
                        </tr>
                        <tr>
                            <td class="product-2-sub"><strong>POE-Injektor (<i>YLPOE30</i>)</strong></td>
                            <td>IEEE802.3af, IEEE802.3at<br />10/100/1000M Ports</td>
                            <td>Alle Yealink Komponenten mit<br />Power-Over-Ethernet</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534727890319.pdf"><button type="button" class="btn btn-primary product-2">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-2-sub"><strong>LCD-Erweiterung (<i>EXP50</i>)</strong></td>
                            <td>Beleuchtetes LCD Display für biszu 40 Kontakttasten <br />Kurzwahl, Weiterleitung, Übernehmen, u.v.m.</td>
                            <td>T54S + T56S</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534728597347.pdf"><button type="button" class="btn btn-primary product-2">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-2-sub"><strong>Headset mit RJ9 Stecker (<i>YHS33</i>)</strong></td>
                            <td>Ergonomisches kabelgebundenes Headset<br />mit hoher Audioqualität und Umgebungsgeräuchfilterung</td>
                            <td>T54S + T56S</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534728621921.pdf"><button type="button" class="btn btn-primary product-2">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-2-sub"><strong>Headset mit USB + 3,5mm Klinke Stecker<br />(<i>YHS33-USB</i>)</strong></td>
                            <td>Ergonomisches kabelgebundenes Headset<br />Anschluss an Telefon oder an Windows/Mac OS</td>
                            <td>T54S + T56S</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534728647836.pdf"><button type="button" class="btn btn-primary product-2">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-2-sub"><strong>Wi-FI USB Dongle 2,4GHz<br />(<i>WF40</i>)</strong></td>
                            <td>Anbindung des Telefons via WLAN<br />IEEE802.11 b/g/n<br />WEP, WPA-PSK + WPA2-PSK</td>
                            <td>T54S + T56S</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534728826678.pdf"><button type="button" class="btn btn-primary product-2">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-3" colspan="5"><strong>Yealink Konferenz</strong></td>
                        </tr>
                        <tr>
                            <td class="product-3-sub"><strong>POE-Injektor (<i>YLPOE30</i>)</strong></td>
                            <td>IEEE802.3af, IEEE802.3at<br />10/100/1000M Ports</td>
                            <td>Alle Yealink Komponenten mit<br />Power-Over-Ethernet</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534727890319.pdf"><button type="button" class="btn btn-primary product-3">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-3-sub"><strong>Kabelgebundenes Zusatzmikrofon (<i>CPE80</i>)</strong></td>
                            <td>3m 120&deg; Radius Reichweite</td>
                            <td>CP860</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534728028321.pdf"><button type="button" class="btn btn-primary product-3">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-3-sub"><strong>Kabelgebundenes Zusatzmikrofon (<i>CPE90</i>)</strong></td>
                            <td>3m 360&deg; Radius Reichweite</td>
                            <td>CP960</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201811/1543483035316.pdf"><button type="button" class="btn btn-primary product-3">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-3-sub"><strong>Kabellosese Zusatzmikrofone (<i>CPE90</i>)</strong></td>
                            <td>3m 360&deg; Radius Reichweite<br />DECT Reichweite zwischen CP960 & CPW90: max. 20m</td>
                            <td>CP960</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201811/1543482985734.pdf"><button type="button" class="btn btn-primary product-3">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-3-sub"><strong>PSTN Adapter (<i>CPN10</i>)</strong></td>
                            <td>Anbindung des Konferenztelefons an PSTN Telefonanschlüsse<br />(Public Switched Telephone Network)</td>
                            <td>CP860 + CP920</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201811/1542965664647.pdf"><button type="button" class="btn btn-primary product-3">Datenblatt</button></a></td>
                        </tr>
                        <tr>
                            <td class="product-4" colspan="5"><strong>Yealink DECT</strong></td>
                        </tr>
                        <tr>
                            <td class="product-4-sub"><strong>POE-Injektor (<i>YLPOE30</i>)</strong></td>
                            <td>IEEE802.3af, IEEE802.3at<br />10/100/1000M Ports</td>
                            <td>Nur Basisstationen und Repeater<br />via Power-Over-Ethernet</td>
                            <td class="even"><a target="_blank" href="https://www.yealink.com/upfiles/products/201808/1534727890319.pdf"><button type="button" class="btn btn-primary product-4">Datenblatt</button></a></td>
                        </tr>
                    </table>
                    <!-- --------------------------------------------------------------------- -->
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-3 ml-3"></div>
    </qloc-card>
</qloc-slide-in>

<qloc-slide-in>
    <qloc-col-media class="mt-5" title="Nahtlose Integration in Ihre 3CX-Telefonanlage
" image="assets/products/3cx/ip-telephony-webmeeting.webp" [size]="6">
        <p>Yealink und 3CX sind offizielle Technologie-Partner. Profitieren Sie von einer hohen Interoperabilität von Hard- und Software zu 3CX Cloud und On-Promise Telefon-Anlagen.<br> Provisionieren Sie Ihre Yealink Plug’n’Play über die 3CX Management
            Konsole. Nach dem Anschluss erscheinen Ihre Yealink Telefone automatisch zur Provisionierung auf Ihrer lokalen 3CX-Telefonanlage. Verwalten Sie Ihr Yealink Inventar zentral über Ihre 3CX-Telefonanlage.<br> Gerne beraten und unterstützen wir
            Sie bei der Wahl und Integration von Yealink IP Telefonen für Ihre Infrastruktur.</p>
        <p>
            Neben 3CX bieten wir auch Telefon-Anlagen von NFON an!
        </p>
    </qloc-col-media>
</qloc-slide-in>


<qloc-interesse></qloc-interesse>
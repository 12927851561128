<div class="row">
    <div class="col-lg-8 col-12 order-2 order-lg-1">
        <qloc-slide-in delay="0.3">
            <qloc-card class="mt-5">
                <h2>Kontakt</h2>
                <p class="mt-3">Der <strong>direkte</strong> und <strong>unkomplizierte Kontakt</strong> zu unseren kompetenten Kundenbetreuern ist ein elementarer Teil unser Qualitätsphilosophie. Neben unseren Mitarbeitern, die Ihnen bei Fragen informativ zur Seite
                    stehen, finden Sie hier auch die notwendigen Ansprechpartner für unsere Fachbereiche Technik, Buchhaltung und Vertrieb.
                </p>
                <p>
                    Wir beraten Sie gerne in einem persöhnlichen Gespräch bei der Umsetzung und Planung Ihrer Projekte, sowie bei der Problemlösung im Fehlerfall.
                    <br><strong>Unsere Mitarbeiter freuen sich auf Sie!</strong>
                </p>
            </qloc-card>
        </qloc-slide-in>
        <qloc-slide-in delay="0.3">
            <qloc-card class="mt-5">
                <h3>Kontaktieren Sie uns</h3><br>

                <div *ngIf="warning !== ''" class="alert alert-warning">{{ warning }}</div>
                <div *ngIf="error !== ''" class="alert alert-danger">{{ error }}</div>
                <div *ngIf="success !== ''" class="alert alert-success">{{ success }}</div>

                <form>
                    <div class="row">
                        <div class="col-lg-6 mt-3">
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="requestData.firstname" name="firstname" placeholder="Vorname" required="required">
                            </div>
                        </div>
                        <div class="col-lg-6 mt-3">
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="requestData.lastname" name="lastname" placeholder="Nachname" required="required">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 mt-3">
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="requestData.tel" name="tel" placeholder="Telefonnummer" required="required">
                            </div>
                        </div>
                        <div class="col-lg-6 mt-3">
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="requestData.mail" name="mail" placeholder="E-Mail Adresse" required="required">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 mt-3">
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="requestData.company" name="company" placeholder="Unternehmen" required="required">
                            </div>
                        </div>
                        <div class="col-lg-6 mt-3">
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="requestData.position" name="position" placeholder="Position" required="required">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mt-3">
                            <div class="form-group">
                                <textarea class="form-control" placeholder="Ihre Nachricht" [(ngModel)]="requestData.message" name="message" required="required"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mt-3">
                            <div class="form-group">
                                <input type="checkbox" [(ngModel)]="requestData.privacy" name="privacy" required="required"> Ich habe die <a target="_blank" href="/datenschutz">Datenschutzbestimmungen</a> gelesen und bin mit der Verarbeitung meiner personenbezogenen Daten zum Zwecke der
                                Kontaktaufnahme einverstanden.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mt-3">
                            <div class="form-group">
                                <ng-hcaptcha *ngIf="isBrowser" (verify)="verifyCaptcha($event)" name="captcha"></ng-hcaptcha>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mt-3">
                            <div class="form-group">
                                <button (click)="submit()" class="btn btn-primary" data-umami-event="page-kontakt-submitform-link">Absenden</button>
                            </div>
                        </div>
                    </div>
                </form>
            </qloc-card>
        </qloc-slide-in>
        <qloc-card class="mt-5 mb-5">
            <h2>Ticket System</h2>
            <div class="row mt-3">
                <div class="col-lg-6 col-12">
                    <div class="text-center">
                        <strong>Bereits Kunde?</strong><br><br>
                        <a href="https://kundencenter.qloc.de" class="btn btn-primary btn-md" data-umami-event="page-kontakt-kundencenter-link">Hier geht's zum Login</a>
                    </div>
                    <br clear="hidden-md hidden-lg hidden-sm">
                </div>
                <div class="col-lg-6 col-12">
                    <div class="text-center">
                        <strong>Neuer Kunde?</strong>
                        <p>Bitte verwenden Sie das Kontaktformular oben.</p><br>
                    </div>
                </div>
            </div>
        </qloc-card>
    </div>

    <div class="col-lg-4 col-12 order-1 order-lg-2">
        <qloc-slide-in>
            <qloc-card class="mt-5">
                <h2>
                    <fa-icon icon="calendar"></fa-icon>Termin vereinbaren:
                </h2>
                <p class="mt-3"><strong>Einen Termin jetzt <a target="_blank" data-umami-event="page-kontakt-outlookmeeting-link" href="https://outlook.office365.com/owa/calendar/QualityLocation@qloc.de/bookings/">online planen</a></strong></p>

                <h2 class="mt-4">
                    <fa-icon icon="phone-square"></fa-icon>Hotline:
                </h2>
                <p class="mt-3">
                    <strong>Vertrieb: 030 2201298-30</strong><br> Erreichbar von 09:00 - 19:00 Uhr Montag - Freitag.
                </p>
                <p>
                    <strong>Support: 030 2201298-30</strong><br> Erreichbar von 09:00 - 19:00 Uhr Montag - Freitag.
                </p>


                <h2 class="mt-4">
                    <fa-icon icon="envelope"></fa-icon>E-Mail:
                </h2>
                <p class="mt-3">
                    <strong>Vertrieb: sales@qloc.de</strong><br> Wir versuchen auf Verkaufsanfragen innerhalb von 24 Stunden zu reagieren.
                </p>
                <p><strong>Support: support@qloc.de</strong><br> Wir versuchen auf Supportanfragen innerhalb von 4 Stunden zu reagieren.
                </p>
                <p>
                    <strong>Information: info@qloc.de</strong><br> Fragen rund um allgemeine Informationen und zu unseren Produkten.
                </p>
                <p>
                    <strong>Rechnung: billing@qloc.de</strong><br> Für Fragen zu Rechnungsangelegenheiten.
                </p>
                <p>
                    <strong>Technik: technik@qloc.de</strong><br> Fragen rund um Technik.
                </p>

                <h2 class="mt-4">
                    <fa-icon icon="home"></fa-icon>Büro:</h2>
                <p class="mt-3">
                    <strong>Quality Location GmbH</strong><br> Schäferstr. 19, 14109 Berlin
                </p>

            </qloc-card>
        </qloc-slide-in>

    </div>

</div>
<div class="background-gradient">
    <qloc-theme-header></qloc-theme-header>
    <qloc-theme-menu></qloc-theme-menu>
    <qloc-theme-subheader>
        <router-outlet name="subheader"></router-outlet>
    </qloc-theme-subheader>
</div>
<qloc-theme-instatus></qloc-theme-instatus>
<qloc-theme-content>
    <router-outlet></router-outlet>
</qloc-theme-content>
<div class="background-testimonials">
    <qloc-theme-testimonials></qloc-theme-testimonials>
</div>
<qloc-theme-partner></qloc-theme-partner>
<qloc-theme-footer></qloc-theme-footer>
<qloc-scroll-to-top></qloc-scroll-to-top>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-page-crowdstrike',
  templateUrl: './crowdstrike.component.html',
  styleUrls: ['./crowdstrike.component.scss']
})
export class QlocPage_CrowdstrikeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

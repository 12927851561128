import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'qloc-slide-in',
  template: '<div class="wow fadeIn {{class}}" attr.data-wow-duration="{{duration}}s" attr.data-wow-delay="{{delay}}s"><ng-content></ng-content></div>'
})
export class QlocComponent_SlideInComponent implements OnInit {

    @Input() delay:string = "0";
    @Input() duration:string = "1";
    @Input() class: string = '';


  constructor() { }
  ngOnInit(): void {

  };
}
